import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/purete';
  return basic_url + url;
}

const medicalAPI = {
  // 常用语分页列表
  expresListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/expres/listPage'), method: 'post', data }, option));
  },
  // 新增常用语
  expresAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/expres/add'), method: 'post', data }, option));
  },
  // 修改常用语
  expresUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/expres/update'), method: 'post', data }, option));
  },
  // 删除常用语
  expresDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/expres/delete'), method: 'post', data }, option));
  },
  // 查询手环标签列表
  labelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/listPage'), method: 'post', data }, option));
  },
  // 消息记录分页列表
  messageRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/messageRecord/listPage'), method: 'post', data }, option));
  },
  // 发送消息
  sendMessage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/messageRecord/sendMessage'), method: 'post', data }, option));
  },
  // 组别查询分页列表
  groupListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/group/listPage'), method: 'post', data }, option));
  },
  // 组别新增
  groupAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/group/add'), method: 'post', data }, option));
  },
  // 组别删除
  groupDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/group/delete'), method: 'post', data }, option));
  },
  // 手环删除
  labelDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/delete'), method: 'post', data }, option));
  },
  // 组别设置
  groupUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/group/update'), method: 'post', data }, option));
  },
  // 组别绑定
  labelGroupUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/labelGroup/update'), method: 'post', data }, option));
  },
  // 用户绑定
  labelUserBindUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/labelUserBind/update'), method: 'post', data }, option));
  },
  // 绑定标签的用户列表
  labelUserBindUserListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/labelUserBind/userListPage'), method: 'post', data }, option));
  },
  // 用户绑定详情
  labelUserBindDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/labelUserBind/detail'), method: 'post', data }, option));
  },
  // 标签绑定详情
  labelGroupDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/labelGroup/detail'), method: 'post', data }, option));
  }
};

export default medicalAPI;
