import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/sys';
  return basic_url + url;
}
const basiAPI = {

  // 获取院区列表分页信息
  getcompoundList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/list'), method: 'post', data }, option));
  },
  // 获取院区列表分页接口信息
  getcompoundListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/listPage'), method: 'post', data }, option));
  },
  // 新增院区
  addcompoundList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/add'), method: 'post', data }, option));
  },
  // 删除院区列表每一行数据
  delcompoundListRowData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/delete'), method: 'post', data }, option));
  },
  // 编辑院区列表
  editcompoundListRowData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/update'), method: 'post', data }, option));
  },
  // 编辑查看详情
  detailcompoundListRowData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/detail'), method: 'post', data }, option));
  },

  // 科室管理页面api--不分页
  getofficesManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/list'), method: 'post', data }, option));
  },
  // 科室管理页面api--分页
  getofficesManagementPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/listPage'), method: 'post', data }, option));
  },
  // 科室管理新增
  addofficesManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/add'), method: 'post', data }, option));
  },
  // 科室管理编辑
  editofficesManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/update'), method: 'post', data }, option));
  },
  // 科室管理删除
  delofficesManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/delete'), method: 'post', data }, option));
  },
  // 科室管理  同步数据
  officesAsyncData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/synchrDeptData'), method: 'post', data }, option));
  },
  // 病区管理页面api--不分页
  getareaDictNameManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/list'), method: 'post', data }, option));
  },
  // 病区管理分页
  getareaDictNameManagementPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/listPage'), method: 'post', data }, option));
  },
  // 病区管理查看详情
  detailareaDictNameManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/detail'), method: 'post', data }, option));
  },
  // 病区管理删除
  delareaDictNameManagement (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/delete'), method: 'post', data }, option));
  },
  // 病区字典 同步数据
  areaDictionaryAsyncData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/synchrAreaData'), method: 'post', data }, option));
  },

  // 床位字典---不分页
  getBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/list'), method: 'post', data }, option));
  },
  // 分页
  getBedListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/listPage'), method: 'post', data }, option));
  },
  // 床位字典 新增测试接口
  addBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/add'), method: 'post', data }, option));
  },
  // 床位字典的删除
  delBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/delete'), method: 'post', data }, option));
  },
  // 床位字典 修改接口
  updateBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/update'), method: 'post', data }, option));
  },
  // 床位字典 同步数据
  bedDictionaryAsyncData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/synchrBedData'), method: 'post', data }, option));
  },
  // 床位字典 获取位置信息/bed/listBuildAndFloor
  getListBuildAndFloor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/listBuildAndFloor'), method: 'post', data }, option));
  },
  // 获取所有缓存数据
  getCacheAll (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheAll'), method: 'post', data }, option));
  },
  // 病区缓存
  getCacheArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheArea'), method: 'post', data }, option));
  },
  // 科室缓存
  getCacheDept (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheDept'), method: 'post', data }, option));
  },
  // 用户缓存
  getCacheUser (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheUser'), method: 'post', data }, option));
  },
  // 院区缓存
  getCacheHospital (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheHospital'), method: 'post', data }, option));
  },
  // 床位缓存
  getCacheBed (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheBed'), method: 'post', data }, option));
  },

  // 获取对应病患详情
  getBasiMedicine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/listPage'), method: 'post', data }, option));
  },
  // 分页查询位置信息
  getBasisiteListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/listPage'), method: 'post', data }, option));
  },
  // 列表查询位置信息
  getBasisiteList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/list'), method: 'post', data }, option));
  },
  // 新增位置信息
  saveBasisiteAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/add'), method: 'post', data }, option));
  },
  // 修改位置信息
  saveBasisiteUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/update'), method: 'post', data }, option));
  },
  // 新增科室位置
  getDeptSiteAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/deptSite/add'), method: 'post', data }, option));
  },
  // 删除位置信息
  delDeptSite (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/delete'), method: 'post', data }, option));
  },
  // 根据科室查病区
  getAreaByDeptList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/list'), method: 'post', data }, option));
  },
  getSiteListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/listPage'), method: 'post', data }, option));
  },
  // 新增位置信息
  addSite (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/add'), method: 'post', data }, option));
  },
  // 修改位置信息
  updateSite (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/update'), method: 'post', data }, option));
  },
  // 删除位置信息
  deleteSite (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/delete'), method: 'post', data }, option));
  },
  // 列表查询位置信息
  getSiteList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/site/list'), method: 'post', data }, option));
  },
  // 位置缓存
  getCacheSite (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/cacheSite'), method: 'post', data }, option));
  }
};

export default basiAPI;
