import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/pect';
  return basic_url + url;
}
const pectoralgiaAPI = {
  // 病患三次操作记录
  queryByPatientLimitThree (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/log/queryByPatientLimitThree'),
      method: 'post',
      data
    }, option));
  },
  // 查询五条最新上报记录
  queryLastRecords (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportRecord/queryLastRecords'),
      method: 'post',
      data
    }, option));
  },
  // 查询最新上报记录 胸痛1.2.8 更新接口
  queryPatientLogAndReport (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/queryPatientLogAndReport'),
      method: 'post',
      data
    }, option));
  },
  // 批量删除病患
  batchDeletepatiend (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/batchDelete'),
      method: 'post',
      data
    }, option));
  },
  // 获取显示字段详情
  getfielddetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/field/detail'),
      method: 'post',
      data
    }, option));
  },
  // 修改显示字段
  fieldUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/field/update'),
      method: 'post',
      data
    }, option));
  },
  // 基础配置
  // 查询配置  0 胸痛提醒阈值配置    1 胸痛自动创建开关  2胸痛自动开始诊疗开关   3胸痛自动结束诊疗开关
  basicSet_querySet (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectConfig/list'),
      method: 'post',
      data
    }, option));
  },
  // 修改
  basicSet_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectConfig/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询即将被锁定病患数接口
  wouldLockedPatients (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/wouldLockedPatients'),
      method: 'post',
      data
    }, option));
  },
  // 阈值
  // 时间采集超时提醒阈值列表
  getTimeThresholdList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timeThreshold/list'),
      method: 'post',
      data
    }, option));
  },
  // 查询时间点列表
  getTimePointList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timePoint/list'),
      method: 'post',
      data
    }, option));
  },
  // 更新时间采集设置
  updateTimePointList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timePoint/update'),
      method: 'post',
      data
    }, option));
  },
  // 环节设置
  // 环节列表
  enclosureSet_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectNode/list'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理
  // 病患对应知情同意书查询接口
  getConsentPatiList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/patient/list'),
      method: 'post',
      data
    }, option));
  },
  // 病患对应知情同意书详情
  getConsentPatiDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/patient/detail'),
      method: 'post',
      data
    }, option));
  },
  // 修改配置
  // pectConfigUpdate (data = {}, option = {}) {
  //   return axios.request(Object.assign({
  //     url: PROXY + Basic_Url('/PectConfig/update'),
  //     method: 'post',
  //     data
  //   }, option));
  // },
  // ======================
  // ======================
  // 实时病患
  // 新增或者编辑
  realTimeDisease_addOrEdit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/') + option,
      method: 'post',
      data
    }, option));
  },
  // 修改
  realTimeDisease_edit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询列表
  realTimeDisease_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/list'),
      method: 'post',
      data
    }, option));
  },
  // 结束诊疗
  realTimeDisease_end (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/end'),
      method: 'post',
      data
    }, option));
  },
  // 同步数据
  realTimeDisease_asyncMessage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/queryThird'),
      method: 'post',
      data
    }, option));
  },
  // ====================
  // ===================
  // 历史病患
  historyDisease_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询历史病患详情
  historyDisease_detail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/detail'),
      method: 'post',
      data
    }, option));
  },
  // 查询环节记录信息
  queryTreatRecord (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/queryTreatRecord'),
      method: 'post',
      data
    }, option));
  },
  // 导出数据
  historyDisease_export (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/exportData'),
      method: 'post',
      data
    }, option));
  },
  // 删除历史病患
  deleteHistoryDisease (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/delete'),
      method: 'post',
      data
    }, option));
  },
  // =================
  // =======================
  // 标签管理
  // 标签管理 查询不分页列表
  labelAdministration_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/list'),
      method: 'post',
      data
    }, option));
  },
  // ========================
  // ========================
  // 时间管理表
  // 查询急救信息
  rescueMessage_query (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectJjxx/detail'),
      method: 'post',
      data
    }, option));
  },
  // 更新急救信息
  rescueMessage_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectJjxx/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询胸痛诊疗
  chestpainMessage_query (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectXtzl/detail'),
      method: 'post',
      data
    }, option));
  },
  // 更新胸痛诊疗
  chestpainMessage_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectXtzl/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询患者转归
  outComeMessage_query (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectHzzg/detail'),
      method: 'post',
      data
    }, option));
  },
  // 更新患者转归
  outComeMessage_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectHzzg/update'),
      method: 'post',
      data
    }, option));
  },
  // 时间管理表操作记录
  timeTableLog_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/log/list'),
      method: 'post',
      data
    }, option));
  },
  // 名单管理
  // 人员详情
  ListManagement_personDetails (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/detail'),
      method: 'post',
      data
    }, option));
  },
  // 新增或编辑
  ListManagement_addOredit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/') + option,
      method: 'post',
      data
    }, option));
  },
  // 人员列表（分页）
  ListManagement_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 人员列表（不分页）
  ListManagement_nolistPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/list'),
      method: 'post',
      data
    }, option));
  },
  // 批量删除或删除多个
  ListManagement_delete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/delete'),
      method: 'post',
      data
    }, option));
  },
  // 判断人员是否有未发布排班
  ListManagement_judge (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/selectPersonSchedule'),
      method: 'post',
      data
    }, option));
  },
  // 归档
  timeTableLog_file (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/patientFiling'),
      method: 'post',
      data
    }, option));
  },
  // 提交急救信息
  rescueMessage_updateSubmit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectJjxx/updateSubmit'),
      method: 'post',
      data
    }, option));
  },
  // 更新胸痛诊疗
  chestpainMessage_updateSubmit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectXtzl/updateSubmit'),
      method: 'post',
      data
    }, option));
  },
  // 更新患者转归
  outComeMessage_updateSubmit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectHzzg/updateSubmit'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询操作记录
  getLogListPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/log/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询环节设置列表
  getPectNodeList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectNode/list'),
      method: 'post',
      data
    }, option));
  },
  // 查询胸痛诊疗
  outcomeMessage_query (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectXtzl/detail'),
      method: 'post',
      data
    }, option));
  },
  // 保存节设置配置
  savePectNode (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectNode/save'),
      method: 'post',
      data
    }, option));
  },
  // 更新胸痛诊疗
  outcomeMessage_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/pectXtzl/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询配置信息
  getPectConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectConfig/list'),
      method: 'post',
      data
    }, option));
  },
  // 修改配置信息
  updatePectConfig (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/PectConfig/update'),
      method: 'post',
      data
    }, option));
  },

  // 知情同意书新增
  addConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/add'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书修改
  updateConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/update'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书列表
  getConsentList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/list'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书详情
  getConsentDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/detail'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书删除
  delConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/delete'),
      method: 'post',
      data
    }, option));
  },
  // 关系列表
  getRelationshipList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/list'),
      method: 'post',
      data
    }, option));
  },
  // 关系详情
  getRelationshipDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/detail'),
      method: 'post',
      data
    }, option));
  },
  // 关系新增
  addRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/add'),
      method: 'post',
      data
    }, option));
  },
  // 关系修改
  updateRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/update'),
      method: 'post',
      data
    }, option));
  },
  // 关系删除
  deleteRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/delete'),
      method: 'post',
      data
    }, option));
  },

  // 列表查询检查及治疗方案
  getTionshipList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/treatment/list'),
      method: 'post',
      data
    }, option));
  },
  // 新增检查及治疗方案
  addTionship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/treatment/add'),
      method: 'post',
      data
    }, option));
  },
  // 检查及治疗方案详情
  getTionshipDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/treatment/detail'),
      method: 'post',
      data
    }, option));
  },
  // 修改检查及治疗方案
  setTionshipDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/treatment/update'),
      method: 'post',
      data
    }, option));
  },
  // 删除检查及治疗方案
  deleteTionshipDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/treatment/delete'),
      method: 'post',
      data
    }, option));
  },

  // 新增标签
  addPectLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/add'),
      method: 'post',
      data
    }, option));
  },
  // 修改标签
  updatePectLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/update'),
      method: 'post',
      data
    }, option));
  },
  // 删除标签
  deletePectLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/delete'),
      method: 'post',
      data
    }, option));
  },
  // 标签列表
  getPectLabelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 标签详情
  getPectLabelDeatil (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/detail'),
      method: 'post',
      data
    }, option));
  },
  // ====================================
  // 定位查询
  // 实时定位查询
  LocationQuery_realTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/location/current'),
      method: 'post',
      data
    }, option));
  },
  // 基础统计---胸痛分布(年、月)
  getStatisticsChestPain (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/chestPain'),
      method: 'post',
      data
    }, option));
  },
  // 历史轨迹查询
  LocationQuery_historyTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/location/history'),
      method: 'post',
      data
    }, option));
  },
  // 基础统计 --- pci分布
  getStatisticsPciPain (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/pciPain'),
      method: 'post',
      data
    }, option));
  },
  // 基础统计 --- 年龄分布
  getStatisticsAgePain (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/agePain'),
      method: 'post',
      data
    }, option));
  },
  // 质控统计 --- 诊断占比
  getStatisticsDiagnosisPain (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/diagnosisPain'),
      method: 'post',
      data
    }, option));
  },
  // 质控统计 --- STEMI患者诊断占比
  getStatisticsDiagnosisStemi (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/diagnosisStemi'),
      method: 'post',
      data
    }, option));
  },
  // 质控统计 --- 直达导管室占比
  getStatisticsDiagnosisDirect (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/diagnosisDirect'),
      method: 'post',
      data
    }, option));
  },
  // 质控统计 --- ECG远程传输占比
  getStatisticsDiagnosisEcgTransfer (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/diagnosisEcgTransfer'),
      method: 'post',
      data
    }, option));
  },
  // 质控统计 --- 出院药物占比
  getOutHospitalMedicine (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/outHospitalMedicine'),
      method: 'post',
      data
    }, option));
  },
  // S2FMC平均时间
  getDiagnosisYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/diagnosisYear'),
      method: 'post',
      data
    }, option));
  },
  // FMC2ECG平均时间
  getHeartTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/heartTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // D2B平均时间
  getArrivalsGuideWireTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/arrivalsGuideWireTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // D2W平均时间
  getArrivalsHeartTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/arrivalsHeartTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // FMC2W平均时间
  getGuideWireTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/guideWireTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // 肌钙蛋白抽血到出结果平均时间
  getBloodDrawTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/bloodDrawTimeYear'),
      method: 'post',
      data
    }, option));
  },

  // STEMI确诊至服用双抗药物平均时间
  getConfirmedTakeTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/confirmedTakeTimeYear'),
      method: 'post',
      data
    }, option));
  },

  // 导管室激活平均时间
  getCatheterRoomActiTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/catheterRoomActiTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // 心电图报告时间
  getEcgTimeYear (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/sys/statistics/ecgTimeYear'),
      method: 'post',
      data
    }, option));
  },
  // 报警记录
  getAlarmList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/alarm/list'),
      method: 'post',
      data
    }, option));
  },
  // 处理报警
  processAlarm (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/alarm/process'),
      method: 'post',
      data
    }, option));
  },
  // 批量处理报警
  processAlarmList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/alarm/batchProcess'),
      method: 'post',
      data
    }, option));
  },
  // 标签详情(占位置用)
  getPectLabelDeatil123 (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/detail'),
      method: 'post',
      data
    }, option));
  },
  // 病患详情
  // 用于pad页面查询病患地图位置
  realTimeDisease_patientDetails (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/detail'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 查询上报月份列表
  reportMonthListPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportMonth/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 新增上报月份任务
  reportMonthAdd (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportMonth/add'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 查询上报明细
  reportMonthReportDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportMonth/reportDetail'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 按年份查询月份列表
  reportMonthMonthList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportMonth/monthList'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 查询添加上报数据列表 - 全部
  reportMonthQueryAddReportPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/reportMonth/queryAddReportPage'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 执行上报
  reportExecuteReport (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/report/executeReport'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 手动强制归档
  manualArchive (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/manualArchive'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理 - 完成时间管理表
  patientHisComplete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/complete'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理 - 提交时间管理表
  patientHisSubmit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/submit'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理 - 平台建档
  reportExecuteFile (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/report/executeFile'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理 - 退回
  patientHisFailback (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/failback'),
      method: 'post',
      data
    }, option));
  },
  // 报警记录查询
  alarmListPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/alarm/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询配置 (新)
  pectConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/detail'),
      method: 'post',
      data
    }, option));
  },
  // 修改配置 (新)
  pectConfigUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询第三方急诊病患
  pectPatientQueryThirdPatient (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/queryThirdPatient'),
      method: 'post',
      data
    }, option));
  },
  // 手动同步病患
  pectPatientsyncPatient (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/syncPatient'),
      method: 'post',
      data
    }, option));
  },
  // 病患管理 - 新增病患
  pectPatientHisAdd (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/add'),
      method: 'post',
      data
    }, option));
  },
  // 数据上报 - 执行归档
  pectReportExecuteArchive (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/report/executeArchive'),
      method: 'post',
      data
    }, option));
  },
  // 名单管理 - 查询胸痛科室病区角色
  pectPersonQueryDeptAreaRole (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/queryDeptAreaRole'),
      method: 'post',
      data
    }, option));
  },
  // 同步数据至国家开放平台
  syncDataToCountry (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/report/executeSync'),
      method: 'post',
      data
    }, option));
  }
};

export default pectoralgiaAPI;
