/*
 * @Author: zxx
 * @Date: 2020-09-23 14:36:40
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-17 18:50:54
 * @FilePath: \xinsec-iot-zhaoqing\src\api\modules\three.js
 */
import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

const thirdAPI = {
  // 监控大屏-获取标签位置
  deviMonitorLocateByFloorNo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/devi/monitor/locateByFloorNo', method: 'post', data }, option));
  },
  // 监控大屏-获取总统计数据
  assetMonitorOutLine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/asset/monitor/outLine', method: 'post', data }, option));
  },
  // 监控大屏-获取资产标签位置
  assetMonitorLocateByFloorNo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/asset/monitor/locateByFloorNo', method: 'post', data }, option));
  },
  // 监控大屏-获取基站数据
  assetMonitorBaseStationList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/asset/monitor/baseStationList', method: 'post', data }, option));
  },
  // 监控大屏-获取基站统计
  assetMonitorCountbaseStation (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/asset/monitor/countbaseStation', method: 'post', data }, option));
  },
  // 监控大屏-获取资产统计
  assetMonitorCountAssetByTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/asset/monitor/countAssetByTime', method: 'post', data }, option));
  },
  // 监控大屏-获取病患看护数据
  patiMonitorStatisticsForMonitor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/pati/monitor/statisticsForMonitor', method: 'post', data }, option));
  },
  // 监控大屏-获取绿通环节统计数据
  emerMonitorStatisticsForMonitor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/third/emer/monitor/statisticsForMonitor', method: 'post', data }, option));
  }
};

export default thirdAPI;
