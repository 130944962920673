import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/devi';
  return basic_url + url;
}

const deviAPI = {
  // 终端设置
  // 获取低电量的设备列表
  getLowPowerList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/infuTutelage/getLowPowerList'),
      method: 'post',
      data
    }, option));
  },
  // 删除床垫终端
  deleteBedMattress (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/mattress/delete'),
      method: 'post',
      data
    }, option));
  },
  // 批量删除床垫终端
  deleteMoreBedMattress (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/mattress/deleteList'),
      method: 'post',
      data
    }, option));
  },
  // Excel导入设备
  ExcelBedMattress (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/mattress/importMattress'),
      method: 'post',
      data
    }, option));
  },
  // 温湿度相关设备接口
  // 分页查询终端
  getHumiMonitorList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/humiMonitor/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 新增监测终端
  getHumiMonitorAdd (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/humiMonitor/add'),
      method: 'post',
      data
    }, option));
  },
  // 编辑监测终端
  updateHumiMonitor (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/humiMonitor/update'),
      method: 'post',
      data
    }, option));
  },
  // 删除温湿度监测终端
  delHumiMonitor (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/humiMonitor/delete'),
      method: 'post',
      data
    }, option));
  },
  // 监护设备列表
  getHumiMonitor (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/humiMonitor/list'),
      method: 'post',
      data
    }, option));
  },

  // 输液终端
  // 列表查询监测终端列表
  getInfuTutelage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/infuTutelage/delete'),
      method: 'post',
      data
    }, option));
  },
  // 添加监护设备
  addInfuTutelage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/infuTutelage/add'),
      method: 'post',
      data
    }, option));
  },
  // 修改监护设备
  updateInfuTutelage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + ('/api/infu/tutelageBind/updateTutelage'),
      method: 'post',
      data
    }, option));
  },
  // 删除监护设备
  deleteInfuTutelage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/infuTutelage/delete'),
      method: 'post',
      data
    }, option));
  },

  // 设备管理开始
  // 添加设备类型
  addBaseMachineType (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/machineType/add'),
      method: 'post',
      data
    }, option));
  },
  // 删除设备类型
  delBaseMachineType (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/machineType/delete'),
      method: 'post',
      data
    }, option));
  },
  // 修改设备类型
  updateBaseMachineType (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/machineType/update'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询设备类型
  getBaseMachineTypelist (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/machineType/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询设备类型
  getBaseMachineTypeAlllist (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/machineType/list'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询RFID读写器
  getRfidReaderList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/rfid/reader/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 新增RFID读写器
  addRfidReader (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/rfid/reader/add'),
      method: 'post',
      data
    }, option));
  },
  // 修改RFID读写器
  updateRfidReader (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/rfid/reader/update'),
      method: 'post',
      data
    }, option));
  },
  // 修改RFID读写器
  delRfidReader (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/rfid/reader/delete'),
      method: 'post',
      data
    }, option));
  },

  // 标签管理
  // 标签管理新增
  addRfidLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/add'),
      method: 'post',
      data
    }, option));
  },
  // 标签管理删除
  delRfidLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/delete'),
      method: 'post',
      data
    }, option));
  },
  // 标签管理修改
  updateRfidLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/update'),
      method: 'post',
      data
    }, option));
  },
  // 标签管理分页查询
  getRfidLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 标签管理不分页查询
  getRfidLabelList_nopage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/list'),
      method: 'post',
      data
    }, option));
  },
  // 导入
  labelImport (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/label/import'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理新增
  addRail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/add'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理删除
  delRail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/delete'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理修改
  uploadRail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/update'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理列表查询（分页）
  getRailList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理列表查询（不分页）
  getRailList_noPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/list'),
      method: 'post',
      data
    }, option));
  },
  // 围栏管理详情查询
  getRailDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/rail/detail'),
      method: 'post',
      data
    }, option));
  },

  // 分页查询围栏模版
  getRailTemplateList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/railTemplate/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询围栏模版详情
  getRailTemplateDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/railTemplate/detail'),
      method: 'post',
      data
    }, option));
  },
  // 添加围栏模版
  addRailTemplate (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/railTemplate/add'),
      method: 'post',
      data
    }, option));
  },
  // 修改围栏模版
  updateRailTemplate (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/base/railTemplate/update'),
      method: 'post',
      data
    }, option));
  },
  // 删除模版
  deleteRailTemplate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/base/railTemplate/delete'), method: 'post', data }, option));
  },

  // 有源rfid列表*(分页)
  getRfidActiveListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/listPage'), method: 'post', data }, option));
  },
  // 有源rfid列表详情
  getRfidActiveDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/detail'), method: 'post', data }, option));
  },
  // 有源rfid列表*(新增)
  getRfidActiveAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/add'), method: 'post', data }, option));
  },
  // 有源rfid列表*(编辑)
  getRfidActiveUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/update'), method: 'post', data }, option));
  },
  // 有源rfid列表*(删除)
  getRfidActiveDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/delete'), method: 'post', data }, option));
  },
  // 有源rfid列表(母婴设备监控有用到)
  getRfidActiveList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/rfidActive/list'), method: 'post', data }, option));
  },
  // 有源rfid报警记录列表(母婴设备监控有用到)
  getAlarmRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/listPage'), method: 'post', data }, option));
  },
  // 标签绑定详情 - 查询
  getbaseLabelDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/base/label/detail'), method: 'post', data }, option));
  },

  // 清除声光报警
  setRyAlarmCloseAll (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/ryAlarm/closeAll'), method: 'post', data }, option));
  },

  // 蓝牙终端管理
  // 查询蓝牙终端设备列表
  getbluetoothTerminalList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/listPage'), method: 'post', data }, option));
  },
  // 蓝牙终端导入
  importDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/importDevice'), method: 'post', data }, option));
  },
  // 新增/编辑蓝牙终端
  addBluetoothTerminal (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/add'), method: 'post', data }, option));
  },
  // 蓝牙终端编辑
  updateBluetoothTerminal (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/update'), method: 'post', data }, option));
  },
  // 蓝牙终端删除
  deleteBluetoothTerminal (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/delete'), method: 'post', data }, option));
  },

  // LoRa转换器管理
  // lora转换器分页查询
  loRaDatalistPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converter/listPage'), method: 'post', data }, option));
  },
  // lora转换器新增
  loRaAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converter/add'), method: 'post', data }, option));
  },
  // lora转换器修改
  loRaUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converter/update'), method: 'post', data }, option));
  },
  // lora转换器白名单查询
  loRaWhiteBindList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterBind/list'), method: 'post', data }, option));
  },
  // lora转换器添加白名单
  loRaConverterBindAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterBind/add'), method: 'post', data }, option));
  },
  // lora命令下发记录
  loRaConverterCmdRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterCmdRecord/list'), method: 'post', data }, option));
  },
  // lora转换器删除
  loRaDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converter/delete'), method: 'post', data }, option));
  },
  // 查询蓝牙终端设备列表
  loRaSignsList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/signs/list'), method: 'post', data }, option));
  },
  // 启动嗅探
  loRaStartScan (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converter/startScan'), method: 'post', data }, option));
  },
  // lora转换器上行数据查询
  loRaConverterUploadListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterUpload/listPage'), method: 'post', data }, option));
  },
  // 看护2.5.0新增
  // 查询手环接收报警设置
  getRecAlarmConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/recAlarmConfig/list'), method: 'post', data }, option));
  },
  //
  updateRecAlarmConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/recAlarmConfig/update'), method: 'post', data }, option));
  },
  // 查询标签位置
  getInfoLocateResults (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/base/label/locateResults'), method: 'post', data }, option));
  },
  // 设备管理 1.1.5
  // OTA固件
  addConverterFirmware (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterFirmware/add'), method: 'post', data }, option));
  },
  deleteConverterFirmware (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterFirmware/delete'), method: 'post', data }, option));
  },
  getConverterFirmwareListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterFirmware/listPage'), method: 'post', data }, option));
  },
  upgradeConverterFirmware (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/converterFirmware/upgrade'), method: 'post', data }, option));
  },
  // OTA升级记录
  getFirmwareRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/firmwareRecord/listPage'), method: 'post', data }, option));
  },
  // 安全求助2.2.4
  // 获取标签的定位
  getFindAllLabelByMacs (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/base/label/findAllLabelByMacs'), method: 'post', data }, option));
  },
  getCameraList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/camera/list'), method: 'post', data }, option));
  },
};

export default deviAPI;
