var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticStyle: {
        width: "100%",
        height: "100%",
        "background-color": "#000000",
        margin: "0 auto"
      },
      on: { dblclick: _vm.fullscreenSwich }
    },
    [
      _c("div", { staticClass: "buttons-box", attrs: { id: "buttonsBox" } }, [
        _c("div", { staticClass: "buttons-box-left" }, [
          !_vm.playing
            ? _c("i", {
                staticClass: "iconfont el-icon-video-play jessibuca-btn",
                on: { click: _vm.playBtnClick }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.playing
            ? _c("i", {
                staticClass: "iconfont el-icon-video-pause jessibuca-btn",
                on: { click: _vm.pause }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont el-icon-switch-button jessibuca-btn",
            on: { click: _vm.destroy }
          }),
          _vm._v(" "),
          _vm.isNotMute
            ? _c("i", {
                staticClass: "iconfont el-icon-bell jessibuca-btn",
                on: {
                  click: function($event) {
                    return _vm.mute()
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNotMute
            ? _c("i", {
                staticClass:
                  "iconfont el-icon-close-notification jessibuca-btn",
                on: {
                  click: function($event) {
                    return _vm.cancelMute()
                  }
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-box-right" }, [
          _c("span", { staticClass: "jessibuca-btn" }, [
            _vm._v(_vm._s(_vm.kBps) + " kb/s")
          ]),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont el-icon-crop jessibuca-btn",
            staticStyle: { "font-size": "1rem !important" },
            on: { click: _vm.screenshot }
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont el-icon-refresh jessibuca-btn",
            on: { click: _vm.playBtnClick }
          }),
          _vm._v(" "),
          !_vm.fullscreen
            ? _c("i", {
                staticClass: "iconfont el-icon-full-screen jessibuca-btn",
                on: { click: _vm.fullscreenSwich }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.fullscreen
            ? _c("i", {
                staticClass: "iconfont el-icon-rank jessibuca-btn",
                on: { click: _vm.fullscreenSwich }
              })
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }