export default {
  namespaced: true,
  state: {
    id: 0,
    name: '',
    deptId: '',
    locationIdList: [], // 病区权限
    roleIdList: [] // 角色列表
  },
  mutations: {
    updateId (state, id) {
      state.id = id;
    },
    updateName (state, name) {
      state.name = name;
    },
    setDeptId (state, deptId) {
      state.deptId = deptId;
    },
    setLocationIdList (state, locationIdList) {
      state.locationIdList = locationIdList;
    },
    setRoleIdList (state, roleIdList) {
      state.roleIdList = roleIdList;
    }
  }
};
