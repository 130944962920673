// i18n.js

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
import Cookies from 'js-cookie'
// import locale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: Cookies.get('language') || 'cn',
  messages
})

// locale.i18n((key, value) => i18n.t(key, value)) // 兼容element

export default i18n
