import Vue from 'vue';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
// 引入智能输液图标库
import './assets/transfusion-icon/iconfont.css';
// 引入资产管理图标库
import './assets/assets-icon/iconfont.css';
import './assets/assets-icon2/iconfont.css';
// 引入病患监护图标库
import './assets/care-icon/iconfont.css';
// 引入急诊管理图标库
import './assets/emer-icon/iconfont.css';
import './assets/emer-icon/iconfont2.css';
// 引入白板护理图标库
import './assets/nurse-icon/iconfont.css';
// 引入白板护理图标库1.3版本，到时之前的要去除
import './assets/nurse_icon_1.3/iconfont.css';
// 引入母婴管理图标库
import './assets/baby-icon/iconfont.css';
// 引入白板管理图标库
import './assets/manage-icon/iconfont.css';
// 引入温湿度管理图标库
import './assets/humiture-icon/iconfont.css';
import './assets/care-icon/simpletextrotator.css';
// 引入安全求组管理图标库
import './assets/help-icon/iconfont.css';
// 引入系统管理图标库
import './assets/sys-icon/iconfont.css';
// 视频图标
// import './assets/video-icon/iconfont.css';


// 引入自定义swiper的css文件
// import './assets/care-icon/swiper.css';
import i18n from './i18n/i18n';
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import '@/styles/index.scss'; // global css
import '../theme/index.css';
import App from './App';
import store from './store';
import router from './router';
import VueCookie from 'vue-cookie'; // api: https://github.com/alfhen/vue-cookie
import apis from '@/api/index.js';
import '@/icons'; // icon
import '@/permission'; // permission control
import cloneDeep from 'lodash/cloneDeep';
import ButtonScreen from '@/components/button-screen/index.js';
import '@/utils/common';
import { getUserEncryptionName } from '@/utils/common';
import { getCameraSrc } from '@/utils/cameraPlayer'
import {
  formatDate,
  reduceSecond,
  formatTime,
  isToday,
  zeroize,
  formatSeconds,
  formatAge,
  timeWeekFormat,
  getTimeLong,
  desensitization
} from '@/utils/formatDate';
import {
  formatHttpData,
  setLoading,
  setLoadingAll,
  messageCallback,
  changeFloor,
  dataTrim,
  formatAge2,
  multipleReq,
  judgeDataType2,
  radioExt,
  getAlarmType
} from '@/utils';
import moment from 'moment';
import { $isAuth } from '@/api/apiUrls.js';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// Vue.use(VueAwesomeSwiper);
// import 'swiper/css/swiper.css';
import animated from 'animate.css'; // npm install animate.css --save安装，在引入

import md5 from 'js-md5';
import scroll from 'vue-seamless-scroll'; // 无缝轮播组件
import eventEmitter from '@/utils/eventEmitter.js'; // 事件触发器
import eventEnum from '@/utils/eventEnum.js'; // 事件枚举
import Export from '@/utils/request';
import { message } from '@/utils/resetMessage.js';
import { getCustomWords } from '@/utils/getCustomWords.js'; // 获取资产管理自定义文案
import Print from 'vue-print-nb'
Vue.use(Print)


// import {newMessage} from  '@/utils/reset.js';
// import Vconsole from 'vconsole';
// let vConsole1 = new Vconsole();
const ENV = require('@/config/env/' + process.env.VUE_APP_ENV + '.js');

Vue.use(scroll);
Vue.use(animated);

import ListTable from '@/components/public/list-table.vue';
import PagesView from '@/components/public/pages-view.vue';
import PopDialog from '@/components/public/dialog.vue';
import FooterFixed from '@/components/public/footer-fixed.vue';
import Jessibuca from '@/components/jessibuca/index.vue';
// 全局列表
Vue.component('ListTable', ListTable);
// 全局分页
Vue.component('PagesView', PagesView);
// 全局弹窗
Vue.component('PopDialog', PopDialog);
// 底部悬浮
Vue.component('FooterFixed', FooterFixed);
Vue.component('Jessibuca', Jessibuca);

/**
 * If you don't want to use mock-server
 * you want to use mockjs for request interception
 * you can execute:
 *
 * import { mockXHR } from '../mock'
 * mockXHR()
 */

ElementUI.Dialog.props.closeOnClickModal.default = false;

// set ElementUI lang to EN
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
// 覆盖全局消息提示，需放在use element ui 后面
Vue.prototype.$message = message;
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(VueCookie);
Vue.use(ButtonScreen); // 页面顶部自定义按钮

Vue.config.productionTip = false;

Vue.prototype.$Vconsole = null;

// 挂载Vue原型上的方法，统一用$命名
Vue.prototype.$apis = apis; // api请求
Vue.prototype.$isAuth = $isAuth; // 权限方法
Vue.prototype.$fmMapOpts = window.SITE_CONFIG.fmMapOpts; // 蜂鸟地图配置
Vue.prototype.$dataTrim = dataTrim;
Vue.prototype.formatHttpData = formatHttpData; // 处理http返回值
Vue.prototype.formatDate = formatDate; // 格式化日期
Vue.prototype.reduceSecond = reduceSecond; // 当前时间,减少多少秒后的时间
Vue.prototype.isToday = isToday; // 判断当前时间是否是今天
Vue.prototype.formatTime = formatTime; // 格式化日期
Vue.prototype.judgeDataType = judgeDataType2; // 获取数据类型
Vue.prototype.formatSeconds = formatSeconds; // 格式化日期
Vue.prototype.timeWeekFormat = timeWeekFormat; // 格式化日期年月日星期时分
Vue.prototype.getTimeLong = getTimeLong; // 格式化剩余时间
Vue.prototype.formatAge = formatAge; // 格式化年龄
Vue.prototype.formatAge2 = formatAge2; // 格式化年龄
Vue.prototype.zeroize = zeroize; // 补位
Vue.prototype.setLoading = setLoading; // 设置加载状态
Vue.prototype.setLoadingAll = setLoadingAll; // 设置全局加载状态
Vue.prototype.changeFloor = changeFloor; // 转换楼层
Vue.prototype.$messageCallback = messageCallback; // 错误提示
Vue.prototype.$multipleReq = multipleReq; // 处理大数据请求
Vue.prototype.$md5 = md5; // md5加密
Vue.prototype.$CONFIG_ENV = ENV; // 环境配置信息
Vue.prototype.$eventEmitter = eventEmitter; // 事件触发器
Vue.prototype.$eventEnum = eventEnum; // 事件枚举
Vue.prototype.$desensitization = desensitization; // 名字脱敏
Vue.prototype.$export = Export; // 导出表格
Vue.prototype.$moment = moment; // 时间格式化方法(优先使用,方法较为全面)
Vue.prototype.$radioExt = radioExt; // element-radios反选
Vue.prototype.$getAlarmType = getAlarmType; // 获取报警类型
Vue.prototype.$getCustomWords = getCustomWords; // 获取资产管理自定义文案
Vue.prototype.$getUserEncryptionName = getUserEncryptionName;
Vue.prototype.$getCameraSrc = getCameraSrc;

// 加密算法
Vue.prototype.$encryption = (val) => {
  // 判断当前环境是否为广二环境，广二用base64加密，其他医院用md5
  // return process.env.VUE_APP_ENV === 'gyey' ? btoa(val) : md5(val);
  return md5(val);
};
import axios from 'axios';
Vue.prototype.$axios = axios;
// 全局控制element组件尺寸
Vue.prototype.$ELEMENT = {
  size: 'medium'
};
// 事件总线
// Vue.prototype.$Bus = new Vue();

moment.locale('zh-cn');// 设置为中文
store.commit('common/setClientWidth', document.body.clientWidth);

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state);
window.OAuth2 = {}
window.vm = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
});
// 注入拖拽
import './utils/directives';

