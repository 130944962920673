/*
 * @Author: zxx
 * @Date: 2020-09-29 16:25:04
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-18 16:05:33
 * @FilePath: \xinsec-iot-zhaoqing\src\settings.js
 */

module.exports = {

  // title: '信尚安物联 医疗物联网应用系统',
  // title: '九龙坡区人民医院 医疗物联网应用系统',
  // title: '重庆市沙坪坝区中医院 医疗物联网应用系统',
  // title: `${title.hospitalName} 医疗物联网应用系统`,
  // title: '重庆市沙坪坝区中医院 医疗物联网应用系统',
  // title: `${title.hospitalName} 医疗物联网应用系统`,
  // title: '重庆市沙坪坝区中医院 医疗物联网应用系统',
  // title: '九龙坡区人民医院 医疗物联网应用系统',
  // title: '重庆市沙坪坝区中医院 医疗物联网应用系统',
  // title: '首都医科大学宣武医院 医疗物联网应用系统',
  // title: '肇庆市第一人民医院 医疗物联网应用系统',
  title: '医疗物联网应用系统',
  // title: (get_courtyardInfo() && get_courtyardInfo().hospitalName) || '',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
};
