export default {
  namespaced: true,
  state: {
    humitrueNoticeList: [],
    alarmTypeOpt: {// 报警类型选项
      1: { label: '温度异常', value: 1 },
      2: { label: '湿度异常', value: 2 },
      3: { label: '设备离线', value: 3 },
      4: { label: '低电量', value: 4 }
    },
    // 搜索类型
    searchOption: [
      {
        value: 0,
        label: '监测点'
      },
      {
        value: 1,
        label: '设备ID'
      }
    ]
    // alarmType: {
    //   1: '温度异常',
    //   2: '湿度异常',
    //   3: '设备离线',
    //   4: '低电量'
    // } // 报警类型
  },
  mutations: {
    updateAlarmType (state, alarmType) {
      state.alarmType = alarmType;
    },
    setHumitrueNoticeList (state, noticeList) {
      state.humitrueNoticeList = noticeList;
    },
    pushtHumitrueNoticeList (state, notice) {
      state.humitrueNoticeList.push(notice);
    },
    setAlarmTypeOpt (state, alarmTypeOpt) {
      state.alarmTypeOpt = alarmTypeOpt;
    }
  }
};
