import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/momb';
  return basic_url + url;
}

const babyAPI = {
  // 巡查事项 - 列表（不分页）
  getInspectItemListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectItem/list'), method: 'post', data }, option));
  },
  // 巡查事项 - 新增
  addInspectItem (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectItem/add'), method: 'post', data }, option));
  },
  // 巡查事项 - 删除
  delInspectItem (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectItem/delete'), method: 'post', data }, option));
  },
  // 巡查记录 - 列表（分页）
  getInspectRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectRecord/listPage'), method: 'post', data }, option));
  },
  // 巡查记录 - 新增
  addInspectRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectRecord/add'), method: 'post', data }, option));
  },
  // 母婴列表---床位模式
  getQueryMomBabyPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/mom/queryMomBabyPage'), method: 'post', data }, option));
  },
  // 母婴列表---列表模式
  getQueryMomBabyPageList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/mom/queryMomBabyPageList'), method: 'post', data }, option));
  },
  // 报警列表（分页）
  getAlarmRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/listPage'), method: 'post', data }, option));
  },
  // 报警列表（不分页）
  getAlarmRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/list'), method: 'post', data }, option));
  },
  // 报警详情
  getAlarmRecordDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/detail'), method: 'post', data }, option));
  },
  // 处理报警
  setProcessAlarmRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/process'), method: 'post', data }, option));
  },
  // 一键处理报警
  setProcessHisAlarmRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/processHis'), method: 'post', data }, option));
  },
  // 处理全部报警
  setProcessHisRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/processHisRecord'), method: 'post', data }, option));
  },
  // 标签预绑定
  setLabelBeforBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/beforBind'), method: 'post', data }, option));
  },
  // 标签绑定
  setLabelBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/bind'), method: 'post', data }, option));
  },
  // 标签解绑
  setLabelRelieveBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/relieveBind'), method: 'post', data }, option));
  },
  // 标签更绑
  setLabelReplaceBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/replaceBind'), method: 'post', data }, option));
  },
  // 出院手动解绑标签
  setLabelUnboundUnBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/unboundUnBind'), method: 'post', data }, option));
  },
  // 空闲标签列表
  getLabelidleList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/label/idle'), method: 'post', data }, option));
  },
  // 根据标签查找婴儿
  getByLabelId (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/getByLabelId'), method: 'post', data }, option));
  },
  // 婴儿签出
  setSiginOut (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/siginout/out'), method: 'post', data }, option));
  },
  // 婴儿签入
  setSiginIn (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/siginout/in'), method: 'post', data }, option));
  },
  // 时间分布
  getStatisticsTimeDist (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statistics/timeDist'), method: 'post', data }, option));
  },
  // 汇总、占比统计
  getStatisticsSummary (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statistics/summary'), method: 'post', data }, option));
  },
  // 汇总统计 - 工作量
  getStatisticsWorkload (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statistics/workload'), method: 'post', data }, option));
  },
  // 汇总统计 - 腕带消耗统计
  getStatisticsConsume (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statistics/wristbandConsume'), method: 'post', data }, option));
  },
  // 根据标签ID查询
  getBabyLocationByLable (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/babyLocation/queryByLabelId'), method: 'post', data }, option));
  },
  // 列表婴儿定位
  getBabyLocationList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/babyLocation/list'), method: 'post', data }, option));
  },
  // 签入签出列表
  getSiginoutListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/siginout/listPage'), method: 'post', data }, option));
  },
  // 操作记录列表 (分页)
  getLogListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/log/listPage'), method: 'post', data }, option));
  },
  // 母婴配对列表 (分页)
  getMatchListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/match/listPage'), method: 'post', data }, option));
  },
  // 同步婴儿数据
  getLoadBabyData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/loadBabyData'), method: 'post', data }, option));
  },
  // 根据母亲id获取在院婴儿
  getFindByMomId (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/findByMomId'), method: 'post', data }, option));
  },
  // 获取在院且未绑定标签的床位列表
  getQueryMombBabyNobindLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/queryMombBabyNobindLabelList'), method: 'post', data }, option));
  },
  // 占位用(无实际意义)
  test (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/inspectRecord/add'), method: 'post', data }, option));
  },
  // 婴儿标签
  // 删除
  babyLabel_delete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/delete'), method: 'post', data }, option));
  },
  // 导入
  babyLabel_export (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/import'), method: 'post', data }, option));
  },
  // 新增
  babyLabel_add (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/add'), method: 'post', data }, option));
  },
  // 标签分页查询
  babyLabel_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/listPage'), method: 'post', data }, option));
  },
  // 激活明细
  babyLabel_detailed (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/activeDetails'), method: 'post', data }, option));
  },
  // 批量删除婴儿标签
  babyLabel_batchDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/baby/label/deleteBatchMomLabel'), method: 'post', data }, option));
  },
  // 婴儿签出编辑
  updateSignout (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/siginout/updateSignout'), method: 'post', data }, option));
  },
  // 婴儿签出详情
  signoutDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/siginout/detail'), method: 'post', data }, option));
  }
  // // 查询母婴列表数据
  // queryMomBabyPageList (params) {
  //   return http.post('admin/momb/mom/queryMomBabyPageList', params);
  // },
  // // 解绑离院未解绑
  // mombUnBindlabel (params) {
  //   return http.post('/admin/momb/bind/bindlabel', params);
  // },
  // // 获取婴儿离线列表
  // getBabyOffLineList (params) {
  //   return http.post('/admin/devi/rfidalarm/queryPageDevice', params);
  // },
  // // 获取婴儿报警列表
  // getBabyNoticeList (params) {
  //   return http.post('/admin/devi/rfidalarm/list', params);
  // },
  // // 获取婴儿报警详情
  // getBabyNoticeDetail (params) {
  //   return http.post('/admin/devi/rfidalarm/detail', params);
  // },
  // // 巡查事项 - 列表
  // getInspectionList (params) {
  //   return http.post('/admin/momb/inspection/item/list', params);
  // },
  // // 巡查事项 - 添加
  // addInspection (params) {
  //   return http.post('/admin/momb/inspection/item/save', params);
  // },
  // // 巡查事项 - 删除
  // delInspection (params) {
  //   return http.post('/admin/momb/inspection/item/delete', params);
  // },
  // // 巡查记录 - 所有列表
  // getInspectAllList (params) {
  //   return http.post('/admin/momb/inspection/record/list', params);
  // },
  // // 处理报警提交
  // getDisposeSubmit(params) {
  //   return http.post('/admin/devi/rfidalarm/process', params);
  // },
  // // 查询母婴列表
  // getMomBabyList(params) {
  //   return http.post('/admin/momb/mom/queryMomBabyPage', params);
  // },
  // // 签入签出列表
  // getSignListData(params) {
  //   return http.post('/admin/momb/signout/list', params);
  // },
  // // 母婴配对列表
  // getPairsListData(params) {
  //   return http.post('/admin/momb/match/list', params);
  // },
  // // 操作记录列表
  // getOperateRecordList(params) {
  //   return http.post('/admin/momb/operate/log/list', params);
  // },
  // // 母婴预绑定/绑定/换绑/解绑
  // bindLabel(params) {
  //   return http.post('/admin/momb/bind/bindlabel', params);
  // },
  // // 查询空闲标签列表
  // FreeLabelList(params) {
  //   return http.post('/admin/devi/label/listFree', params);
  // },
  // // 清除声光报警
  // clearLight(params) {
  //   return http.post('/admin/devi/ryAlarm/closeAll', params);
  // },
  // // 婴儿签出签入
  // babySignout(params) {
  //   return http.post('/admin/momb/signout/save', params);
  // },
  // // 有源RFID设备列表查询
  // rfidActiveList(params) {
  //   return http.post('/admin/devi/rfidactive/list', params);
  // },
  // // 获取婴儿定位数据
  // rfidHeartList(params) {
  //   return http.post('/admin/devi/rfidheart/list', params);
  // },
  // // 设备监控 - 列表
  // rfidactiveListNew(params) {
  //   return http.post('/admin/devi/rfidactive/list/monitor', params);
  // },
  // // 汇总统计与占比统计列表
  // getSummaryListData(params) {
  //   return http.post('/admin/momb/statistics/summary', params);
  // },
  // // 汇总统计 - 时间分布
  // getTimeDistribution(params) {
  //   return http.post('/admin/momb/statistics/timeDistribution', params);
  // },
  // // 汇总统计 - 工作量
  // getWorkload(params) {
  //   return http.post('/admin/momb/statistics/workload', params);
  // },
  // // 根据母亲id查婴儿列表
  // getBabyListByMomID(params) {
  //   return http.post('/admin/momb/baby/findByMomIdOnNotLableBaby', params);
  // },
  // // 婴儿最后的位置 - 查询
  // getbabyLocation(params) {
  //   return http.post('/admin/devi/rfidheart/queryLabelCoordinate', params);
  // },
  // // 未绑定标签婴儿床位 - 列表
  // getNoBindList(params) {
  //   return http.post('/admin/momb/baby/queryMombBabyNobindLabelList', params);
  // },
  // // 一键处理婴儿历史消息
  // handleBabyMessage(params) {
  //   return http.post('/admin/devi/rfidalarm/updatHisRecord', params)
  // },
  // // 同步婴儿信息
  // syncBabyMessage(params) {
  //   return http.post('/admin/momb/baby/loadBabyData', params)
  // }
};
export default babyAPI;
