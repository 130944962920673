var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: {
            "modal-append-to-body": _vm.modalAppendToBody,
            title: _vm.title,
            width: _vm.width,
            visible: _vm.visible,
            "show-close": _vm.showClose,
            "close-on-click-modal": _vm.closeClickModal
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleCancel
          }
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.showFooter
            ? _c(
                "div",
                {
                  staticClass: "footer-container",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [_vm._t("footer")],
                2
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }