/*
 * @Author: your name
 * @Date: 2020-11-08 16:47:24
 * @LastEditTime: 2020-11-08 20:23:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\patient.js
 */
let happenedPatientNoticeList = localStorage.getItem('happenedPatientNoticeList');
happenedPatientNoticeList = happenedPatientNoticeList ? JSON.parse(happenedPatientNoticeList) : null;
export default {
  namespaced: true,
  state: {
    patientNoticeList: [], // 全部病患消息
    happenedPatientNoticeList: happenedPatientNoticeList && happenedPatientNoticeList.length > 0 ? happenedPatientNoticeList : [], // 已播放过的警报
    noticeList: [], // 病患消息列表
    patientOutNoticeList: [], // 病患离院消息列表
    dismantleNoticeList: [], // 手环拆除消息列表
    oneKeyNoticeList: [], // 一键报警消息列表
    oneKeyCancelNoticeList: [], // 一键取消报警消息列表
    alarmTypeList: [ // 报警类型
      { type: '全部', value: '' },
      { type: '心率告警', value: [81, 82] },
      { type: '呼吸告警', value: [83, 84] },
      { type: '离床告警', value: 85 },
      { type: '体动频繁告警', value: 86 },
      // { type: '护理提醒', value: 5 },
      // { type: '解绑提醒', value: 6 },
      // { type: '越界报警', value: 7 },
      // { type: '滞留报警', value: 8 },
      { type: 'sos报警', value: 9 },
      // { type: '拆除报警', value: 10 },
      { type: 'sos取消报警', value: 11 },
      //  关于体征监测的值为数组类型
      { type: '体温异常报警', value: [20, 21] },
      { type: '脉搏异常报警', value: [22, 23] },
      { type: '呼吸异常报警', value: [24, 25] },
      { type: '心率异常报警', value: [26, 27] },
      { type: '血氧异常报警', value: [28] },
      { type: '血压异常报警', value: [29, 30] },
      { type: '体温危急值报警', value: [40, 41] },
      { type: '脉搏危急值报警', value: [42, 43] },
      { type: '呼吸危急值报警', value: [44, 45] },
      { type: '心率危急值报警', value: [46, 47] },
      { type: '血氧危急值报警', value: [48] },
      { type: '血压危急值报警', value: [45, 50] },
      { type: '定时任务提醒', value: [62] },
      { type: '特殊体征报警', value: [60] },
      { type: '出院未解绑', value: [13] },

      { type: '滞留报警', value: 15 },
      { type: '签出未归报警', value: 16 },
      { type: '脱落报警', value: 14 },
      { type: '进入围栏报警', value: 17 },
      { type: '离开围栏报警', value: 18 },
      // 2.3.0新增
      { type: '卫生间报警', value: 70 },
      { type: '离线告警', value: 72 },
      { type: '低电量告警', value: 73 },
      { type: '拆除报警', value: 71 },
      // 2.3.5新增
      { type: '手环离线告警', value: 90 },
      { type: '手环低电量告警', value: 91 },
      // 2.5.1新增
      { type: '转床垫确认通知', value: 88 },
      { type: '跌倒告警', value: 92 }

    ],
    alarmTypeObj: {
      1: { type: '(床垫)心率告警', value: 1 },
      2: { type: '(床垫)呼吸告警', value: 2 },
      3: { type: '离床告警', value: 3 },
      4: { type: '体动频繁告警', value: 4 },
      5: { type: '护理提醒', value: 5 },
      6: { type: '解绑提醒', value: 6 },
      // 7: { type: '越界报警', value: 7 },
      8: { type: '滞留报警', value: 8 },

      '9': { type: 'SOS报警', value: 9 },
      '11': { type: 'SOS取消报警', value: 11 },
      '13': { type: '手环出院未解绑', value: 13 },
      '14': { type: '脱落报警', value: 14 },
      '15': { type: '滞留报警', value: 15 },
      '16': { type: '签出未归报警', value: 16 },
      '17': { type: '进入围栏报警', value: 17 },
      '18': { type: '离开围栏报警', value: 18 },
      '20': { type: '体温过高告警', value: 20 },
      '21': { type: '体温过低告警', value: 21 },
      '22': { type: '脉搏过快告警', value: 22 },
      '23': { type: '脉搏过慢告警', value: 23 },
      '24': { type: '呼吸过快告警', value: 24 },
      '25': { type: '呼吸过慢告警', value: 25 },
      '26': { type: '心率过快告警', value: 26 },
      '27': { type: '心率过慢告警', value: 27 },
      '28': { type: '血氧过低告警', value: 28 },
      '29': { type: '血压过高告警', value: 29 },
      '30': { type: '血压过低告警', value: 30 },
      '40': { type: '体温危急高值报警', value: 40 },
      '41': { type: '体温危急低值报警', value: 41 },
      '42': { type: '脉搏危急高值报警', value: 42 },
      '43': { type: '脉搏危急低值报警', value: 43 },
      '44': { type: '呼吸危急高值报警', value: 44 },
      '45': { type: '呼吸危急低值报警', value: 45 },
      '46': { type: '心率危急高值报警', value: 46 },
      '47': { type: '心率危急低值报警', value: 47 },
      '48': { type: '血氧危急低值报警', value: 48 },
      '49': { type: '血压危急低值报警', value: 49 },
      '50': { type: '血压危急高值报警', value: 50 },
      '60': { type: '特殊体征报警', value: 60 },
      '62': { type: '定时任务提醒', value: 62 },
      '63': { type: '体征设备低电量告警', value: 63 },
      '64': { type: '体征设备离线告警', value: 64 },
      '65': { type: '体征设备出院未解绑', value: 65 },
      '66': { type: '定时血压测量失败告警', value: 66 },
      '70': { type: '卫生间报警', value: 70, message: '卫生间紧急呼叫'},
      '71': { type: '拆除报警', value: 71, message: '拆除一键报警终端' },
      '72': { type: '离线报警', value: 72, message: '一键报警终端离线' },
      '73': { type: '低电量报警', value: 73, message: '一键报警终端低电量' },
      '81': { type: '（床垫）心率过快告警', value: 81 },
      '82': { type: '（床垫）心率过慢告警', value: 82 },
      '83': { type: '（床垫）呼吸过快告警', value: 83 },
      '84': { type: '（床垫）呼吸过慢告警', value: 84 },
      '85': { type: '离床告警', value: 85 },
      '86': { type: '体动频繁告警', value: 86 },
      '87': { type: '床垫离线告警', value: 87 },
      '88': { type: '转床垫确认通知', value: 88 },
      '90': { type: '手环离线告警', value: 90 },
      '91': { type: '手环低电量告警', value: 91 },
      '92': { type: '跌倒告警', value: 92 },

      999: { type: '定时体征任务', value: 999 },
    },
    // 体征报警
    signAlarmTypeList: [13, 20, 21, 22, 23, 24, 25, 26, 27, 28, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 60, 62, 63, 64, 65, 66], // 用于区别报警信息是否属于体征监测
    // 体征监测 - 病患管理的病患ID列表
    patientIdList: [], // 病患ID列表
    targetRecordId: null, // 用于侧边栏滚动条定位id,点击体征监测 - 单个病患的闹钟/轮播显示的异常体征警报消息ID
    taskRecordList: [],
    unBindTerminalFlag: false, // 是否一键解绑操作
    ecgList: [], // 病患管理 - 体征监测 - WS传过来的心电数据
    isTransfer: 0 // 记录是否操作转床
  },
  mutations: {
    setTransfer (state, val) {
      state.isTransfer += 1;
    },
    setNoticeList (state, noticeList) {
      state.noticeList = noticeList;
    },
    // 病患消息列表
    pushNoticeList (state, notice) {
      state.noticeList.unshift(notice);
    },
    setPatientOutNoticeList (state, noticeList) {
      state.patientOutNoticeList = noticeList;
    },
    pushPatientOutNoticeList (state, notice) {
      state.patientOutNoticeList.unshift(notice);
    },
    setHappenedPatientNoticeList (state, data) {
      state.happenedPatientNoticeList = data;
    },
    pushHappenedPatientNoticeList (state, data) {
      state.happenedPatientNoticeList.push(data);
    },
    // 手环拆除消息列表
    setDismantleNoticeList (state, noticeList) {
      state.dismantleNoticeList = noticeList;
    },
    pushDismantleNoticeList (state, notice) {
      state.dismantleNoticeList.unshift(notice);
    },
    // 手环一键报警消息列表
    setOneKeyNoticeList (state, noticeList) {
      state.oneKeyNoticeList = noticeList;
    },
    pushOneKeyNoticeList (state, notice) {
      state.oneKeyNoticeList.unshift(notice);
    },
    // 手环一键取消报警消息列表
    setOneKeyCancelNoticeList (state, noticeList) {
      state.oneKeyCancelNoticeList = noticeList;
    },
    pushOneKeyCancelNoticeList (state, notice) {
      state.oneKeyCancelNoticeList.unshift(notice);
    },
    splicePatientOutNoticeList (state, option) {
      state.patientOutNoticeList.splice(option.index, option.num);
    },
    // 全部病患消息列表
    setPatientNoticeList (state, notice) {
      state.patientNoticeList = notice;
    },
    pushPatientNoticeList (state, notice) {
      state.patientNoticeList.unshift(notice);
    },
    setPatientIdList (state, patientIdList) {
      state.patientIdList = patientIdList;
    },
    setTargetRecordId (state, targetRecordId) {
      state.targetRecordId = targetRecordId;
    },
    setTaskRecordList (state, taskRecordList) {
      state.taskRecordList = taskRecordList;
    },
    setUnBindTerminalFlag (state, unBindTerminalFlag) {
      state.unBindTerminalFlag = unBindTerminalFlag;
    },
    setEcgList (state, ecgList) {
      state.ecgList = ecgList;
    },
    setAlarmTypeObj (state, data) {
      state.alarmTypeObj = data
    }
  },
  actions: {
  }
};
