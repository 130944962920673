<template>
  <el-dialog class="dialog-container" v-if="visible" :modal-append-to-body="modalAppendToBody" :title="title" :width="width" :visible.sync="visible" :show-close="showClose" :close-on-click-modal="closeClickModal" @close="handleCancel">
    <slot />
    <div v-if="showFooter" class="footer-container" slot="footer">
      <slot name="footer" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 控制是否显示
    show: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 宽度
    width: {
      type: String,
      default: '800px'
    },
    // 是否显示右上角的关闭X(默认显示)
    showClose: {
      type: Boolean,
      default: true
    },
    // 是否可以点击遮罩层关闭弹框
    closeClickModal: {
      type: Boolean,
      default: false
    },
    // 是否设置 遮罩层是否插入至 body 元素上
    modalAppendToBody: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false, // 是否显示
      showFooter: false // 底部插槽是否被使用
    }
  },
  watch: {
    // 监听显示
    show (val) {
      this.visible = val
    }
  },
  created () {
    // 底部插槽被使用
    if (this.$slots.footer) {
      this.showFooter = true
    }
  },
  methods: {
    // 触发确认
    handleConfirm () {
      this.$emit('click')
    },
    // 触发取消
    handleCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-container /deep/ .el-dialog {
    border-radius: 4px !important;
  }
  .footer-btn 
  ::v-deep .el-dialog {
    border-radius: 4px !important;
  }
  ::v-deep .el-dialog__header {
    line-height: 54px;
    padding: 0 48px 0 16px;
    font-size: 16px;
    font-weight: 500;
    background: #fff !important;
  }
  ::v-depp .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__title {color: #333;font-size: 16px;font-weight: 500;}
  ::v-deep .el-dialog__footer {
    border-radius: 0 0 4px 4px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    background: #fff !important;
  }
  ::v-deep .el-table td, ::v-deep .el-table th {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 24px;
  }
  .footer-container {
    ::v-deep .el-button {
      // line-height: 32px;
      height: 32px;
      padding: 0 26px;
      border-radius: 2px;
      box-shadow: none;
      min-width: 76px;
      box-sizing: border-box;
    }
  }
</style>
