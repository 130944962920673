import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/asse';
  return basic_url + url;
}

const asseAPI = {
  // 分页查询资产列表
  getInfoListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/listPage'), method: 'post', data }, option));
  },
  // 不分页列表查询
  getInfoList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/list'), method: 'post', data }, option));
  },
  // 新增资产
  addInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/add'), method: 'post', data }, option));
  },
  // 编辑资产
  updateInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/update'), method: 'post', data }, option));
  },
  // 资产详情
  getInfoDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/detail'), method: 'post', data }, option));
  },
  // 删除资产
  delInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/delete'), method: 'post', data }, option));
  },

  // 历史轨迹查询
  getInfoHistoryPath (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/historyPath'), method: 'post', data }, option));
  },

  // 资产品牌列表-分页接口
  getBrandListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/listPage'), method: 'post', data }, option));
  },
  // 资产品牌列表-不分页接口
  getBrandList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/list'), method: 'post', data }, option));
  },
  // 资产品牌新增接口
  addBrand (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/add'), method: 'post', data }, option));
  },
  // 资产品牌修改
  updateBrand (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/update'), method: 'post', data }, option));
  },
  // 资产品牌明细
  getBrandDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/detail'), method: 'post', data }, option));
  },
  // 删除资产品牌
  deleteBrand (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/brand/delete'), method: 'post', data }, option));
  },

  // V2.3.17 - xiaoqi - updata **********

  // 资产设备分类列表 - 分页
  getAsseDevClassifyListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDevClassify/listPage'), method: 'post', data }, option));
  },
  // 资产设备分类列表 - 不分页
  getAsseDevClassifyList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDevClassify/list'), method: 'post', data }, option));
  },
  // 资产分类添加
  addAsseDevClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDevClassify/add'), method: 'post', data }, option));
  },
  // 资产分类删除
  deleteleAsseDevClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDevClassify/delete'), method: 'post', data }, option));
  },
  // 资产分类更新
  updateAsseDevClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDevClassify/update'), method: 'post', data }, option));
  },

  // V2.3.17 - xiaoqi - updata **********

  // 资产分类列表不分页接口
  getClassifyList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/list'), method: 'post', data }, option));
  },
  // 资产分类列表分页接口
  getClassifyListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/listPage'), method: 'post', data }, option));
  },
  // 资产分类添加
  addClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/add'), method: 'post', data }, option));
  },
  // 资产分类更新
  updateClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/update'), method: 'post', data }, option));
  },
  // 资产分类详情
  getClassifyDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/detail'), method: 'post', data }, option));
  },
  // 删除
  getClassifyDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/classify/delete'), method: 'post', data }, option));
  },

  // 资产流转
  // 资产状态流转-资产借用接口
  setAssetBorrow (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetBorrow'), method: 'post', data }, option));
  },
  // 资产状态流转-资产借用接口
  setAssetBorrowBack (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetBorrowBack'), method: 'post', data }, option));
  },
  // 资产状态流转-资产借用接口
  setAssetMaintain (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetMaintain'), method: 'post', data }, option));
  },
  // 资产状态流转-资产维修成功接口
  setAssetMaintainFinall (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetMaintainFinish'), method: 'post', data }, option));
  },
  // 资产状态流转-资产报废接口
  setAssetScrap (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetScrap'), method: 'post', data }, option));
  },
  // 资产状态流转-资产遗失接口
  setAssetLose (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetLose'), method: 'post', data }, option));
  },
  // 资产状态流转-资产找回接口
  setAssetLoseBack (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetLoseBack'), method: 'post', data }, option));
  },
  // 资产状态流转-资产转移接口
  setAssetMove (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseState/assetMove'), method: 'post', data }, option));
  },
  // 资产查看明细
  getLogList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/log/list'), method: 'post', data }, option));
  },
  // 任务盘点明细列表查询
  taskResultListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskRecord/taskResultListPage'), method: 'post', data }, option));
  },
  // 操作记录
  getLogListPage (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/log/listPage'), method: 'post', data }, option));
  },
  // 修改申领人
  editPersonName (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/apply/changeApply'), method: 'post', data }, option));
  },
  // 位置记录
  getLocationList (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/location/list'), method: 'post', data }, option));
  },

  // 标签设置
  // 绑定标签
  addBind (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/add'), method: 'post', data }, option));
  },
  // 更绑，只能单项操作
  updateBind (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/update'), method: 'post', data }, option));
  },
  // 解绑，只能单项操作
  deleteBind (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/delete'), method: 'post', data }, option));
  },
  // 能效标签相关的配置
  setBindConfigEnergy (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/configEnergy'), method: 'post', data }, option));
  },
  // 围栏报警相关的配置
  setBindConfigRail (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/configRail'), method: 'post', data }, option));
  },

  // 报警记录
  // 报警记录 (分页)
  getAlarmRecordLisPage (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/listPage'), method: 'post', data }, option));
  },
  // 报警记录 (不分页)
  getAlarmRecordList (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/list'), method: 'post', data }, option));
  },
  // 处理报警记录
  updateAlarmRecord (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/update'), method: 'post', data }, option));
  },
  // 处理报警记录
  getAlarmDetail (data = {}, option = {}) { // '/asseState/assetBorrow'
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/detail'), method: 'post', data }, option));
  },

  // 能效统计
  // 查询单个资产能效使用情况
  getStatEnergyToday (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statEnergyToday'), method: 'post', data }, option));
  },
  // 统计单个资产用电情况
  getStatAssetUseTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statAssetUseTime'), method: 'post', data }, option));
  },

  // 标签定位
  getInfoLocateByFloorNo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/locateByFloorNo'), method: 'post', data }, option));
  },

  // 多楼层标签定位
  getInfolocateAssetList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/locateAssetList'), method: 'post', data }, option));
  },
  // 查询标签位置
  getInfoLocateResults (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/locateResults'), method: 'post', data }, option));
  },

  // 资产统计
  // 查询资产统计--状态
  getAssetsCountState (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/state'), method: 'post', data }, option));
  },
  // 查询资产统计--分类
  getAssetsCountClassify (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/classify'), method: 'post', data }, option));
  },
  // 查询资产统计--管理病区
  getAssetsCountArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/area'), method: 'post', data }, option));
  },
  // 查询资产统计--年度
  getAssetsCountYear (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/year'), method: 'post', data }, option));
  },

  // 资产盘点
  // 资产盘点列表
  getAsseTaskListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/listPage'), method: 'post', data }, option));
  },
  // 主任务对应子任务详情列表
  listTaskInfoByTaskId (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/listTaskInfoByTaskId'), method: 'post', data }, option));
  },
  // 盘点任务添加
  addAsseTask (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/add'), method: 'post', data }, option));
  },
  // 盘点任务编辑
  updateAsseTask (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/update'), method: 'post', data }, option));
  },
  // 盘点任务删除
  deleteAsseTask (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/delete'), method: 'post', data }, option));
  },
  // 盘点任务结束
  setAsseTaskEnd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/taskEnd'), method: 'post', data }, option));
  },
  // 资产任务详情接口
  getAsseTaskDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/detail'), method: 'post', data }, option));
  },
  // 自动盘点盘点
  setTaskBluetoothAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskBluetooth/add'), method: 'post', data }, option));
  },
  // 盘点明细接口
  getAsseDetailsListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/listPage'), method: 'post', data }, option));
  },
  // 查看结果接口
  getAsseTaskResul (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTaskResult/taskResultDetail'), method: 'post', data }, option));
  },
  // 查看结果接口
  getAsseTaskExportExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/exportExcel'), method: 'post', data }, option));
  },

  // 子任务盘点明细分页接口
  getAsseTaskDetailbyChild (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/childListPage'), method: 'post', data }, option));
  },
  // 子任务盘点明细分页接口
  setChildTaskEnd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskChild/childTaskEnd'), method: 'post', data }, option));
  },
  // 子任务查询结果接口
  getChildResultDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTaskResult/childResultDetail'), method: 'post', data }, option));
  },
  // 子任务结果导出接口
  getChildExportExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/childExportExcel'), method: 'post', data }, option));
  },
  // 主任务详情
  asseTaskDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/detail'), method: 'post', data }, option));
  },
  // 子任务详情
  taskChildDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskChild/detail'), method: 'post', data }, option));
  },

  // 能效统计
  // 查询我管理的资产的类型列表
  getManageAreaTypeList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/queryAreaTypeList'), method: 'post', data }, option));
  },
  // 查询我使用的资产的类型列表
  getUseAreaTypeList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/queryUseAreaTypeList'), method: 'post', data }, option));
  },
  // 管理科室今日统计当前设备
  getTodayStatistics (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/todayManagerArea'), method: 'post', data }, option));
  },
  // 今日统计——各科室设备用电量、使用数量
  getTodayManagerAssetCount (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/todayManagerAreaAsset'), method: 'post', data }, option));
  },
  // 今日统计——今日能效报警记录列表
  getEnergyAlarmRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/queryEnergyAlarm'), method: 'post', data }, option));
  },
  // 今日统计——今日使用科室下各医疗设备类型数量
  getTodayTypeCount (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/todayTypeCount'), method: 'post', data }, option));
  },
  // 今日统计——今日同一设备类型各科室使用率
  getTodayTypeUsageRate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/todayTypeUsageRate'), method: 'post', data }, option));
  },
  // 今日统计——今日使用科室下各医疗设备类型用电量
  getTodayTypeCountAsset (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/todayTypeCountAsset'), method: 'post', data }, option));
  },
  // 使用病区设备使用时长排行榜
  getServiceUseTimeRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statDeviceUseTimeRank'), method: 'post', data }, option));
  },
  // 使用病区设备使用率排行榜
  getServiceUsageRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statDeviceUsageRateRank'), method: 'post', data }, option));
  },
  // 使用病区设备用电量排行榜
  getServiceTotalWattageRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statDeviceSumEnergyRank'), method: 'post', data }, option));
  },
  // 各科室使用设备时长排行榜前10位
  getDepartServiceTimeRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/useTimeRankArea'), method: 'post', data }, option));
  },
  // 各科室设备用电量排行榜前10位
  getDepartServiceTotalWattageRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/useEnergyRankArea'), method: 'post', data }, option));
  },
  // 同一类型设备的科室使用率
  getDepartUsageRateRank (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/usageRateTypeArea'), method: 'post', data }, option));
  },
  // 科室设备类型统计
  getServiceTypeTotalWattage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/statTypeUseEnergy'), method: 'post', data }, option));
  },
  // 根据科室统计设备工作、待机数量
  getServiceWorkStandby (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/workStandbyCountArea'), method: 'post', data }, option));
  },
  // 根据科室统计用电量
  getTotalWattage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/sumEnergyCountArea'), method: 'post', data }, option));
  },
  // 根据类型统计管理科室设备工作、待机数量
  getManageServiceWorkStandby (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/workStandbyCountType'), method: 'post', data }, option));
  },
  // 根据类型统计管理科室用电量
  getManageTotalWattage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/sumEnergyCountType'), method: 'post', data }, option));
  },
  // 资产标签分页
  getAsseLabelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/listPage'), method: 'post', data }, option));
  },
  // 获取资产日电流统计
  getelectricCurrentByAsset (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/electricCurrentByAsset'), method: 'post', data }, option));
  },
  // 显示列字段详情
  getFieldDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/field/detail'), method: 'post', data }, option));
  },
  // 修改显示列字段
  setFieldUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/field/update'), method: 'post', data }, option));
  },
  //  下载模板
  getPointData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/outputAsset'), method: 'post', data }, option));
  },

  // 打印模板列表
  getTemplateList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/list'), method: 'post', data }, option));
  },
  // 打印模板新增
  getTemplateAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/add'), method: 'post', data }, option));
  },
  // 设置默认打印模板
  setTemplateDefault (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/setDefault'), method: 'post', data }, option));
  },
  // 取消默认模板
  cancelTemplateDefault (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/cancelDefault'), method: 'post', data }, option));
  },
  // 打印模板批量新增
  addBatchTemplateDefault (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/addBatch'), method: 'post', data }, option));
  },
  // 打印模板批量新增
  printFinishInform (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/printFinishInform'), method: 'post', data }, option));
  },
  // 导出资产管理数据
  exportAssetsData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/exportExcel'), method: 'post', data }, option));
  },
  // 导出资产盘点主任务数据
  exportAssetsTaskDetailsExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/exportExcel'), method: 'post', data }, option));
  },
  // 导出资产盘点子任务数据
  exportAssetsChildTaskDetailsExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/childExportExcel'), method: 'post', data }, option));
  },
  // 资产管理-资产管理更多操作
  getAsseProcessRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/procesRecord'), method: 'post', data }, option));
  },
  // 终端情况统计
  getStatiMonthDeviStat (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/deviStat'), method: 'post', data }, option));
  },
  // 终端情况统计--详情
  getStatiMonthDeviStatDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/statiMonth/deviStatDetail'), method: 'post', data }, option));
  },
  // 资产管理-资产管理更多操作确认
  addCleanAsseProcessRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/addClean'), method: 'post', data }, option));
  },
  addLossAsseProcessRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/addLoss'), method: 'post', data }, option));
  },
  addRepairAsseProcessRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/addRepair'), method: 'post', data }, option));
  },
  addScrapAsseProcessRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/addScrap'), method: 'post', data }, option));
  },
  scrapAsseProcessRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseProcessRecord/list'), method: 'post', data }, option));
  },
  getAsseDeviRailConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDeviRailConfig/list'), method: 'post', data }, option));
  },
  getAsseDeviRailConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDeviRailConfig/listPage'), method: 'post', data }, option));
  },
  addAsseDeviRailConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDeviRailConfig/add'), method: 'post', data }, option));
  },
  updateAsseDeviRailConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDeviRailConfig/update'), method: 'post', data }, option));
  },
  batchUpdateAsseDeviRailConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseDeviRailConfig/batchUpdate'), method: 'post', data }, option));
  },
  getTaskRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskRecord/list'), method: 'post', data }, option));
  },
  // 2.3.7 轨迹日期高亮显示
  getHistoryDay (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/historyDay'), method: 'post', data }, option));
  },
  // 2.3.7-1 资产能效使用率
  getEnergyUseRate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/energyUseRate'), method: 'post', data }, option));
  },
  // 2.3.8 资产标签批量绑定
  tagBinding (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bind/addList'), method: 'post', data }, option));
  },
  // 资产 2.3.9 一键同步
  syncAssetData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/syncAssetData'), method: 'get', data }, option));
  },
  // 资产 2.3.15 打印需求优化
  // 打印记录分页列表
  getPrintRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/printRecord/listPage'), method: 'post', data }, option));
  },
  // 打印记录详情list
  getPrintRecordDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/printRecordDetail/list'), method: 'post', data }, option));
  },
  // 打印记录添加
  addPrintRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/printRecord/add'), method: 'post', data }, option));
  },
  // 打印记录修改
  updatePrintRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/printRecord/update'), method: 'post', data }, option));
  },
  // 打印记录详情
  getPrintDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/printRecord/detail'), method: 'post', data }, option));
  },
  // 2.3.18 资产管理 斑马打印机
  // 斑马打印模板新增
  uploadTemplate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/uploadTemplate'), method: 'post', data }, option));
  },
  // 根据打印模板名称获取模板信息
  getTemplateByName(data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/print/template/getTemplateByName'), method: 'post', data }, option));
  },
  // 3.0.100 资产复盘
  // 导出子任务列表
  exportChildTaskList(data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/exportChildTaskList'), method: 'post', data }, option));
  },
  getAsseCompany(data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/AsseCompany/list'), method: 'post', data }, option));
  },
  // 资产复盘-初盘任务
  getAsseTaskList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/list'), method: 'post', data }, option));
  },
  // 资产复盘-初盘任务
  asseTaskAddCopy (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/addCopy'), method: 'post', data }, option));
  },
  // 资产复盘-任务对比
  asseTaskCompare (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/copyCompare'), method: 'post', data }, option));
  },
  // 资产复盘-任务对比导出
  copyCompareExportExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/copyCompareExportExcel'), method: 'post', data }, option));
  },
  // 大设备能效分页列表
  bigEnergyRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/listPage'), method: 'post', data }, option));
  },
  // 大设备能效列表不分页
  bigEnergyRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/list'), method: 'post', data }, option));
  },
  // 查询单个大能效设备详情
  asseBigEnergydetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/asseBigEnergydetail'), method: 'post', data }, option));
  },
  // 批量修改大设备类型
  saveBigEnergyType (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/saveBigEnergyType'), method: 'post', data }, option));
  },
  // 已选择大能效分类资产列表
  bigAssetInfoList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/assetInfoList'), method: 'post', data }, option));
  },
  // 同步大设备能效
  syncBigEnergyUse (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/syncBigEnergyUse'), method: 'post', data }, option));
  },
  // 大设备-按部门/设备类型统计
  getBigEnergSumEnergyCountType (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/sumEnergyCountType'), method: 'post', data }, option));
  },
  // 大设备-同一设备类型各部门使用率
  getBigEnergSsageRateTypeDept (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/usageRateTypeDept'), method: 'post', data }, option));
  },
  // 大设备-综合统计
  getBigEnergyTypeUseEnergy (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/energyTypeUseEnergy'), method: 'post', data }, option));
  },
  // 大设备-按月、按年显示资产的使用时长、设备使用率
  getBigEnergySssetUseTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/assetUseTime'), method: 'post', data }, option));
  },
  // 2.3.29
  // 能效分析-明细查询导出
  useRateListExport (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/useRateListExport'), method: 'post', data }, option));
  },
  // 能效分析-明细查询列表
  useRateList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/energy/stats/useRateList'), method: 'post', data }, option));
  },
  // 2.3.30
  // 大设备-能效分析-明细查询列表
  getbigEnergyUseRateList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/useRateList'), method: 'post', data }, option));
  },
  // 大设备-能效分析-明细查询列表-导出
  exportbigEnergyUseRate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergy/stats/useRateListExport'), method: 'post', data }, option));
  },
  // 盘点子任务批量导出
  batchChildExportExcel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/batchChildExportExcel'), method: 'post', data }, option));
  },
  // 原始数据查询
  bigEnergyRecordThirdList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/thirdList'), method: 'post', data }, option));
  },
  // 原始数据查询导出
  exportBigEnergyRecordThirdList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bigEnergyRecord/thirdListExport'), method: 'post', data }, option));
  },
  // 2.3.32
  // 
  // 任务回传
  manualPushTask (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/asseTask/manualPushTask'), method: 'post', data }, option));
  },
  // 盘点统计
  countListTaskInfoByDate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/listTaskInfoByDate'), method: 'post', data }, option));
  },
  // 盘点统计导出
  exprotTaskInfoByDate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/taskDetails/exprotTaskInfoByDate'), method: 'post', data }, option));
  },
};

export default asseAPI;
