const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.userHandle.token,
  avatar: state => state.userHandle.avatar,
  name: state => state.userHandle.name
};
export default getters;
