import i18n from '@/i18n/i18n';
import apis from '@/api/index';
export default {
  namespaced: true,
  state: {
    infuDialogSetting: {}, // 输液弹窗设置
    // 院方信息：存储id，医院名称，版本号，图片（logo）
    initTopicInformation: {
      customerName: '广东信尚安物联科技有限公司',
      copyright: '©2018    广东信尚安物联科技有限公司版权所有',
      version: '3.0.0',
      loginLogo: 1,
      appLogo: 1,
      isLoginDisplay: 1,
      isAppDisplay: 1
    },
    topicInformation: {
      customerName: '广东信尚安物联科技有限公司',
      copyright: '©2018    广东信尚安物联科技有限公司版权所有',
      version: '3.0.0',
      loginLogo: null,
      appLogo: null,
      isLoginDisplay: 1,
      isAppDisplay: 1
    },
    courtyardInfo: {},
    isBellGreen: false,
    isOpenMessageList: false,
    // 登录页面标题
    loginTitle: process.env.NODE_ENV !== 'demo' ? i18n.t('config.devLoginTitle') : 'demo',

    monitorTitle: window.SITE_CONFIG.monitorTitle,
    // 标签最多打印数量
    maxPrintCount: window.SITE_CONFIG.maxPrintCount,
    printLabel: [],
    // 建筑id
    buildId: '200402',
    // 图片服务器
    imgUrl: process.env.NODE_ENV !== 'development' ? window.SITE_CONFIG.imgUrl : ``,
    // 蜂鸟地图配置
    fmMapOpts: window.SITE_CONFIG.fmMapOpts,
    // 楼层映射关系
    floorMapping: window.SITE_CONFIG.floorMapping,
    // 楼层控件映射关系
    floorControlping: window.SITE_CONFIG.showFloorControl,
    // 默认初始楼层
    floor: window.SITE_CONFIG.baseFloor,
    // 报警声音的默认值
    alarmSoundFileName: '08.mp3',
    // 推送消息类型
    msgType: {
      EMER: 'EMERGENCY', // 急诊消息
      ASSETS: 'ASSE_ALARM', // 资产消息
      PATIENT: 'PATI_ALARM', // 病患消息
      PATI_CALL:'PATI_CALL',// 呼叫消息
      PATI_SIGN_ALARM: 'PATI_SIGN_ALARM', // 体征监测报警
      PATI_TASK_REMIND: 'PATI_TASK_REMIND', // 定时任务提醒
      EMER_LOW_POWER: 'EMER_LOW_POWER', // 急诊低电量报警
      PATIENT_OUT: 'PATIENT_OUT', // 病患离院
      MOMB_ALARM: 'MOMB_ALARM', // 婴儿安全报警
      PATIENT_DEMOLISH: 'PATIENT_DEMOLISH', // 病患手环拆除报警
      PATIENT_ONE_KEY_ALARM: 'PATIENT_ONE_KEY_ALARM', // 病患手环一键报警
      PATIENT_ONE_KEY_CANCEL_ALARM: 'PATIENT_ONE_KEY_CANCEL_ALARM', // 病患手环取消一键报警
      INFU_ALARM: 'INFU_ALARM', // 输液报警
      INFU_MEMO: 'INFU_MEMO', // 输液报警
      MOMB_ALARM_ADVANCE: 'MOMB_ALARM_ADVANCE', // 标签激活提醒
      HUMITURE_ALARM: 'HUMITURE_ALARM', // 温湿度报警
      HELP_ALARM: 'SECU_ALARM', // 求助报警
      HELP_ALARM_PROCESSED: 'SECU_ALARM_PROCESSED', // 求助报警处理完
      PECT_ALARM: 'PECT_ALARM', // 胸痛报警
      INFU_ALARM_CLOSE: 'INFU_ALARM_CLOSE' // 关闭输液报警
    },
    // 报警信息点击确认后重新获取数据
    retrieveData: null,
    belongTo: {
      assets: 1,
      emer: 2,
      patient: 3,
      mab: 4
    },
    // 报警类型
    alarmType: {
      transboundary: 1,
      retention: 2,
      onekey: 3
    },
    // 报警类型列表
    alarmTypeList: [{
      label: i18n.t('common.all'),
      value: ''
    },
    {
      label: i18n.t('common.transboundaryAlarm'), // 越界报警
      value: '1'
    },
    // {
    //   label: '滞留报警',
    //   value: '2'
    // },
    {
      label: i18n.t('common.oneButtonAlarm'),
      value: '3'
    },
    {
      label: i18n.t('common.dismantlTheAlarm'),
      value: '4'
    },
    {
      label: i18n.t('common.cancelHelp'),
      value: '5'
    }
    ],

    // 本系统最小支持宽度，如果客户端的宽度大于该值，则会页面底部有滚动条
    minWidth: 1180,
    // 客户端实际可见区域宽度
    clientWidth: 1200,
    // 加载状态
    loading: false,
    // 全屏的加载状态
    loadingAll: false,
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 导航条, 布局风格, defalut(默认) / inverse(反向)
    navbarLayoutType: 'default',
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: 'light',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    menuList: [],
    // 格式化后的菜单
    formatMenuList: [],
    // 当前选择的菜单
    selectionMenuList: {},
    menuActiveName: '',
    // mainTabs: [], // 主入口标签页
    // mainTabsActiveName: '',
    position: [],
    // 分页器配置信息
    paginationOptions: {
      layout: 'total, sizes, prev, pager, next, jumper',
      pageSizes: [5, 10, 15, 20, 30, 50, 100],
      pageSize: 10
    },
    // 表格无数据显示内容
    tableEmptyText: '暂时没有内容显示',
    // 表格头部样式
    tableHeadStyleObj: {
      // 'background-color': '#f0f3fa',
      // 'color': '#6a707d',
      // 'font-size': '15px',
      // 'border-bottom': '1px solid #e8e8e8',
      'background-color': '#F2F4F5',
      'color': '#333333',
      'font-size': '14px',
      'line-height': '30px'
    },
    // 与服务器时间戳相差值，当前服务器时间 = new Date().getTime() + timestampDiff;
    timestampDiff: 0,
    yearList: [{
      value: '2016',
      label: '2016年',
      disabled: false
    },
    {
      value: '2017',
      label: '2017年',
      disabled: false
    },
    {
      value: '2018',
      label: '2018年',
      disabled: false
    },
    {
      value: '2019',
      label: '2019年',
      disabled: false
    }
    ],
    monthList: [{
      value: '01',
      label: '1月',
      disabled: false
    },
    {
      value: '02',
      label: '2月',
      disabled: false
    },
    {
      value: '03',
      label: '3月',
      disabled: false
    },
    {
      value: '04',
      label: '4月',
      disabled: false
    },
    {
      value: '05',
      label: '5月',
      disabled: false
    },
    {
      value: '06',
      label: '6月',
      disabled: false
    },
    {
      value: '07',
      label: '7月',
      disabled: false
    },
    {
      value: '08',
      label: '8月',
      disabled: false
    },
    {
      value: '09',
      label: '9月',
      disabled: false
    },
    {
      value: '10',
      label: '10月',
      disabled: false
    },
    {
      value: '11',
      label: '11月',
      disabled: false
    },
    {
      value: '12',
      label: '12月',
      disabled: false
    }
    ],
    // 图表插件主题颜色
    chartColor: ['#3bd190', '#5e97ff', '#67e0e3', '#ffdb5c', '#ff9f7f', '#ff6b6b', '#ad5cff'],
    // 日期控件配置
    datetimepickerOptions: {
      shortcuts: [{
        text: '最近一周',
        onClick (picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近一个月',
        onClick (picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近三个月',
        onClick (picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit('pick', [start, end]);
        }
      }]
    },
    // 时间控件配置
    timePickerOptions: {
      selectableRange: '00:00:00 - 23:59:59'
    },
    // 返回页面地址
    returnBack: null,
    focusGroupID: null, // 聚焦楼层
    // 报警声音的默认值
    // alarmSoundFileName: '08.mp3'
    keepAliveComponents: [], // 页面名字的缓存数组

    // 摄像头服务器
    cameraUrl: window.SITE_CONFIG.cameraUrl,
    cameraWVPUrl: window.SITE_CONFIG.cameraWVPUrl,
    // 安全求助->监控大屏路由要跳到指定链接地址
    screenUrl: '',
    WVPCameraToken: '', //wvp第三方视频平台token
    pageScale: 1 // 缩放比例 
  },
  getters: {
    fmMapOpts: (state) => (key) => {
      if (state.fmMapOpts[key]) {
        return state.fmMapOpts[key];
      } else {
        return state.fmMapOpts;
      }
    }
  },
  mutations: {
    // 输液列表当前设置， 消息通知使用
    setInfuDialogSetting(state, info){
      state.infuDialogSetting = info;
    },
    keepAlive (state, component) {
      // 注：防止重复添加（当然也可以使用Set）
      !state.keepAliveComponents.includes(component) &&
        state.keepAliveComponents.push(component);
    },
    noKeepAlive (state, component) {
      const index = state.keepAliveComponents.indexOf(component);
      index !== -1 &&
        state.keepAliveComponents.splice(index, 1);
    },
    setcourtyardInfo (state, info) {
      state.courtyardInfo = info;
    },
    setClientWidth (state, width) {
      state.clientWidth = width;
    },
    setIsOpenMessageList (state, isOpenMessageList) {
      state.isOpenMessageList = isOpenMessageList;
    },
    setIsBellGreen (state, isBellGreen) {
      state.isBellGreen = isBellGreen;
    },
    setRetrieveData (state, retrieveData) {
      state.retrieveData = retrieveData;
    },
    setLoading (state, flag) {
      state.loading = flag;
    },
    setLoadingAll (state, flag) {
      state.loadingAll = flag;
    },
    updateDocumentClientHeight (state, height) {
      state.documentClientHeight = height;
    },
    updateNavbarLayoutType (state, type) {
      state.navbarLayoutType = type;
    },
    updateSidebarLayoutSkin (state, skin) {
      state.sidebarLayoutSkin = skin;
    },
    updateSidebarFold (state, fold) {
      state.sidebarFold = fold;
    },
    updateMenuList (state, list) {
      state.menuList = list;
    },
    updateFormatMenuList (state, list) {
      state.formatMenuList = list;
    },
    updateSelectionMenuList (state, list) {
      state.selectionMenuList = list;
    },
    updateMenuActiveName (state, name) {
      state.menuActiveName = name;
    },
    setPosition (state, position) {
      state.position = position;
    },
    setPageSize (state, size) {
      state.paginationOptions.pageSize = size;
    },
    setTimestampDiff (state, diff) {
      state.timestampDiff = diff;
    },
    setReturnBack (state, returnBack) {
      state.returnBack = returnBack;
    },
    setFocusGroupID (state, focusGroupID) {
      state.focusGroupID = focusGroupID;
    },
    setAlarmSoundFileName (state, name) {
      state.alarmSoundFileName = name;
    },
    setFmMapOpts (state, options) {
      state.fmMapOpts = options;
    },
    /**
     * --------通过字典管理配置设置不要修改大小写 开始---------*
    */
    setmaxPrintCount (state, options) {
      state.maxPrintCount = options;
    },
    setprintLabel (state, options) {
      state.printLabel = options;
    },
    setimgUrl (state, options) {
      state.imgUrl = options;
    },
    setcameraUrl (state, options) {
      state.cameraUrl = options;
    },
    setcameraWVPUrl (state, options) {
      state.cameraWVPUrl = options;
    },
    setscreenUrl (state, options) {
      state.screenUrl = options;
    },
    setTopicInformation (state, data) {
      state.topicInformation = data;
    },
    /**
     * --------通过字典管理配置设置不要修改大小写 结束---------*
    */
    setWVPCameraToken (state, data) {
      state.WVPCameraToken = data;
    },
    setPageScale (state, data) {
      console.log(state,data)
      state.pageScale = data;
    },
  },
  actions: {
     

    async getWVPCameraToken({commit, state}){
      const res = await apis.help.cameraToken();
      if(res&&res.data){
        commit('setWVPCameraToken', res.data);
      }
    },
    // 获取wvp摄像头url
    async getCameraUrl({dispatch, state}, data) {
      console.log('getCameraUrl', data);
      await dispatch('getWVPCameraToken');
      let obj = { 
        channelId: data.code,
        serial: data.serial,
        code: data.code
      }
        const res = await apis.help.cameraVideo(obj, {
          'Access-Token': state.WVPCameraToken
        });
        console.log('getCameraUrl2', res.data.ws_flv);
        if(!res?.data.ws_flv)return ' '
        return res.data.ws_flv
    },
    async getTopicInformation ({ commit, state }) {
      const res = await apis.sys.getConfigDetail({ cfgCode: 3 });
      if (res.code === 0) {
        if (res.data) {
          const { cfgVal } = res.data;
          // 检测返回到内容类型
          const result = (Object.prototype.toString.call(cfgVal)).replace('object', '').replace('[', '').replace(']', '').replace(' ', '');
          if (result === 'String') {
            commit('setTopicInformation', JSON.parse(cfgVal));
          } else if (result === 'Object') {
            commit('setTopicInformation', cfgVal);
          } else {
            commit('setTopicInformation', state.initTopicInformation);
          }
        } else {
          commit('setTopicInformation', state.initTopicInformation);
        }
      }
    },
    // 每日定时刷新
    setRefreshTime ({ commit, state }) {
      let now = new Date();
      let currentTimestamp = now.getTime();
      // 生成随机刷新时间（第二天5点到5点半之间的某一分钟）
      let randomMinute = Math.floor(Math.random() * 30);
  
      // 计算下一次刷新的时间
      let nextRefreshTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 5, randomMinute);
  
      // 计算距离下一次刷新时间的剩余毫秒数
      let timeUntilRefresh = nextRefreshTime - currentTimestamp;
      console.log('每日定时刷新', timeUntilRefresh/1000/60/60);
      // 设置定时器，在剩余时间达到0时触发刷新页面的函数
      setTimeout(function() {
        location.reload(true);
      }, timeUntilRefresh);
    }
  },
};
