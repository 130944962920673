/*
 * @Author: your name
 * @Date: 2019-08-01 09:37:32
 * @LastEditTime: 2020-11-27 14:32:08
 * @LastEditors: zxx
 * @Description: In User Settings Edit
 * @FilePath: \xinsec-iot-zhaoqing\src\api\index.js
 */

import sys from '@/api/modules/sysAPI.js';
import basi from '@/api/modules/basiAPI.js';
import pati from '@/api/modules/patiAPI.js';
import devi from '@/api/modules/deviAPI.js';
import three from '@/api/modules/threeAPI.js';
import humi from '@/api/modules/humiAPI.js';
import infu from '@/api/modules/infuAPI.js';
import asse from '@/api/modules/asseAPI.js';
import emer from '@/api/modules/emerAPI.js';
import baby from '@/api/modules/babyAPI.js';
import nurse from '@/api/modules/nurseAPI.js';
import platform from '@/api/modules/platformAPI.js';
import help from '@/api/modules/helpAPI.js';
import pectoralgia from '@/api/modules/pectoralgiaAPI.js';
import third from '@/api/modules/thirdAPI.js';
import secu from '@/api/modules/secuAPI.js';
import medical from '@/api/modules/medicalAPI.js';

const API = {
  sys,
  basi,
  pati,
  devi,
  three,
  humi,
  infu,
  asse,
  emer,
  baby,
  nurse,
  platform,
  help,
  pectoralgia,
  third,
  secu,
  medical
};

export default API;
