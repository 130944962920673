/*
 * @Author: zxx
 * @Date: 2020-11-19 13:49:17
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-22 11:34:25
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\help.js
 */
// import apis from '@/api/index.js';
export default {
  namespaced: true,
  state: {
    helpNoticeList: [], // 全部求救消息
    // 搜索类型
    searchOption: [
      {
        value: 0,
        label: '报警点'
      },
      {
        value: 1,
        label: '设备ID'
      }
    ],
    chartColorList: [
      '#5B8FF9',
      '#5AD8A6',
      '#F6BD16',
      '#EA7464',
      '#6DC8EC',
      '#945FB9'
    ],
    ASSE_ALARM: {} // 资产管理 - 报警消息
  },
  mutations: {

    // 全部病患消息列表
    setHelpNoticeList (state, notice) {
      state.helpNoticeList = notice;
    },
    pushHelpNoticeList (state, notice) {
      state.helpNoticeList.unshift(notice);
    },
    // 消息推送
    SET_ASSE_ALARM (state, params) {
      state.ASSE_ALARM = params;
    }
    // SET_SETTINGS (state, params) {
    //   state.settings = params;
    //   console.log(state.settings, '更新个人设置缓存------');
    // }
  },
  actions: {
    // async getSettings ({ commit, state, rootState }) {
    //   try {
    //     let { data } = await apis.help.settings_detail({ userId: rootState.userHandle.userInfo.userId });
    //     commit('SET_SETTINGS', {
    //       defaultMode: data.defaultMode || 1,
    //       id: data.id || null,
    //       floor: data.floor || null,
    //       defaultSiteId: data.defaultSiteId
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // async updateSettings ({ commit, state, rootState }) {
    //   if (JSON.stringify(state.settings) === '{}') {
    //     try {
    //       let { data } = await apis.help.settings_detail({ userId: rootState.userHandle.userInfo.userId });
    //       commit('SET_SETTINGS', {
    //         defaultMode: data.defaultMode || 1,
    //         id: data.id || null,
    //         floor: data.floor || null,
    //         defaultSiteId: data.defaultSiteId
    //       });
    //       console.log(state.settings, 'settings-----');
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // }
  }
};
