import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/humi';
  return basic_url + url;
}

const humiAPI = {
  // 参数设置
  // 根据监测点id获取参数设置详情
  getConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/list'), method: 'post', data }, option));
  },
  // 新增或修改参数设置
  saveConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/add'), method: 'post', data }, option));
  },
  // 分页查询终端
  getHumiMonitorList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/pointMonitorBind/monitorListPage'), method: 'post', data }, option));
  },
  // 不分页查询监测点
  getPointList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/list'), method: 'post', data }, option));
  },
  // 新增温湿度监测点
  savePointAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/add'), method: 'post', data }, option));
  },
  // 编u辑温湿度监测点
  updatePoint (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/update'), method: 'post', data }, option));
  },
  // 删除温湿度监测点
  delPoint (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/delete'), method: 'post', data }, option));
  },
  // 新增温湿度监测点绑定监测终端
  saveBindHumiDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/pointMonitorBind/add'), method: 'post', data }, option));
  },
  // 修改温湿度监测点绑定监测终端
  updateBindHumiDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/pointMonitorBind/update'), method: 'post', data }, option));
  },
  // 解除温湿度监测点绑定监测终端
  delBindHumiDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/pointMonitorBind/delete'), method: 'post', data }, option));
  },
  // 查询监测点详情
  getHumiPointDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/detail'), method: 'post', data }, option));
  },
  // 查询监测点最新一条温湿度基础数据
  getHumiRecordDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/HumiRecord/detail'), method: 'post', data }, option));
  },
  // 按天查询监测点每小时统计
  getHumiHourStatistics (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hourStatistics/list'), method: 'post', data }, option));
  },
  // 报警记录分页查询
  getAlarmRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/listPage'), method: 'post', data }, option));
  },
  // 处理报警记录
  saveAlarmRecordUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/update'), method: 'post', data }, option));
  },
  //  报警记录查询(不分页)
  getAlarmRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/list'), method: 'post', data }, option));
  },
  //  导出温湿度数据
  getPointData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/exportData'), method: 'post', data }, option));
  },
  //  根据监测点id获取参数设置详情（获取为空则会自动获取系统配置）
  getConfigListAuto (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/configList'), method: 'post', data }, option));
  }

};

export default humiAPI;
