/*
 * @Author: zxx
 * @Date: 2020-06-22 18:28:52
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-09 15:40:05
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\baby.js
 */
import i18n from '@/i18n/i18n';
export default {
  namespaced: true,
  state: {
    // 设置婴儿护理默认地图设置
    babyMapOpt: {
      mapScale: 200,
      tiltTo: 90,
      rotateTo: 0,
      xContent: 12943634.785578102,
      yContent: 4871903.977602744,
      viewMode: 'top'
    },
    babyNoticeList: [],
    openDeviceAlarmVis: false,
    // tagActivationList: [],
    regroupAlarm: {
      deviceAlarm: [],
      babyAlarm: []
    }, // 母婴重新组合后的报警信息
    deviceList: [], // 设备列表
    restartDeviceAlarmIntInt: false, // 设备定时器是否重新启动
    // 母亲状态
    MOM_STATUS: {
      CHECKOUT: 0, // 临时签出
      IN: 1, // 在院
      OUT: 2// 离院
    },

    // 婴儿状态
    BABY_STATUS: {
      CHECKOUT: 0, // 临时签出
      IN: 1, // 在院
      OUT: 2// 离院
    },

    // 报警类型枚举
    ALARM_TYPE: {
      CLIPPING: 1, // 标签剪断报警
      MONITOR: 2, // 出口监视器报警
      OFFLINE: 3, // 设备离线报警
      PAIR: 4, // 配对报警
      NO_BACK: 5, // 签出未归报警
      LOW_BATTERY: 6, // 低电量报警
      ACTIVEATE: 7, // 激活报警
      BED_OCCUPY: 8// 床位占用报警
    },
    // 报警类型下拉列表
    ALARM_OPTION: {
      1: {
        VALUE: 1, // 标签剪断报警
        LABEL: i18n.t('baby.LabelCutAlarm')
      },
      2: {
        VALUE: 2, // 出口监视器报警
        LABEL: i18n.t('baby.ExitCameraAlert')
      },
      3: {
        VALUE: 3, // 设备离线报警
        LABEL: i18n.t('baby.offlineAlarm')
      },
      4: {
        VALUE: 4, // 配对报警
        LABEL: i18n.t('baby.MonBabyAlarm')
      },
      5: {
        VALUE: 5, // 签出未归报警
        LABEL: i18n.t('baby.CheckoutTips')
      },
      6: {
        VALUE: 6, // 低电量报警
        LABEL: i18n.t('baby.LowBatAlarm')
      },
      7: {
        VALUE: 7, // 激活报警
        LABEL: i18n.t('baby.activateAlarm')
      },
      8: {
        VALUE: 8, // 床位占用报警
        LABEL: i18n.t('baby.bedOccupancyAlarm')
      }
    },
    // 操作类型
    LOG_TYPE: {
      PRE_BIND_COMPLETE: 0, // 完成预绑婴儿标签绑定
      BIND_COMPLETE: 1, // 完成婴儿标签绑定
      BIND_REPLACE: 2, // 操作更换标签
      UNBUND: 3, // 操作解绑标签
      SING_OUT: 4, // 操作签出
      SING_IN: 5, // 操作签入
      PAIR_SUCCESS: 6, // 配对成功
      PAIR_FAIL: 7, // 配对失败
      RECEIVE_MOM_INFO: 8, // 接收推送母亲入院信息
      RECEIVE_BABY_INFO: 9, // 接收推送婴儿入院信息
      RECEIVE_CHANGE_BED_INFO: 10, // 接收推送换床信息
      RECEIVE_LEAVE_HOSPITAL_INFO: 11, // 接收出院信息
      LEAVE_HOSPITAL_UNBIND: 12, // 操作出院解绑标签
      ACTIVATION_LABEL: 13 // 激活标签
    },
    LOG_TYPE_OBJ: {
      1: {
        type: 'PRE_BIND_COMPLETE', // 完成预绑婴儿标签绑定
        name: i18n.t('baby.CompletePreBind')
      },
      2: {
        type: 'BIND_COMPLETE', // 完成婴儿标签绑定
        name: i18n.t('baby.CompleteLabelBind')
      },
      3: {
        type: 'UNBUND', // 操作更换标签
        name: i18n.t('baby.OperationChange')
      },
      4: {
        type: 'SING_OUT', // 操作签出
        name: i18n.t('baby.CheckOutOper')
      },
      5: {
        type: 'SING_IN', // 操作签入
        name: i18n.t('baby.OperatingCheckin')
      },
      6: {
        type: 'PAIR_SUCCESS', // 配对成功
        name: i18n.t('baby.PairingSUCC')
      },
      7: {
        type: 'PAIR_FAIL', // 配对失败
        name: i18n.t('baby.MatchFailure')
      },
      8: {
        type: 'RECEIVE_MOM_INFO', // 接收推送母亲入院信息
        name: i18n.t('baby.ReceivePushMom')
      },
      9: {
        type: 'RECEIVE_BABY_INFO', // 接收推送婴儿入院信息
        name: i18n.t('baby.ReceivePushBaby')
      },
      10: {
        type: 'RECEIVE_CHANGE_BED_INFO', // 接收推送换床信息
        name: i18n.t('baby.ReceiveBedChange')
      },
      11: {
        type: 'RECEIVE_LEAVE_HOSPITAL_INFO', // 接收出院信息
        name: i18n.t('baby.ReceiveDischarge')
      },
      12: {
        type: 'LEAVE_HOSPITAL_UNBIND',
        name: i18n.t('baby.OutHospitalUnbundleLabel')// 操作出院解绑标签
      },
      13: {
        type: 'ACTIVATION_LABEL',
        name: '激活标签' // 激活标签
      }
    },
    // 设备列表配置值
    // 设备类型：1=信号中继器，2=信号接收器，3=出口监测器
    DEVICE_OPTION: {
      1: {
        icon: 'chukou',
        text: i18n.t('equip.SignalRepeater')
      },
      2: {
        icon: 'jiehsou',
        text: i18n.t('equip.SignalReceiver')
      },
      3: {
        icon: 'zhongji',
        text: i18n.t('equip.ExitCamera')
      }

    }
  },

  mutations: {
    setBabyNoticeList (state, noticeList) {
      state.babyNoticeList = noticeList;
    },
    pushBabyNoticeList (state, notice) {
      state.babyNoticeList.unshift(notice);
    },
    setDeviceAlarmVis (state, val) {
      state.openDeviceAlarmVis = val;
    },
    // setTagActivationList(state, val) {
    //   state.tagActivationList = val;
    // },
    repalceNotice (state, val) {
      state.babyNoticeList.splice(val.num, 1, val.val);
    },
    spliceNotice (state, val) {
      state.babyNoticeList.splice(val, 1);
    },
    setRegroupAlarm (state, val) {
      state.regroupAlarm = val;
    },
    setDeviceList (state, val) {
      state.deviceList = val;
    },
    setRestartDeviceAlarmIntInt (state, val) {
      state.restartDeviceAlarmIntInt = val;
    }
  }
};
