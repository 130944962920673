export default {
  namespaced: true,
  state: {
    // 报警消息列表
    noticeList: [],
    isGray: false, // 病患管理急救管理表状态,对此页面的全局背景样式进行特殊处理
    warningRecoprtObj: {
      warningInfo: null, // 报警弹窗时处理的单个报警信息--针对病患消息
      warningRecoprt: null // 报警记录数组
    },
    globalSettingUpdateStatus: { // 用于监听全局设置是否保存，控制病患管理是否需要重新init
      areaId: '', // 病区id
      isSave: false // 是否保存了
    },
    globalSetting:{} // 全局设置
  },
  getters: {
    globalSettingUpdateStatus: (state) => {
      return state.globalSettingUpdateStatus;
    },
    getGlobalSetting:(state) => {
      return state.globalSetting;
    }
  },
  mutations: {
    setNoticeList (state, noticeList) {
      state.noticeList = noticeList;
    },
    pushNoticeList (state, notice) {
      state.noticeList.unshift(notice);
    },
    setIsGray (state, isGray) {
      state.isGray = isGray;
    },
    // 保存报警弹窗时处理的单个报警信息
    savewarningInfo (state, info) {
      // 判断是否已经保存了一次，如果有值，那么先赋值为null再保存
      if (state.warningRecoprtObj.warningInfo) {
        state.warningRecoprtObj.warningInfo = null;
        state.warningRecoprtObj.warningInfo = info;
      } else {
        state.warningRecoprtObj.warningInfo = info;
      }
    },
    // 保存病患历史数组
    savewarningRecoprt (state, info) {
      console.log(info);
      // Vue.set(state.warningRecoprtObj, 'warningRecoprt', info);
      state.warningRecoprtObj.warningRecoprt = info;
    },
    // 取消报警弹窗时处理的单个报警信息，在点击取消了弹出层或者是接口返回失败不予保存
    NosavewarningInfo (state) {
      state.warningRecoprtObj.warningInfo = null;
      // state.warningRecoprtObj.warningRecoprt = null;
    },
    // 更新全局设置保存了状态
    setGlobalSettingUpdateStatus (state, info) {
      state.globalSettingUpdateStatus.areaId = info.areaId;
      state.globalSettingUpdateStatus.isSave = info.isSave;
    },
    // 更新全局设置保存了状态
    setGlobalSetting (state, info) {
      console.log(info,'---globalSetting')
      state.globalSetting = info;
    }
  }

};

