import Vue from 'vue';
Vue.prototype.$common = {};

// 判断是否为空
Vue.prototype.$common.isEmpty = value => {
  // eslint-disable-next-line
  return value === undefined || value === null || typeof(value) === 'object' && Object.keys(value).length === 0 || typeof value === 'string' && value.trim().length === 0;
};

export function conversionUrl (url) {
  if (url.indexOf('/') === 0) {
    url = url.slice(1);
  }
  //
  // 资产管理模块
  // 资产管理 - 管理资产 - 资产入库跳转静态页面
  if (url === 'assets-all-add-update') {
    return () => import('@/views/assets/all/all-add-update.vue');
  // 资产管理 - 地图模式
  } else if (url === 'assets-all-map') {
    return () => import('@/views/assets/all/all-map.vue');
  // 资产管理 - 查看
  } else if (url === 'assets-check') {
    return () => import('@/views/assets/all/assets-check.vue');
  // 资产管理 - 新建盘点任务
  } else if (url === 'check-add-update') {
    return () => import('@/views/assets/check/check-add-update.vue');
  // 资产管理 - 使用资产
  } else if (url === 'check-show-select-asset') {
    return () => import('@/views/assets/check/check-show-select-asset.vue');
  // 资产管理 - 标签打印
  } else if (url === 'moduleStore') {
    return () => import('@/views/assets/moduleStore/index.vue');
  //
  // 卒中管理模块
  // 卒中管理 - 编辑历史病患
  } else if (url === 'emer-history-update') {
    return () => import('@/views/emer/history/history-update.vue');
  // 卒中管理 - 编辑历史病患详情
  } else if (url === 'emer-history-view') {
    return () => import('@/views/emer/history/history-show-view.vue');
  // 卒中管理 - 历史病患 - 时间管理表
  } else if (url === 'emer-history-time-table') {
    return () => import('@/views/emer/history/history-time-table.vue');
  // 卒中管理 - 历史病患
  } else if (url === 'emer-history') {
    return () => import('@/views/emer/history/index.vue');
  // 卒中管理 - 编辑病患
  } else if (url === 'realtime-add-update') {
    return () => import('@/views/emer/realtime/realtime-add-update.vue');
  // 卒中管理 - 时间管理表
  } else if (url === 'time-table') {
    return () => import('@/views/emer/disease/time-table.vue');
  // 智慧看护
  // 智慧看护 - 病患管理 - 定位看护 - 记录
  } else if (url === 'PatientRecord') {
    return () => import('@/views/care/patient/patient-record.vue');
  // 智慧看护 - 在床看护 - 睡眠报告
  } else if (url === 'sleep-report') {
    return () => import('@/views/care/patient/sleep-report.vue');
  // 智慧看护 - 在床看护 - 历史记录
  } else if (url === 'patient-history') {
    return () => import('@/views/care/patient//patient-history.vue');
  // 智慧看护 - 体征监测 - 历史记录
  } else if (url === 'SignHistory') {
    return () => import('@/views/care/settings/sign-history.vue');
  // 婴儿安全
  // 婴儿安全 - 母婴管理 - 签入签出
  } else if (url === 'baby-history-view') {
    return () => import('@/views/baby/MomBabyManage/MomBabyManage-history.vue');
  // 婴儿安全 - 母婴管理 - 地图模式
  } else if (url === 'baby-map') {
    return () => import('@/views/baby/babyDevice/index.vue');
  // 设备管理
  // 设备管理 - 围栏管理 - 新增/编辑围栏
  } else if (url === 'rail-add-update') {
    return () => import('@/views/equip/rail/rail-add-update.vue');
  // 设备管理 - 围栏管理 - 围栏模板
  } else if (url === 'sosRailTpl-add-update') {
    return () => import('@/views/equip/sosRailTpl/sosRailTpl-add-update.vue');
  // 设备管理 - LoRa转换器管理 - 上报列表
  } else if (url === 'LoRaUpDataList') {
    return () => import('@/views/equip/LoRaConverter/LoRaUpDataList.vue');
  // 智能输液
  // 智能输液 - 输液管理 - 详情
  } else if (url === 'transfusion/manage-details') {
    // return () => import('@/views/transfusion/manage/details-data-view.vue');
    return () => import('@/views/transfusion/manage/transfusionDetail.vue');
  // 护理白板
  // 护理白板 - 白板管理 - 新增/编辑
  } else if (url === 'nurse-board-add-update') {
    return () => import('@/views/nurse/list/add-update.vue');
  // 护理白板 - 白板管理 - 手工录入
  } else if (url === 'Manual-entry1') {
    return () => import('@/views/nurse/screen/index.vue');
  // 胸痛时间管理
  // 胸痛时间管理 - 实时病患 - 时间管理表
  } else if (url === 'pectoralgia-disease-management-table') {
    return () => import('@/views/pectoralgia/disease/management-table.vue');
  } else {
    return () => import(`@/views/${url}/index.vue`);
  }
}

// 获取用户加密后名称
/**
 * 
 * @param { 需要加密名称 } name 
 * @param { 需要加密的应用ID } appId 
 * @param { 加密规则 } encryption 
 * @returns 
 */
export const getUserEncryptionName = (name, appId, encryption) => {
  // console.log(encryption, appId, 'encryption');
  const __encryption = encryption && encryption.appId ? encryption.appId : []
  let type = 0;
  const check = __encryption.find(i => i === +appId);
  if (check) {
    type = encryption.type;
  }
  if (name.length > 1) {
    // 保留第一个字
    if (type === 0) {
      let char = '';
      for (let i = 0, len = name.length - 1; i < len; i++) {
        char += '*';
      }
      return name.substr(0, 1) + char;
    }
    // 保留最后一个字
    if (type === 1) {
      let char = '';
      for (let i = 0, len = name.length - 1; i < len; i++) {
        char += '*';
      }
      return char + name.substr(-1, 1);
    }
    // 保留第一和最后一个字3种
    if (type === 2) {
      let char = '';
      for (let i = 0, len = name.length - 2; i < len; i++) {
        char += '*';
      }
      if (name.length === 2) {
        return name.substr(0, 1) + '*';
      } else {
        return name.substr(0, 1) + char + name.substr(-1, 1);
      }
    }
  } else {
    return '*'
  }
}
