var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pages-view" },
    [
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.currentPage,
          "page-sizes": _vm.$store.state.common.paginationOptions.pageSizes,
          "page-size": _vm.$store.state.common.paginationOptions.pageSize,
          layout: _vm.$store.state.common.paginationOptions.layout,
          total: _vm.total
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }