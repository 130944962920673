/*
 * @Author: zxx
 * @Date: 2020-10-27 13:47:26
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-17 17:23:19
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\transfusion.js
 */
import i18n from '@/i18n/i18n';
export default {
  namespaced: true,
  state: {
    transfusionNoticeList: [],
    infuAlarm: {}, // 消息推送 - 当前
    alarmType: {
      101: i18n.t('transfusion.infusionInsuf'), // 余量不足
      201: i18n.t('transfusion.aneroidAlarm'), // 无液报警
      301: i18n.t('transfusion.callHelp'), // 呼叫求助
      401: i18n.t('transfusion.memorandum'), // 备忘录
      // 501: i18n.t('transfusion.lowBattery'), // 低电量
      502: i18n.t('transfusion.weightAbnormal'), // 重量异常
      // 503: i18n.t('transfusion.deviceOffline'), // 设备离线
      504: i18n.t('transfusion.infusionJam'), // 输液堵塞
      505: i18n.t('transfusion.lightOff'), // 空载关机
      506: i18n.t('transfusion.speedToFast'), // 滴速过快
      507: i18n.t('transfusion.speedToSlow'), // 滴速过慢
      509: i18n.t('transfusion.automaticEnd'), // 自动结束输液
      510: '空载报警', // 空载报警
      511: i18n.t('transfusion.speedAnomalies'), // 滴速异常
      512:'重量异常报警'
    }, // 报警类型
    alarmType1: {
      101: i18n.t('transfusion.infusionInsuf'), // 余量不足
      201: i18n.t('transfusion.aneroidAlarm'), // 无液报警
      301: i18n.t('transfusion.callHelp'), // 呼叫求助
      401: i18n.t('transfusion.memorandum'), // 备忘录
      // 501: i18n.t('transfusion.lowBattery'), // 低电量
      502: i18n.t('transfusion.weightAbnormal'), // 重量异常
      // 503: i18n.t('transfusion.deviceOffline'), // 设备离线
      504: i18n.t('transfusion.infusionJam'), // 输液堵塞
      // 505: i18n.t('transfusion.lightOff'), // 空载关机
      506: i18n.t('transfusion.speedToFast'), // 滴速过快
      507: i18n.t('transfusion.speedToSlow'), // 滴速过慢
      509: i18n.t('transfusion.automaticEnd'), // 自动结束输液
      510: '空载报警', // 空载报警
      512:'重量异常报警'
      // 511: i18n.t('transfusion.speedAnomalies') // 滴速异常
    }, // 报警类型
    // 输液参数提示对象
    //   parameterDetailObj: {
    //     1: {// 输液参数
    //       name: 'speedInterv',
    //       value: 1
    //     },
    //     2: {// 滴速范围、
    //       name: 'coefficient',
    //       value: 2
    //     },
    //     3: {// 提醒方式
    //       name: 'remindType',
    //       value: 3
    //     }
    //   }
    // },
    parameterDetailObj: {
      1: 'speedInterv', // 输液参数
      2: 'coefficient', // 滴速范围、
      3: 'remindType' // 提醒方式

    },
    transfusionVersion: '', // 输液版本号
    descInitObj: {
      deptInitDesc: '科室', // 科室默认文案
      areaInitDesc: '病区', // 病区室默认文案
      bedSeatInitDesc: '床位', // 床位默认文案
      bedNoInitDesc: '床号', // 床号默认文案
      bedInitDesc: '床', // 床默认文案
      hospitalNoInitDesc: '住院号' // 住院号默认文案
    },
    descSetObj: {
      deptDesc: '', // 科室文案
      areaDesc: '', // 病区文案
      bedSeatDesc: '', // 床位文案
      bedNoDesc: '', // 床号文案
      bedDesc: '', // 床文案
      hospitalNoDesc: '' // 住院号文案
    }
  },
  mutations: {
    SET_DESC (state, descSetObj) {
      state.descSetObj = descSetObj;
    },
    updateAlarmType (state, alarmType) {
      state.alarmType = alarmType;
    },
    setTransfusionNoticeList (state, noticeList) {
      state.transfusionNoticeList = noticeList;
    },
    pushTransfusionNoticeList (state, notice) {
      state.transfusionNoticeList.push(notice);
    },
    // 设置报警消息
    SET_INFU_ALARM (state, params) {
      state.infuAlarm = params;
    },
    // 设置输液版本号
    setTransfusionVersion (state, params) {
      state.transfusionVersion = params;
    }
  }
};
