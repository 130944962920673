import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/emer';
  return basic_url + url;
}

const emerAPI = {
  // 基础配置
  // 查询配置  0	病患名称  1	阈值 2	时间段 3	科室 4	病区  5	角色 6	自动创建
  basicSet_querySet (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/list'),
      method: 'post',
      data
    }, option));
  },
  // 添加
  basicSet_add (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/add'),
      method: 'post',
      data
    }, option));
  },
  // 修改
  basicSet_update (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/update'),
      method: 'post',
      data
    }, option));
  },
  // 删除
  basicSet_delete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/config/delete'),
      method: 'post',
      data
    }, option));
  },
  // 围栏设置
  // 列表查询
  enclosureSet_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/node/list'),
      method: 'post',
      data
    }, option));
  },
  // 围栏设置 保存
  enclosureSet_save (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/node/save'),
      method: 'post',
      data
    }, option));
  },
  // ==========================
  // ==========================
  // 实时病患
  // 新增或者编辑
  realTimeDisease_addOrEdit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/') + option,
      method: 'post',
      data
    }, option));
  },
  // 修改
  realTimeDisease_edit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/update'),
      method: 'post',
      data
    }, option));
  },
  // 查询列表
  realTimeDisease_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/list'),
      method: 'post',
      data
    }, option));
  },
  // 结束诊疗
  realTimeDisease_end (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/end'),
      method: 'post',
      data
    }, option));
  },
  // 同步数据
  realTimeDisease_asyncMessage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/thirPatient/detail'),
      method: 'post',
      data
    }, option));
  },
  // 病患详情
  // 用于pad页面查询病患地图位置
  realTimeDisease_patientDetails (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patient/detail'),
      method: 'post',
      data
    }, option));
  },
  // ==========================
  // ==========================
  // 历史病患 病患管理
  // 删除历史病患
  historyDisease_delete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/delete'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询
  historyDisease_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 导出病患质控表
  historyDisease_emerPROXY_patient (data = {}, option = {}) {
    return PROXY + Basic_Url('/excel/outputPatientForm');
  },
  // 导出医生质控表
  historyDisease_emerPROXY_doctor (data = {}, option = {}) {
    return PROXY + Basic_Url('/excel/outputDoctorsForm');
  },
  // ============================
  // ============================
  // 急救管理表
  // 查询急救管理表
  ManagementTable_detail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/detail'),
      method: 'post',
      data
    }, option));
  },
  // 提交或保存急救管理表
  ManagementTable_submit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/updateForm'),
      method: 'post',
      data
    }, option));
  },
  // 修改会诊信息
  ManagementTable_updateHzxx (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/updateHzxx'),
      method: 'post',
      data
    }, option));
  },
  // 修改患者转归
  ManagementTable_updateHzzg (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/updateHzzg'),
      method: 'post',
      data
    }, option));
  },
  // 修改急救信息
  ManagementTable_updateJjxx (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/updateJjxx'),
      method: 'post',
      data
    }, option));
  },
  // 修改溶栓介入
  ManagementTable_updateRsjr (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/updateRsjr'),
      method: 'post',
      data
    }, option));
  },

  // ===============================
  // ===============================
  // 汇总统计
  // 卒中绿通数分布（月）
  Count_apoplexy_month (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/mohthList'),
      method: 'post',
      data
    }, option));
  },
  // 卒中绿通、溶栓数分布（年）
  Count_apoplexyThrombolysis_year (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/yearList'),
      method: 'post',
      data
    }, option));
  },
  // DTS平均时间
  DTS_averageTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/dtsYearList'),
      method: 'post',
      data
    }, option));
  },
  // DTC平均时间
  DTC_averageTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/dtcYearList'),
      method: 'post',
      data
    }, option));
  },
  // DNT平均时间
  DNT_averageTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/dntYearList'),
      method: 'post',
      data
    }, option));
  },
  DPT_averageTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/statisticsDay/dptYearList'),
      method: 'post',
      data
    }, option));
  },
  // 卒中绿通性别年龄段占比
  Count_sexCount (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/sexAgeStatistics'),
      method: 'post',
      data
    }, option));
  },
  // 会诊医生质控分析统计
  Count_doctorCount (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/doctorStatistics'),
      method: 'post',
      data
    }, option));
  },
  // 计算环节对比及统计数据
  Count_Statistics (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/patientHis/statistics'),
      method: 'post',
      data
    }, option));
  },
  // 导出环节对比名单列表
  Count_exportNameList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/excel/outputLinkComparedList'),
      method: 'post',
      data
    }, option));
  },
  // 病患提交情况
  Count_Submit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/manageForm/getManagementCount'),
      method: 'post',
      data
    }, option));
  },
  // ============================
  // ============================
  // 名单管理
  // 人员详情
  ListManagement_personDetails (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/detail'),
      method: 'post',
      data
    }, option));
  },
  // 新增或编辑
  ListManagement_addOredit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/') + option,
      method: 'post',
      data
    }, option));
  },
  // 人员列表（分页）
  ListManagement_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 人员列表（不分页）
  ListManagement_nolistPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/list'),
      method: 'post',
      data
    }, option));
  },
  // 批量删除或删除多个
  ListManagement_delete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/delete'),
      method: 'post',
      data
    }, option));
  },
  // 判断人员是否有未发布排班
  ListManagement_judge (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/selectPersonSchedule'),
      method: 'post',
      data
    }, option));
  },
  // ==========================
  // ==========================
  // 定位查询
  // 实时定位查询
  LocationQuery_realTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/location/current'),
      method: 'post',
      data
    }, option));
  },
  // 历史轨迹查询
  LocationQuery_historyTime (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/location/history'),
      method: 'post',
      data
    }, option));
  },

  // ============================
  // ============================
  // 排班管理
  // 查询当前排班人员（按月）
  WorkforceMmanagement_month (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/schedule/detail'),
      method: 'post',
      data
    }, option));
  },
  // 详情（按天）
  WorkforceMmanagement_day (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/scheduleDetail/list'),
      method: 'post',
      data
    }, option));
  },
  // 排班人员列表
  WorkforceMmanagement_personList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/person/listAll'),
      method: 'post',
      data
    }, option));
  },
  // 排班 - 保存（按日期）
  WorkforceMmanagement_saveDate (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/schedule/add'),
      method: 'post',
      data
    }, option));
  },
  // 排班 - 保存（按人员）
  WorkforceMmanagement_savePerson (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/scheduleDetail/add'),
      method: 'post',
      data
    }, option));
  },
  // 排班 - 删除（按人员）
  WorkforceMmanagement_deletePerson (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/scheduleDetail/delete'),
      method: 'post',
      data
    }, option));
  },
  // 排班 - 发布
  WorkforceMmanagement_release (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/schedule/publish'),
      method: 'post',
      data
    }, option));
  },
  // 排班 - 撤回
  WorkforceMmanagement_withdraw (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/schedule/cancel'),
      method: 'post',
      data
    }, option));
  },
  // 导出排班表
  WorkforceMmanagement_emerPROXY () {
    return PROXY + Basic_Url('/excel/exportSchedule');
  },

  // ============================
  // ============================
  // 操作记录
  // 分页查询操作记录
  OperationRecord_handle (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/log/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询急诊操作记录
  OperationRecord_emerHandle (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/log/list'),
      method: 'post',
      data
    }, option));
  },
  // ===================================
  // 卒中标签分页查询    暂为北京宣武医院提供的功能
  emerLabel_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/showLabelPage'),
      method: 'post',
      data
    }, option));
  },
  // 查询时间点列表
  getTimePointList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timePoint/list'),
      method: 'post',
      data
    }, option));
  },
  emerLabel_equipType (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/showTypeList'),
      method: 'post',
      data
    }, option));
  },
  // 更新时间采集设置
  updateTimePointList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timePoint/update'),
      method: 'post',
      data
    }, option));
  },
  // 时间采集超时提醒阈值列表
  getTimeThresholdList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/timeThreshold/list'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书新增
  addConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/add'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书修改
  updateConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/update'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书列表
  getConsentList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/list'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书详情
  getConsentDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/detail'),
      method: 'post',
      data
    }, option));
  },
  // 知情同意书删除
  delConsent (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/delete'),
      method: 'post',
      data
    }, option));
  },
  // 关系列表
  getRelationshipList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/list'),
      method: 'post',
      data
    }, option));
  },

  // 关系详情
  getRelationshipDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/detail'),
      method: 'post',
      data
    }, option));
  },
  // 关系新增
  addRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/add'),
      method: 'post',
      data
    }, option));
  },
  // 关系修改
  updateRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/update'),
      method: 'post',
      data
    }, option));
  },
  // 关系删除
  deleteRelationship (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/relationship/delete'),
      method: 'post',
      data
    }, option));
  },
  // 分页查询标签
  getLabelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 列表查询未使用的标签
  getUnuseLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/list'),
      method: 'post',
      data
    }, option));
  },
  // 标签详情
  getLabelDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/detail'),
      method: 'post',
      data
    }, option));
  },
  // 标签新增
  addLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/add'),
      method: 'post',
      data
    }, option));
  },
  // 标签修改
  updateLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/update'),
      method: 'post',
      data
    }, option));
  },
  // 标签删除
  deleLabel (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/label/delete'),
      method: 'post',
      data
    }, option));
  },
  // 病患对应知情同意书查询接口
  getConsentPatiList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/patient/list'),
      method: 'post',
      data
    }, option));
  },
  // 病患对应知情同意书详情
  getConsentPatiDetail (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/consent/patient/detail'),
      method: 'post',
      data
    }, option));
  }

};

export {
  PROXY
};
export default emerAPI;
