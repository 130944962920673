import i18n from '@/i18n/i18n';
export default {
  namespaced: true,
  state: {
    belongTo: [
      {
        label: '资产管理',
        value: 1
      }, {
        label: '急诊管理',
        value: 2
      }, {
        label: '病患管理',
        value: 3
      }, {
        label: '婴儿安全',
        value: 4
      }
      // {
      //   label: '母婴管理',
      //   value: 4
      // }
    ],
    techType: [
      {
        label: '蓝牙',
        value: 1
      }, {
        label: '有源RFID',
        value: 2
      }, {
        label: '无源RFID',
        value: 3
      }, {
        label: '能效标签',
        value: 4
      }
    ],
    // 标签类型obj
    techTypeObj: {
      1: {
        label: '蓝牙',
        value: 1
      },
      2: {
        label: '有源RFID',
        value: 2
      },
      3: {
        label: '无源RFID',
        value: 3
      },
      4: {
        label: '能效标签',
        value: 4
      }
    },
    // 有源rfid类型
    rfidType: {
      // 信号中继器
      1: {
        label: i18n.t('equip.SignalRepeater'),
        value: 1
      },
      // 信号接收器
      2: {
        label: i18n.t('equip.SignalReceiver'),
        value: 2
      },
      // 出口监视器
      3: {
        label: i18n.t('equip.ExitCamera'),
        value: 3
      }
    },
    /* bindApp: [
      {
        label: '病患看护',
        value: '1'
      }, {
        label: '资产管理',
        value: '2'
      }, {
        label: '母婴管理',
        value: '3'
      }
    ], */
    // 电量显示
    battery: [
      {
        value: 0,
        label: i18n.t('equip.insufficient')
      }, {
        value: 1,
        label: '25% ~ 50%'
      }, {
        value: 2,
        label: '50% ~ 75%'
      }, {
        value: 3,
        label: i18n.t('equip.sufficient')
      }
    ],

    // LoRa转换器 - 管理 - 基本信息
    LoRaInfo: {},
    // LoRa转换器 - 批量的eui列表
    LoRaMoteEuiList: null
  },
  mutations: {
    SET_LORAINFO (state, LoRaInfo) {
      state.LoRaInfo = LoRaInfo;
    },
    set_LoRaMoteEuiList (state, LoRaMoteEuiList) {
      state.LoRaMoteEuiList = LoRaMoteEuiList;
    }
  }
};
