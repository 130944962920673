import moment from 'moment';
const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const month = day * 30;
const year = month * 12;

/**
 * 格式时间戳
 * @param {Timestamp} timer 时间戳
 * @param {String} format 格式
 */
export function formatDate (timestamp, format) {
  if (typeof timestamp === 'string') {
    return timestamp;
  } else if (timestamp) {
    if (format && format.toLowerCase().indexOf('yyyy-mm-dd') !== -1) {
      return moment(timestamp).format(format.replace('dd', 'DD').replace('hh', 'HH'));
    } else {
      return moment(timestamp).format(format);
    }
  } else {
    return '';
  }
  // if (!timestamp) { return ''; }
  // if (typeof timestamp === 'string') {
  //   let time = new Date(timestamp);
  //   let o = {
  //     'M+': time.getMonth() + 1, // 月份
  //     'd+': time.getDate(), // 日
  //     'h+': time.getHours(), // 小时
  //     'm+': time.getMinutes(), // 分
  //     's+': time.getSeconds(), // 秒
  //     'q+': Math.floor((time.getMonth() + 3) / 3), // / /季度
  //     'S': time.getMilliseconds() // 毫秒
  //   };
  //   format = format === null ? 'yyyy-MM-dd' : format;
  //   if (/(y+)/.test(format)) {
  //     format = format.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
  //     for (let k in o) {
  //       if (new RegExp('(' + k + ')').test(format)) {
  //         format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
  //       }
  //     }
  //   }
  //   return format;
  // } else if (typeof timestamp === 'number') {
  //   return moment(timestamp).format(format);
  // }
}
/**
 * 判断当前时间是否是今天
 * @param {date} date 时间 ms/string(192934923838/'2021-06-10 12:00')
*/
export function isToday (date) {
  let d = new Date(date.toString().replace(/-/g, '/'));
  let todaysDate = new Date();
  return d.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
}

/**
 * 将时间戳格式化为 ‘今天/昨天/前天/3天前/1个月前/1年前 12:10:00’
 * @param {Number} timestamp 需要格式化的时间戳
 * @param {Number} curTimestamp 服务器当前时间戳
 */
export function timestampFormatToHms (timestamp, curTimestamp, needSecond = true) {
  if (Object.prototype.toString.call(timestamp).slice(8, -1) === 'Undefined') {
    return '--';
  }
  timestamp = new Date(timestamp) && new Date(timestamp).getTime();
  let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数
  let r = 0;
  let tmDate = new Date(timestamp); // 参数时间戳转换成的日期对象
  // let y = tmDate.getFullYear();
  // let M = tmDate.getMonth() + 1;
  let d = tmDate.getDate();
  let H = tmDate.getHours();
  let m = tmDate.getMinutes();
  let s = tmDate.getSeconds();
  let daytext = '';
  if (timestampDiff > year) { // 大于1年
    r = Math.floor(timestampDiff / year);
    daytext = r + '年前';
  } else if (timestampDiff > month) { // 大于1个月(按30天算)
    r = Math.floor(timestampDiff / month);
    daytext = r + '个月前';
  } else if (timestampDiff > day) { // 大于1天
    r = Math.floor(timestampDiff / day);
    if (r === 1) {
      daytext = '昨天';
    } else if (r === 2) {
      daytext = '前天';
    } else {
      daytext = r + '天前';
    }
  } else { // 小于一天
    let curdate = new Date(curTimestamp).getDate();
    if (d === curdate) {
      daytext = '今天';
    } else if (d === curdate - 1) {
      daytext = '昨天';
    }
  }
  console.log(daytext + ' ' + zeroize(H) + ':' + zeroize(m) + (needSecond ? (':' + zeroize(s)) : ''));
  return daytext + ' ' + zeroize(H) + ':' + zeroize(m) + (needSecond ? (':' + zeroize(s)) : '');
}

// 秒数转换分秒
export function formatSeconds1 (value = -1) {
  // console.error('我每隔一秒触发了', value);
  if (value < 0 || !value) {
    return '--' + window.vm.$t('common.min') + '--' + window.vm.$t('common.second');
  }
  let theTime = parseInt(value); // 秒
  let middle = 0; // 分
  let result = '';
  if (theTime > 600) {
    middle = Math.round((theTime / 60));
    result = middle + window.vm.$t('common.min');
  } else if (theTime > 60) {
    middle = parseInt((theTime / 60));
    theTime = parseInt(theTime % 60);
    result = middle + window.vm.$t('common.min') + theTime + window.vm.$t('common.second');
  } else if (theTime > 0) {
    theTime = parseInt(theTime % 60);
    result = theTime + window.vm.$t('common.second');
  }
  return result;
}

/**
 * 将时间戳格式化为 ‘刚刚/1分钟前/1小时前/1天前/1个月前/1年前’
 * @param {Number} timestamp 需要格式化的时间戳
 * @param {Number} curTimestamp 服务器当前时间戳
 */
export function timestampFormatToAgo (timestamp, curTimestamp) {
  let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数
  let r = 0;
  if (timestampDiff > year) { // 大于1年
    r = timestampDiff / year;
    return r + '年前';
  } else if (timestampDiff > month) { // 大于1个月(按30天算)
    r = timestampDiff / month;
    return r + '个月前';
  } else if (timestampDiff > day) { // 大于1天
    r = timestampDiff / day;
    return r + '天前';
  } else if (timestampDiff > hour) { // 小于1小时
    r = timestampDiff / hour;
    return r + '小时前';
  } else if (timestampDiff > minute) { // 大于1分钟
    r = timestampDiff / minute;
    return r + '分钟前';
  }
  return '刚刚';
}

export function getTimeLong (timestamp) {
  if (timestamp <= 0) {
    return '0时0分0秒';
  }
  let timelong = '';
  let theDay = Math.floor(timestamp / day);
  let newDay = timestamp % day;
  let theHour = Math.floor(newDay / hour);
  let newHour = newDay % hour;
  let theMinite = Math.floor(newHour / minute);
  let newMinute = newHour % minute;
  let theSeconds = Math.floor(newMinute / 1000);
  if (theDay > 0) {
    timelong += theDay + '天';
  }
  if (theHour > 0) {
    timelong += theHour + '小时';
  }
  if (theMinite > 0) {
    timelong += theMinite + '分';
  }
  if (theSeconds > 0) {
    timelong += theSeconds + '秒';
  }
  return timelong;
}

/**
 * 补位，将一位数字前面补0
 * @param {Number} num 需要补位的数值
 */
export function zeroize (num) {
  return (String(num).length === 1 ? '0' : '') + num;
}

/**
 * 根据出生日期时间戳计算年龄
 * @param {Number} birthDayTime 出生日期时间戳
 */
export function getAge (birthDayTime) {
  // 当前时间 毫秒
  let nowTime = new Date().getTime();
  // 一年毫秒数(365 * 86400000 = 31536000000)
  return Math.ceil((nowTime - birthDayTime) / 31536000000);
}

/**
 * 格式化时间
 *
 * @param  {time} 时间
 * @param  {cFormat} 格式
 * @return {String} 字符串
 *
 * @example formatTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
 */
export function formatTime (time, cFormat) {
  if (arguments.length === 0) return null;
  if ((time + '').length === 10) {
    time = +time * 1000;
  }

  let format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    date = new Date(time);
  }

  let formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  let time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
// 秒数转换分秒
export function formatSeconds (value = -1) {
  if (value < 0) {
    return '--' + window.vm.$t('common.min') + '--' + window.vm.$t('common.second');
  }
  let theTime = parseInt(value); // 秒
  let middle = 0; // 分

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
  }
  if (!theTime && !middle) {
    middle = '00';
    theTime = '00';
  }
  let result = '' + theTime + window.vm.$t('common.second');
  if (middle > 0 || middle === '00') {
    result = '' + middle + window.vm.$t('common.min') + result;
  }
  return result;
}
// 时间戳计算年龄
export function formatAge (birth) {
  // 当前年份减去生日年份
  return new Date().getFullYear() - new Date(birth).getFullYear();
}

export function timeWeekFormat (times) {
  // 定义一个日期对象;
  let dateTime = new Date(times);
  // 获得系统年份;
  let year = dateTime.getFullYear();
  // 获得系统月份;
  let month = dateTime.getMonth() + 1;
  // 获得系统当月分天数;
  let day = dateTime.getDate();
  // 获得系统小时;
  let hours = dateTime.getHours();
  // 获得系统分钟;
  let minutes = dateTime.getMinutes();
  // 获得系统秒数;
  let second = dateTime.getSeconds();
  // 获得系统星期几;
  let dayCycle = dateTime.getDay();
  // 使用数组更改日期样式;
  let dayCycleArray = ['日', '一', '二', '三', '四', '五', '六'];
  for (let i = 0; i < 7; i++) {
    if (dayCycle === i) {
      // 将dayCycleArray的数赋值到系统星期几里面中去;
      dayCycle = dayCycleArray[i];
    }
  }
  month < 10 ? month = '0' + month : month;
  hours < 10 ? hours = '0' + hours : hours;
  minutes < 10 ? minutes = '0' + minutes : minutes;
  second < 10 ? second = '0' + second : second;
  console.log(second);
  // 打印完整的系统日期;
  let dateStr = year + '-' + month + '-' + day + '  ' + '  ' + hours + ':' + minutes + '  ' + '星期' + dayCycle;
  return dateStr;
}

export function desensitization (str, beginLen, endLen) {
  let len = str.length;
  let firstStr = str.substr(0, beginLen);
  let lastStr = str.substr(endLen);
  let middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/ig, '**');
  let tempStr;
  tempStr = firstStr + middleStr + lastStr;
  return tempStr;
}
let textSecondParams = function (str) {
  if (Object.prototype.toString.call(str).slice(8, -1) === 'String') {
    return str.length;
  } else {
    throw new Error('请传递字符串');
  }
};
/**
 * 针对输液2.3的姓名脱敏:如果str长度大于6，只返回 5个新号   阿里提迈尔哈：阿*****
 * @param {*} str   需要脱敏的字符串
 * @param {*} frontLen 字符串前面保留位数 不允许负数,传递负数会转换为正数
 * @param {*} endLen  字符串后面保留位数  不允许负数,传递负数会转换为正数
 */
export function desensitization_sy (str, frontLen = textSecondParams(str), endLen = 0) {
  // if (!str) {
  //   throw new Error('参数不能为空');
  // }
  if (Object.prototype.toString.call(str).slice(8, -1) !== 'String') {
    return '';
  }
  if (Object.prototype.toString.call(frontLen).slice(8, -1) !== 'Number') {
    frontLen = str.length;
  }
  if (Object.prototype.toString.call(endLen).slice(8, -1) !== 'Number') {
    endLen = 0;
  }
  if (str.length === 0) {
    return '';
  }
  if (frontLen < 0 || endLen < 0) {
    frontLen = Math.abs(frontLen);
    endLen = Math.abs(endLen);
  }
  if (frontLen + endLen > str.length) {
    return str;
  }
  let len = str.length - frontLen - endLen;
  let xing = '';
  for (let i = 0; i < len; i++) {
    xing += '*';
  }
  let backStr = str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  // 脱敏后长度大于6那么只保留5位
  if (backStr.length >= 6) {
    return backStr.substring(0, 6);
  }
  return backStr; // 返回脱敏后的字符串
}

/**
 * 计算时间差
 * @param dateStr格式为yyyy-mm-dd hh:mm:ss
 * @param ndtNumber格式为number 毫秒:ms,减少的秒数
 */

export function reduceSecond (dateStr, ndtNumber) { // dateStr格式为yyyy-mm-dd hh:mm:ss
  let dt = new Date(dateStr.replace(/-/, '/'));// 将传入的日期格式的字符串转换为date对象 兼容ie
  let ndt = new Date(dt.getTime() - ndtNumber);// 将转换之后的时间减去两秒
  let result = {
    year: parseInt(ndt.getFullYear()),
    month: parseInt(ndt.getMonth() + 1),
    day: parseInt(ndt.getDay()),
    hour: parseInt(ndt.getHours()),
    minute: parseInt(ndt.getMinutes()),
    second: parseInt(ndt.getSeconds())
  };
  return result;
}
