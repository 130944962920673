/*
 * @Author: zxx
 * @Date: 2020-09-23 14:36:40
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-17 18:50:54
 * @FilePath: \xinsec-iot-zhaoqing\src\api\modules\three.js
 */
import axios from '@/utils/request';

const PROXY = '/proxy_three';

const threeAPI = {
  // 大耳马睡眠报告
  websocketSleepReport (url) {
    return axios.request(Object.assign({ url: PROXY + '/ibis/device/sleepReport' + url, method: 'get' }));
  }

};

export default threeAPI;
