/*
 * @Author: zxx
 * @Date: 2020-09-29 17:44:10
 * @LastEditors: zxx
 * @LastEditTime: 2020-10-09 11:49:13
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\settings.js
 */
import defaultSettings from '@/settings';

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings;

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  // model密码规则// 强密码模式列表 1== 是字母+数字 2== 是字母+符号 3== 是 数字+符号 4== 是数字+字母+符号
  // leastLength //最小位数
  passwordRuleOpt: {
    leastLength: 6,
    model: null// 默认为空
  },
  passwordTip: '', // 密码提示
  // 强密码模式option
  passwordRuleList: [
    {
      name: 'numLetter',
      value: 1,
      text: '字母+数字'
    },
    {
      name: 'letterSymbol',
      value: 2,
      text: '字母+符号'
    },
    {
      name: 'numSymbol',
      value: 3,
      text: '数字+符号'
    },
    {
      name: 'numLetterSymbol',
      value: 4,
      text: '数字+字母+符号'
    }
  ]

};
const getters = {
  getPasswordTip: (state) => {
    let temp = '';
    let passwordRuleTip = null;
    console.info(state.passwordRuleOpt, 'setting密码规则');
    if (!state.passwordRuleOpt.model) {
      state.passwordRuleOpt = JSON.parse(sessionStorage.getItem('passwordRuleOpt'));
    }
    if (state.passwordRuleOpt.model && state.passwordRuleOpt.model.length > 0) {
      state.passwordRuleOpt.model.forEach((item, index) => {
        temp += (state.passwordRuleList[item - 1].text + (index !== state.passwordRuleOpt.model.length - 1 ? '、' : ''));
      });
      passwordRuleTip = `密码必须大于等于${state.passwordRuleOpt.leastLength}位，且为以下任意一种组合形式：${temp}，组合内容不分先后`;
    } else {
      passwordRuleTip = `密码必须大于等于${state.passwordRuleOpt.leastLength}位`;
    }
    console.log(passwordRuleTip);
    return passwordRuleTip;
  }
};
const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  updatePasswordRuleOpt: (state, passwordRuleOpt) => {
    state.passwordRuleOpt = passwordRuleOpt;
    let passwordRuleOptStr = JSON.stringify(passwordRuleOpt);
    sessionStorage.setItem('passwordRuleOpt', passwordRuleOptStr);
  }
};

const actions = {
  changeSetting ({ commit }, data) {
    commit('CHANGE_SETTING', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

