/*
 * @Author: your name
 * @Date: 2019-12-09 20:02:05
 * @LastEditTime: 2019-12-10 15:38:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xinsec-new-security-vue\src\store\modules\nurse.js
 */
export default {
  namespaced: true,
  state: {
    // 白板模块ID白板1.3数据源
    // layerModuleIds: [
    //   { key: 1, name: '1：（病区信息）' },
    //   { key: 2, name: '2：（值班护士）' },
    //   { key: 3, name: '3：（备注）' },
    //   { key: 4, name: '4：（通知）' },
    //   { key: 5, name: '5：（应急电话）' },
    //   { key: 6, name: '6：（病区概要）' },
    //   { key: 7, name: '7：（护理项目分类）' },
    //   { key: 8, name: '8：（输液状态分类）' },
    //   { key: 9, name: '9：（检查分类信息）' },
    //   { key: 10, name: '10：（检验分类信息）' },
    //   { key: 11, name: '11：（手术安排）' }
    // ],
    layerModuleIds: [
      {
        key: '护理事项',
        name: '护理事项',
        type: 1, // 护理事项的字典类型
        children: [
          { key: 1, name: '1：（病区信息）' }, // key代表在新增白板拖拽那里对应的sort，这样在白板字典那里你才可以知道是拖拽到哪一个高亮，而白板新增那里就需要配置好，比如：病区信息的sort为1 对应此key为1
          { key: 2, name: '2：（值班护士）' },
          { key: 3, name: '3：（备注）' },
          { key: 4, name: '4：（通知）' },
          { key: 5, name: '5：（应急电话）' },
          { key: 6, name: '6：（病区概要）' },
          { key: 7, name: '7：（护理项目分类）' },
          { key: 8, name: '8：（输液状态分类）' },
          { key: 9, name: '9：（检查分类信息）' },
          { key: 10, name: '10：（检验分类信息）' },
          { key: 11, name: '11：（手术安排）' },
          { key: 12, name: '12：（自定义）' },
          { key: 13, name: '13：（胰岛素泵统计）' },

        ]
      },
      {
        key: '医护一览',
        name: '医护一览',
        type: 2, // 医护一览的字典类型
        children: [
          { key: 1, name: '1：（科室信息）' },
          { key: 2, name: '2：（护士组管床）' },
          { key: 3, name: '3：（治疗组管床）' },
          { key: 4, name: '4：（医生值班）' },
          { key: 5, name: '5：（护士值班）' }
        ]
      },
      {
        key: '患者一览',
        name: '患者一览',
        type: 3, // 医护一览的字典类型
        children: [
          { key: 1, name: '1：（患者信息）' }
        ]
      }

    ],
    closeId: null, // 关闭的白板id
    isFullscreen: false ,// 当前是否全屏
    NurseListPage:null, //存储当前页面点击新增白板时的页码

  },
  mutations: {
    setCloseId (state, closeId) {
      state.closeId = closeId;
    },
    // 赋值全屏
    setisFullscreen (state, status) {
      state.isFullscreen = status;
    },
    //设置当前跳转前的页码
    setNurseListPage(state,pageNum){
      state.NurseListPage = pageNum;
    },
    //清除页码
    clearNurseListPage(state){
      state.NurseListPage = null;
    },
  }
};
