/*
 * @Author: zxx
 * @Date: 2020-06-22 18:28:52
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-09 15:40:05
 * @FilePath: \xinsec-iot-zhaoqing\src\store\modules\baby.js
 */
export default {
  namespaced: true,
  state: {
    pectoralgiaNoticeList: [], // 全部胸痛报警信息
    //  诊断类型对照表
    DIAGNOSIS_OPT: {
      1: 'STEMI',
      2: 'NSTEMI',
      3: 'UA',
      4: '主动脉夹层',
      5: '肺动脉栓塞',
      6: '非ACS心源性胸痛',
      7: '其它非心源性胸痛',
      8: '待查'
    },
    // 是否绕行急诊
    DETOUR_OPT: {
      1: '经过急诊',
      2: '绕行急诊'
    },
    // 证件类型
    certificatesType: [
      {
        label: '身份证',
        value: 1
      },
      {
        label: '护照',
        value: 2
      },
      {
        label: '军官证',
        value: 3
      },
      {
        label: '未知',
        value: 99
      }
    ],
    // 职业
    occupationType: [
      {
        label: '国家机关、党群组织、企事业单位负责人',
        value: 1
      },
      {
        label: '各类专业技术人员',
        value: 2
      },
      {
        label: '办事人员和有关人员',
        value: 3
      },
      {
        label: '商业及服务型工作人员',
        value: 4
      },
      {
        label: '农、林、牧、渔、水利业生产人员',
        value: 5
      },
      {
        label: '生产工人、运输工人和相关人员',
        value: 6
      },
      {
        label: '下岗或无业',
        value: 7
      },
      {
        label: '离退休',
        value: 8
      },
      {
        label: '学生',
        value: 9
      },
      {
        label: '军人',
        value: 10
      },
      {
        label: '不便分类的其他从业人员',
        value: 11
      }
    ],
    // 文化程度
    degreeOfEducation: [
      {
        label: '没上过学',
        value: 1
      },
      {
        label: '小学',
        value: 2
      },
      {
        label: '初中',
        value: 3
      },
      {
        label: '高中或中专（中技）',
        value: 4
      },
      {
        label: '大专',
        value: 5
      },
      {
        label: '本科及以上',
        value: 6
      },
      {
        label: '不祥',
        value: 9
      }
    ],
    // 婚姻状况
    marriage: [
      {
        label: '未婚',
        value: 1
      },
      {
        label: '已婚',
        value: 2
      },
      {
        label: '丧偶',
        value: 3
      },
      {
        label: '离异',
        value: 4
      },
      {
        label: '不祥',
        value: 5
      }
    ],
    // 发病时间
    onsetTime: [
      {
        label: '凌晨（0点到6点）',
        value: 1
      },
      {
        label: '清晨（6到8点）',
        value: 2
      },
      {
        label: '上午（8到12点）',
        value: 3
      },
      {
        label: '中午（12到14点）',
        value: 4
      },
      {
        label: '下午（14到17点）',
        value: 5
      },
      {
        label: '傍晚（17到19点）',
        value: 6
      },
      {
        label: '晚上（19到24点）',
        value: 7
      }
    ],
    // 医保类型
    medicalInsurance: [
      {
        label: '城镇职工基本医疗保险',
        value: 1
      },
      {
        label: '新型农村合作医疗',
        value: 2
      },
      {
        label: '城镇居民基本医疗保险',
        value: 3
      },
      {
        label: '自费',
        value: 4
      },
      {
        label: '军免',
        value: 5
      }
    ],
    // 病情评估  多选
    assessmentCheckBox: [
      {
        label: '呼吸困难',
        value: 1
      },
      {
        label: '腹痛',
        value: 2
      },
      {
        label: '齿痛',
        value: 3
      },
      {
        label: '肩背痛',
        value: 4
      },
      {
        label: '合并出血',
        value: 5
      },
      {
        label: '合并心衰',
        value: 6
      },
      {
        label: '合并恶性心律失常',
        value: 7
      },
      {
        label: '不明原因的昏厥',
        value: 8
      },
      {
        label: '自汗、大汗淋漓',
        value: 9
      },
      {
        label: '心慌心悸',
        value: 10
      },
      {
        label: '烦躁不安',
        value: 11
      },
      {
        label: '颈前部束缚感',
        value: 12
      },
      {
        label: '乏力',
        value: 13
      },
      {
        label: '气喘',
        value: 14
      },
      {
        label: '其他',
        value: 99
      }
    ],
    // 意识类型
    consciousness: [
      {
        label: '清醒',
        value: 1
      },
      {
        label: '对语言有反应',
        value: 2
      },
      {
        label: '对刺痛有反应',
        value: 3
      },
      {
        label: '对任何刺激无反应',
        value: 4
      }
    ],
    // 药物类型
    medicineType: [
      {
        label: '第一代',
        value: 1
      },
      {
        label: '第二代',
        value: 2
      },
      {
        label: '第三代',
        value: 3
      }
    ],
    // 剂量：
    dose: [
      {
        label: '全量',
        value: 1
      },
      {
        label: '半量',
        value: 2
      }
    ],
    // 传输方式：
    transmission: [
      {
        label: '实时监控',
        value: 1
      },
      {
        label: '微信群',
        value: 2
      },
      {
        label: '其他',
        value: 3
      }
    ],
    // 肌钙蛋白容量选择
    jgdbrl: [
      {
        label: 'ng/mL',
        value: 1
      },
      {
        label: 'ug/L',
        value: 2
      },
      {
        label: 'ng/L',
        value: 3
      },
      {
        label: 'pg/mL',
        value: 4
      },
      {
        label: 'mg/L',
        value: 5
      }
    ],
    // 肌钙蛋白 阴  阳性
    jgdbyyx: [
      {
        label: '阴性',
        value: 1
      },
      {
        label: '阳性',
        value: 2
      }
    ],
    // D-二聚体  容量规格
    D_ejt_rl: [
      {
        label: 'ng/mL',
        value: 1
      },
      {
        label: 'ug/mL',
        value: 2
      },
      {
        label: 'ug/L',
        value: 3
      },
      {
        label: 'mg/L',
        value: 4
      }
    ],
    // Myo 容量规格
    Myo_rl: [
      {
        label: 'ng/mL',
        value: 1
      },
      {
        label: 'ug/L',
        value: 2
      }
    ],
    // CKMB 容量规格
    CKMB_rl: [
      {
        label: 'ng/mL',
        value: 1
      },
      {
        label: 'ug/L',
        value: 2
      },
      {
        label: 'u/L',
        value: 3
      },
      {
        label: 'IU/L',
        value: 4
      }
    ],
    // 抗凝类型
    kn_select: [
      {
        label: '普通肝素',
        value: 1
      },
      {
        label: '低分子肝素',
        value: 2
      },
      {
        label: '比伐卢定',
        value: 3
      },
      {
        label: '磺达肝癸钠',
        value: 4
      }
    ],
    // 无再灌注措施 多选
    wzgscs_checkbox: [
      {
        label: '生命体征平稳，无持续缺血表现',
        value: 1
      },
      {
        label: '超过再灌注时间窗',
        value: 2
      },
      {
        label: '高出血风险',
        value: 3
      },
      {
        label: '严重肝肾功能不全',
        value: 4
      },
      {
        label: '经济原因限制',
        value: 5
      },
      {
        label: '拒绝或放弃',
        value: 6
      },
      {
        label: '出血活动期',
        value: 7
      },
      {
        label: '其他原因',
        value: 9
      }
    ],
    // 术中抗凝药物  种类
    szknyw_Type: [
      {
        label: '普通肝素',
        value: 1
      },
      {
        label: '低分子肝素',
        value: 2
      },
      {
        label: '比伐卢定',
        value: 3
      },
      {
        label: '磺达肝癸钠',
        value: 4
      }
    ],
    // 是否延误 多选
    sfyw_checkbox: [
      {
        label: '症状不明显延误诊断',
        value: 1
      },
      {
        label: '家属未到场',
        value: 2
      },
      {
        label: '医生决策延误',
        value: 3
      },
      {
        label: '排队挂号、缴费、办住院时间长',
        value: 4
      },
      {
        label: '急诊科处理时间长',
        value: 5
      },
      {
        label: '手术期间出现并发症',
        value: 6
      },
      {
        label: '超过再灌注时间',
        value: 7
      },
      {
        label: '未实施绕行急诊方案',
        value: 8
      },
      {
        label: '导管室人员未到位',
        value: 9
      },
      {
        label: '药物缺乏',
        value: 10
      },
      {
        label: '知情同意时间过长',
        value: 11
      },
      {
        label: '病情不稳定',
        value: 12
      },
      {
        label: '绕行急诊科但未直接入导管室',
        value: 13
      },
      {
        label: '导管室占台',
        value: 14
      },
      {
        label: '转运时间长',
        value: 15
      },
      {
        label: '经费问题',
        value: 16
      },
      {
        label: '心内科会诊时间长',
        value: 17
      },
      {
        label: '血管通路困难',
        value: 18
      },
      {
        label: '难以绕行病变区域',
        value: 19
      },
      {
        label: '心脏骤停或需要气管插管再PCI',
        value: 20
      },
      {
        label: '急需影像学资料再行PCI',
        value: 21
      },
      {
        label: '其他原因',
        value: 99
      }
    ],
    // 术中处理  多选
    szcl_checkbox: [
      {
        label: '无',
        value: 0
      },
      {
        label: '植入支架',
        value: 1
      },
      {
        label: 'PTCA',
        value: 2
      },
      {
        label: '血栓抽吸',
        value: 3
      },
      {
        label: '冠脉内溶栓',
        value: 4
      },
      {
        label: '仅使用导丝',
        value: 5
      },
      {
        label: '其他',
        value: 9
      }
    ],
    // 术中并发症 多选
    szbfz_checkbox: [
      {
        label: '无',
        value: 0
      },
      {
        label: '恶性心律失常',
        value: 1
      },
      {
        label: '低血压',
        value: 2
      },
      {
        label: '慢血流/无复流',
        value: 3
      },
      {
        label: '血管夹层',
        value: 4
      },
      {
        label: '非干预血管急性闭塞',
        value: 5
      },
      {
        label: '血管穿孔',
        value: 6
      },
      {
        label: '死亡',
        value: 7
      },
      {
        label: '心源性休克',
        value: 8
      },
      {
        label: '严重缓慢型心律失常',
        value: 9
      },
      {
        label: '血栓栓塞',
        value: 10
      },
      {
        label: '其他',
        value: 99
      }
    ],
    // Grace评估 多选1
    Gracepg_checkbox1: [
      {
        label: '发病后曾出现心脏骤停',
        value: 1
      },
      {
        label: '心电图ST段改变',
        value: 2
      },
      {
        label: '心肌坏死标志物升高',
        value: 3
      }
    ],
    // Grace评估 多选2
    Gracepg_checkbox2: [
      {
        label: '急性心力衰竭',
        value: 1
      },
      {
        label: '致命性心律失常或心脏骤停',
        value: 2
      },
      {
        label: '血液动力学不稳定或心源性休克',
        value: 3
      },
      {
        label: '心肌梗死合并机械并发症',
        value: 4
      },
      {
        label: '反复的ST-T动态改变，尤其是伴随间歇性ST段抬高',
        value: 5
      },
      {
        label: '药物治疗无效的反复发作或持续性胸痛',
        value: 6
      }
    ],
    // 影像学检查  多选
    yxxjc_checkbox: [
      {
        label: '急诊CT',
        value: 1
      },
      {
        label: '彩超',
        value: 2
      },
      {
        label: 'MRI',
        value: 3
      },
      {
        label: '未做',
        value: 4
      }
    ],
    // 影像学检查  多选
    yxxjc_checkbox2: [
      {
        label: '急诊CT',
        value: 1
      },
      {
        label: '未做',
        value: 4
      }
    ],
    // 非ACS心源性胸痛类型：
    fACSxyxxtlx_checkbox: [
      {
        label: '心律失常',
        value: 1
      },
      {
        label: '扩张性心肌病',
        value: 2
      },
      {
        label: '缺血性心肌病',
        value: 3
      },
      {
        label: '肥厚型心肌病',
        value: 4
      },
      {
        label: '心肌炎',
        value: 5
      },
      {
        label: '冠心病',
        value: 6
      },
      {
        label: '瓣膜性心脏病',
        value: 7
      },
      {
        label: '陈旧性心肌梗死',
        value: 8
      },
      {
        label: '心绞痛',
        value: 9
      },
      {
        label: '心悸',
        value: 10
      },
      {
        label: '房颤',
        value: 11
      },
      {
        label: '高血压',
        value: 12
      },
      {
        label: '心衰',
        value: 13
      },
      {
        label: '房扑',
        value: 14
      },
      {
        label: '室早',
        value: 15
      },
      {
        label: '房早',
        value: 16
      },
      {
        label: '室上速',
        value: 17
      },
      {
        label: '心包炎',
        value: 18
      },
      {
        label: '心肌桥',
        value: 19
      },
      {
        label: '心包积液',
        value: 20
      },
      {
        label: '主动脉管壁间血肿',
        value: 21
      },
      {
        label: '透壁性动脉粥样硬化性溃疡',
        value: 22
      },
      {
        label: '主动脉瘤 ',
        value: 23
      },
      {
        label: '张力性气胸',
        value: 24
      }
    ],
    // 其它非心源性胸痛类型：
    qtfxyxxtlx_checkbox: [
      {
        label: '呼吸系统病',
        value: 1
      },
      {
        label: '消化系统病',
        value: 2
      },
      {
        label: '神经系统病',
        value: 3
      },
      {
        label: '精神系统病',
        value: 4
      },
      {
        label: '肌肉骨骼病',
        value: 5
      },
      {
        label: '皮肤系统病',
        value: 6
      },
      {
        label: '其他',
        value: 9
      }
    ],
    // 药物名称
    ywmc_checkbox: [
      { label: '磺脲类', value: 1 },
      { label: '双胍类', value: 2 },
      { label: 'α糖苷酶抑制剂', value: 3 },
      { label: 'DPP-4抑制剂', value: 4 },
      { label: 'GLP-1受体激动剂', value: 5 },
      { label: 'SGLT-2抑制剂', value: 6 },
      { label: '胰岛素及胰岛素类似物', value: 7 },
      { label: '其他', value: 99 }
    ],
    // 药物名称下拉框
    ywmc_select: [
      { label: '华法林', value: 1 },
      { label: '达比加群酯', value: 2 },
      { label: '利伐沙班', value: 3 },
      { label: '阿哌沙班', value: 4 },
      { label: '其他', value: 99 }
    ],
    // PCSK9药物名称下拉框
    PCSK9_select: [
      { label: '阿利西尤单抗', value: 1 },
      { label: '依洛尤单抗', value: 2 }
    ],
    // 住院期间并发症
    zyqjbfz: [
      {
        label: '无',
        value: 0
      },
      {
        label: '休克',
        value: 1
      },
      {
        label: '机械性并发症',
        value: 2
      },
      {
        label: '感染',
        value: 3
      },
      {
        label: '再发心梗',
        value: 4
      },
      {
        label: '血栓',
        value: 5
      },
      {
        label: '卒中',
        value: 6
      },
      {
        label: 'TIA',
        value: 7
      },
      {
        label: '出血',
        value: 8
      },
      {
        label: '呼吸衰竭',
        value: 9
      },
      {
        label: '肾衰竭',
        value: 10
      },
      {
        label: '死亡',
        value: 11
      },
      {
        label: '恶性心律失常',
        value: 12
      }
    ],
    // 72h内肌钙蛋白最高值  单位
    seventy_two_hnjgdb_dw: [
      {
        label: 'ng/mL',
        value: 1
      },
      {
        label: 'ug/L',
        value: 2
      },
      {
        label: 'ng/L',
        value: 3
      },
      {
        label: 'pg/mL',
        value: 4
      },
      {
        label: 'mg/L',
        value: 5
      }
    ],
    // 离院宣教
    lyxj_checkbox: [
      {
        label: '戒烟',
        value: 1
      },
      {
        label: '规律坚持服药',
        value: 2
      },
      {
        label: '遵医嘱随访治疗',
        value: 3
      },
      {
        label: '生活起居调护',
        value: 4
      },
      {
        // label: '心血管危险因素控制（降压、调脂、血糖控制、限酒、控制体重）',
        label: '心血管危险因素控制',
        value: 5
      },
      {
        label: '未宣教',
        value: 9
      }
    ],
    // 抗血小板药物 多选
    kxxbyw_checkbox: [
      {
        label: '阿司匹林',
        value: 1
      },
      {
        label: '氯吡格雷',
        value: 2
      },
      {
        label: '替格瑞洛',
        value: 3
      },
      {
        label: '普拉格雷',
        value: 4
      },
      {
        label: '西洛他唑',
        value: 5
      },
      {
        label: '其它',
        value: 6
      }
    ],
    // ACEI/ARB   多选
    ACEI_ARB_checkbox: [
      {
        label: '贝那普利',
        value: 1
      },
      {
        label: '依那普利',
        value: 2
      },
      {
        label: '赖诺普利',
        value: 3
      },
      {
        label: '卡托普利',
        value: 4
      },
      {
        label: '福辛普利',
        value: 5
      },
      {
        label: '莫西普利',
        value: 6
      },
      {
        label: '培哚普利',
        value: 7
      },
      {
        label: '雷米普利',
        value: 8
      },
      {
        label: '缬沙坦',
        value: 9
      },
      {
        label: '氯沙坦',
        value: 10
      },
      {
        label: '厄贝沙坦',
        value: 11
      },
      {
        label: '坎地沙坦',
        value: 12
      },
      {
        label: '替米沙坦',
        value: 13
      },
      {
        label: '奥美沙坦',
        value: 14
      },
      {
        label: '沙库巴曲缬沙坦钠',
        value: 15
      },
      {
        label: '单片复方制剂',
        value: 16
      },
      {
        label: '其他（单药）',
        value: 17
      }
    ],
    // 调脂药物  多选
    dzyw_checkbox: [
      {
        label: '阿托伐他汀',
        value: 1
      },
      {
        label: '瑞舒伐他汀',
        value: 2
      },
      {
        label: '洛伐他汀',
        value: 3
      },
      {
        label: '普伐他汀',
        value: 4
      },
      {
        label: '氟伐他汀',
        value: 5
      },
      {
        label: '匹伐他汀',
        value: 6
      },
      {
        label: '依洛尤单抗',
        value: 7
      },
      {
        label: '依折麦布',
        value: 8
      },
      {
        label: '阿利西尤单抗',
        value: 9
      },
      {
        label: '其他',
        value: 10
      }
    ],
    // β受体阻滞剂  多选
    βstzzj_checkbox: [
      {
        label: '酒石酸美托洛尔',
        value: 1
      },
      {
        label: '琥珀酸美托洛尔',
        value: 2
      },
      {
        label: '比索洛尔',
        value: 3
      },
      {
        label: '阿替洛尔',
        value: 4
      },
      {
        label: '普萘洛尔',
        value: 5
      },
      {
        label: '奈必洛尔',
        value: 6
      },
      {
        label: '卡维地洛',
        value: 7
      },
      {
        label: '其他',
        value: 8
      }
    ],
    // 服用频次 次数
    fypc_cs_checkbox: [
      {
        label: '1次',
        value: 1
      },
      {
        label: '2次',
        value: 2
      },
      {
        label: '3次',
        value: 3
      },
      {
        label: '4次',
        value: 4
      }
    ],
    // 服用频次 区间
    fypc_qj_checkbox: [
      {
        label: '每日',
        value: 1
      },
      {
        label: '每周',
        value: 2
      },
      {
        label: '每月',
        value: 3
      }
    ],
    // 人口基本信息
    populationBasicMessage: true,
    // 基本信息
    basicMessage: true,
    // 病情评估
    assessment: true,
    // 来院方式
    HospitalWay: true,
    // 生命体征
    vitalSigns: true,
    // 院前溶栓治疗
    thrombolyticTherapy: true,
    // ===========
    // 心电图
    electrocardiogram: true,
    // 实验室检查
    laboratoryExamination: true,
    // 心内科会诊
    groupConsultation: true,
    // 诊断
    diagnosis: true,
    // 患者转归
    // 出院诊断
    OutcomeOfPatients: true,
    // 住院期间用药
    zyqjyy: true,
    // 危险因素
    wxys: true,
    // 合并疾病
    hbjb: true,
    // 检查结果
    jcjg: true,
    // 出院信息
    cyxx: true,
    // 出院带药（仍在住院患者填写第30天用药）
    cydy: true,
    xtzl88ShowGmzy: false, // 【溶栓后24h内造影】、【造影开始时间】是否显示
    // 急救信息 当前显示模块列表
    jjxxModelList: [],
    // 胸痛诊疗  当前显示模块列表
    xtzlModelList: [],
    // 患者转归  当前显示模块列表
    hzzgModelList: [],
    // =====================
    // 冠脉造影数组对象
    gmzyArr: [
      {
        label: '右冠近段',
        value: 1
      },
      {
        label: '右冠中段',
        value: 2
      },
      {
        label: '右冠远段',
        value: 3
      },
      {
        label: '后降支(右优势型)',
        value: 4
      },
      {
        label: '左主干',
        value: 5
      },
      {
        label: '前降支近段',
        value: 6
      },
      {
        label: '前降支中段',
        value: 7
      },
      {
        label: '前降支远段',
        value: 8
      },
      {
        label: '第一对角支',
        value: 9
      },
      {
        label: '第二对角支',
        value: 10
      },
      {
        label: '旋支近段',
        value: 11
      },
      {
        label: '第一钝缘支',
        value: 12
      },
      {
        label: '旋支远段',
        value: 13
      },
      {
        label: '左室后支',
        value: 14
      },
      {
        label: '后降支(左优势型或均衡型)',
        value: 15
      }
    ],
    // 冠脉造影数组对象
    gmzyArr2: [
      {
        label: '中间支',
        value: 16
      },
      {
        label: '第三对角支',
        value: 17
      },
      {
        label: '第二钝缘支',
        value: 18
      },
      {
        label: '第三钝缘支',
        value: 19
      },
      {
        label: '锐缘支',
        value: 20
      },
      {
        label: '左圆椎支',
        value: 21
      },
      {
        label: '右圆椎支',
        value: 22
      },
      {
        label: '室间隔支',
        value: 23
      },
      {
        label: '左后外侧支',
        value: 24
      },
      {
        label: '右后外侧支',
        value: 25
      },
      {
        label: '房室沟动脉',
        value: 26
      },
      {
        label: '后降支室间支',
        value: 27
      }
    ],
    // 时间轴数组对象
    Time_axis: {
      diseaseTime: '发病时间',
      jjxx5: '呼救时间',
      // jjxx8: '到达医院大门时间',
      jjxx8: '到达本院大门时间',
      jjxx9: '首次医疗接触时间',
      // jjxx9: '本次发病后首次医疗接触时间',
      jjxx10: '院内首诊医师接诊时间',
      jjxx34: '转出医院入门时间',
      jjxx35: '决定转院时间',
      // jjxx36: '转出医院出门时间',
      jjxx36: '离开转出医院时间',
      jjxx42: '到达本院大门时间',
      jjxx37: '院内接诊时间',
      // jjxx37: '院内转诊时间',
      jjxx39: '床位医生接触时间（会诊时间）',
      jjxx40: '离开科室时间',

      // jjxx21: '开始知情同意时间',
      // jjxx22: '签署知情同意书时间',
      jjxx21: '溶栓开始知情同意时间',
      jjxx22: '溶栓知情同意书时间',
      jjxx24: '开始溶栓时间',
      jjxx25: '溶栓结束时间',

      heartTime: '首份心电图时间',
      // heartZdTime: '心电图诊断时间',
      heartZdTime: '首份心电图诊断时间',
      xtzl3: '接收120 / 网络医院心电图时间',
      finishTime: '抽血完成时间', // 肌钙蛋白抽血时间
      // reportTime: '获得报告时间',
      reportTime: '报告完成时间',

      // 院内 溶栓/溶栓=补救PCI/溶栓-溶栓后介入
      xtzl30: '通知CT室时间',
      xtzl31: 'CT室完成准备时间',
      xtzl32: 'CT扫描开始时间',
      xtzl33: 'CT报告时间',
      xtzl35: '彩超检查时间',
      xtzl36: '彩超出结果时间',
      xtzl37: '通知心外科会诊时间',
      xtzl38: '心外科会诊时间',
      xtzl48: '开始抗凝治疗时间',
      xtzl54: '溶栓开始知情同意时间',
      xtzl55: '溶栓知情同意书时间',

      xtzl22: '通知会诊时间',
      xtzl23: '会诊时间',

      xtzl42: '初步诊断时间',
      xtzl63: '阿司匹林时间',
      xtzl65: '氯吡格雷时间',
      xtzl67: '替格瑞洛时间',
      // xtzl72: '抗凝时间',
      xtzl72: '首次抗凝给药时间',
      kxxb: '抗血小板用药时间',

      xtzl80: '再次危险分层时间',
      xtzl84: '决定介入手术时间',
      xtzl91: '决定介入手术时间',
      xtzl92: '启动导管室时间',
      // xtzl93: '开始知情同意时间',
      // xtzl94: '签署知情同意时间',
      xtzl93: 'PCI开始知情同意时间',
      xtzl94: 'PCI知情同意书时间',

      xtzl99: '导管室激活时间',
      xtzl100: '患者到达导管室时间',
      xtzl102: '开始穿刺时间',
      xtzl103: '造影开始时间',
      xtzl104: '术中抗凝给药时间',
      xtzl108: '手术结束时间',

      xtzl56: '开始溶栓时间',
      xtzl57: '溶栓结束时间',

      xtzl95: '决定CABG时间',
      xtzl96: '开始CABG时间',

      gmzy10: '导丝通过时间',
      gmzy15: '球囊扩张时间',

      hzzg2: '确诊时间',
      hzzg53: '出院时间',
      hzzg60: '离开本院大门时间',
      hzzg74: '实际介入手术开始时间',
      hzzg65: '传输心电图至协作单位时间',
      hzzg68: '转科时间',
      hzzg71: '死亡时间'
    },
    // 患者转归是否可选
    noClickHzzgFlag: true, // 默认可选
    // 时间管理表
    csyw: true, // 初始药物
    zgzcs: true, // 再灌注措施
    zcwxfc: true, // 再次危险分层
    Gracepg: true, // Grace评估
    clcl: true, // 处理策略
    ynrszl: true, // 院内溶栓治疗
    jjxxNumber: null, // 急救信息所有字段数
    xtzlNumber: null, // 胸痛诊疗所有字段数
    hzzgNumber: null, // 患者转归所有字段数
    jjxxRequiredNumber: null, // 急救信息必填字段数
    xtzlRequiredNumber: null, // 胸痛诊疗必填字段数
    hzzgRequiredNumber: null, // 患者转归必填字段数
    isEdit: true, // 时间管理表设置是否可以编辑 true 可编辑   false 不可编辑
    jjxx6: null, // 时间管理表 - 急救信息 - 来院方式 - 直接转送上级医院(转出患者时) 否/是 ys_no/ys_yes
    xtzl24: null, // 时间管理表 - 胸痛诊疗 - 诊断 - 初步诊断 - 非ACS心源性胸痛(6)、其它非心源性胸痛(7)、待查(8)
    xtzl25: null // 时间管理表 - 胸痛诊疗 -诊断 - 患者自愿放弃后续治疗：否/是 ys_no/ys_yes
  },
  mutations: {
    setPectoralgiaNoticeList (state, val) {
      state.pectoralgiaNoticeList = val;
    },
    pushPectoralgiaNoticeList (state, val) {
      state.pectoralgiaNoticeList.push(val);
    },
    setPopulationBasicMessage (state, val) {
      state.populationBasicMessage = val;
    },
    setBasicMessage (state, val) {
      state.setBasicMessage = val;
    },
    setAssessment (state, val) {
      state.assessment = val;
    },
    setHospitalWay (state, val) {
      state.HospitalWay = val;
    },
    setVitalSigns (state, val) {
      state.vitalSigns = val;
    },
    setThrombolyticTherapy (state, val) {
      state.thrombolyticTherapy = val;
    },
    setElectrocardiogram (state, val) {
      state.electrocardiogram = val;
    },
    setLaboratoryExamination (state, val) {
      state.laboratoryExamination = val;
    },
    setGroupConsultation (state, val) {
      state.groupConsultation = val;
    },
    setDiagnosis (state, val) {
      state.diagnosis = val;
    },
    setOutcomeOfPatients (state, val) {
      state.OutcomeOfPatients = val;
    },
    // 住院期间用药
    setZyqjyy (state, val) {
      state.zyqjyy = val;
    },
    // 危险因素
    setWxys (state, val) {
      state.wxys = val;
    },
    // 合并疾病
    setHbjb (state, val) {
      state.hbjb = val;
    },
    // 检查结果
    setJcjg (state, val) {
      state.jcjg = val;
    },
    // 出院信息
    setCyxx (state, val) {
      state.cyxx = val;
    },
    // 出院带药（仍在住院患者填写第30天用药）
    setCydy (state, val) {
      state.cydy = val;
    },
    setNoClickHzzgFlag (state, val) {
      state.noClickHzzgFlag = val;
    },
    setCsyw (state, val) {
      state.csyw = val;
    },
    setZgzcs (state, val) {
      state.zgzcs = val;
    },
    setZcwxfc (state, val) {
      state.zcwxfc = val;
    },
    setGracepg (state, val) {
      state.Gracepg = val;
    },
    setClcl (state, val) {
      state.clcl = val;
    },
    setYnrszl (state, val) {
      state.ynrszl = val;
    },

    // 急救信息所有字段数
    setJjxxNumber (state, val) {
      state.jjxxNumber = val;
    },
    // 胸痛诊疗所有字段数
    setXtzlNumber (state, val) {
      state.xtzlNumber = val;
    },
    // 患者转归所有字段数
    setHzzgNumber (state, val) {
      state.hzzgNumber = val;
    },
    // 急救信息必填字段数
    setJjxxRequiredNumber (state, val) {
      state.jjxxRequiredNumber = val;
    },
    // 胸痛诊疗必填字段数
    setXtzlRequiredNumber (state, val) {
      state.xtzlRequiredNumber = val;
    },
    // 患者转归必填字段数
    setHzzgRequiredNumber (state, val) {
      state.hzzgRequiredNumber = val;
    },
    // 时间管理表是否可编辑
    setIsEdit (state, val) {
      state.isEdit = val;
    },
    // xtzl88ShowGmzy   【溶栓后24h内造影】、【造影开始时间】
    setXtzl88ShowGmzy (state, val) {
      state.xtzl88ShowGmzy = val;
    },
    // 急救信息 当前显示模块列表
    setJjxxModelList (state, val) {
      state.jjxxModelList = val;
    },
    // 胸痛诊疗  当前显示模块列表
    setXtzlModelList (state, val) {
      state.xtzlModelList = val;
    },
    // 患者转归  当前显示模块列表
    setHzzgModelList (state, val) {
      state.hzzgModelList = val;
    },
    // 时间管理表 - 直接转送上级医院(转出患者时)
    setJjxx6 (state, val) {
      state.jjxx6 = val;
    },
    // 时间管理表 - 非ACS心源性胸痛(6)、其它非心源性胸痛(7)、待查(8)
    setXtzl24 (state, val) {
      state.xtzl24 = val;
    },
    // 时间管理表 - 患者自愿放弃后续治疗：否/是 ys_no/ys_yes
    setXtzl25 (state, val) {
      state.xtzl25 = val;
    }
  }
};
