// 获取摄像头地址 2 =wvp  else = livegbs
import store from '@/store';
export const getCameraSrc = async function(info){
  let obj = info.cameraEntity ? info.cameraEntity : info
  let url = ''
  if(obj.cameraPlatform === 2){
    await store.dispatch('common/getCameraUrl', obj).then(res => {
      url = res
    })
  }else{
    url = store.state.common.cameraUrl + ('/play.html?serial=' + obj.serial + '&code=' + obj.code + '&aspect=fullscreen&ptz=no')
  }
  await console.log('getCameraSrcUrl', url)
  return url
}
