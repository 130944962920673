import Vue from "vue";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { treeDataTranslate, arrToObj } from "@/utils";
import { message } from "@/utils/resetMessage";
import apis from "@/api/index";
import router, { constantRoutes } from "@/router";
import { conversionUrl } from "@/utils/common";

const state = {
  token: getToken(),
  userInfo: {}, // 用户信息
  menuList: [], // 菜单数据
  cacheData: {}, // 所有缓存数据
  // cacheSiteList: [], // 缓存楼栋楼层信息
  router: {
    // 动态路由
    children: []
  },
  previousRoute:null,// 上一个页面路由信息
  menuAuthList: [], // 菜单权限 - 显示用
  selectionMenuList: {}, // 当前菜单
  loginCode: 12138, // 登录code值(自定义默认值,作为判断是否登录成功code值)
  encryptionName: {
    type: 0,
    appId: []
  }
};
const getters = {
  // 根据科室获取病区
  // 获取deptID:[病区]对象
  getAreaByDept: state => {
    let obj = {};
    state.cacheData.cacheArea.forEach(item => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(item.deptId)) {
        obj[item.deptId].push(item);
      } else {
        obj[item.deptId] = [item];
      }
    });
    return obj;
  },
  // 缓存病区
  cacheArea: state => {
    let obj = {};
    state.cacheData.cacheArea.forEach(item => {
      obj[item.areaId] = item;
    });
    return obj;
  },
  // 病区
  cacheAreaCode: state => {
    let obj = {};
    state.cacheData.cacheArea.forEach(item => {
      obj[item.areaCode] = item;
    });
    return obj;
  },
  // 缓存科室
  cacheDept: state => {
    let obj = {};
    state.cacheData.cacheDept.forEach(item => {
      obj[item.deptId] = item;
    });
    return obj;
  },
  // 院区
  cacheHospital: state => {
    let obj = {};
    state.cacheData.cacheHospital.forEach(item => {
      obj[item.hospitalId] = item;
    });
    return obj;
  },
  // 楼层
  cacheSite: state => {
    let obj = {};
    let cacheSite = state.cacheData.cacheSite || [];

    cacheSite.forEach(item => {
      obj[item.siteId] = item;
    });
    return obj;
  },
  // 缓存床位
  cacheBed: state => {
    let obj = {};
    if (state.cacheData.cacheBed.length > 0) {
      state.cacheData.cacheBed.forEach(item => {
        obj[item.bedId] = item;
      });
    }
    return obj;
  },
  // 缓存客户信息
  cacheUser: state => {
    let obj = {};
    state.cacheData.cacheUser.forEach(item => {
      obj[item.userId] = item;
    });
    return obj;
  },
  // 返回当前用户areaId对应病区权限列表
  cacheAreaAuth: state => {
    let { areaIdList } = state.userInfo;
    let obj = {};
    areaIdList.forEach(item => {
      state.cacheData.cacheArea.forEach(item2 => {
        item2.areaId === item ? (obj[item] = item2) : null;
      });
    });
    return obj;
  },

  // 组装当前用户科室下树形数据(包括病区关联)
  cacheDeptByDeptIdArea: state => {
    let { deptId } = state.userInfo;
    let newAreaArr = [];
    let newDeptArr = [];
    // 将缓存列表部分和病区存起来,防止此方法计算时候,其他的方法同时调用下面两个值造成不可以控影响
    let copyAreaList = JSON.parse(JSON.stringify(state.cacheData.cacheArea));
    let copyDeptList = JSON.parse(JSON.stringify(state.cacheData.cacheDept));
    // 转换为树形数据所需的格式 deptId和parentId相同会导致转换函数出错,所以+10000以区别,后期有用到再-10000恢复
    copyAreaList.forEach(item => {
      if (item.deptId === deptId) {
        if (!item.parentId) {
          item.parentId = item.deptId;
          item.deptId += 10000;
        }
        newAreaArr.push(item);
      }
    });
    // 将科室和属于该科室下的子科室查找出来
    copyDeptList.forEach(item => {
      if (item.deptId === deptId || item.parentId === deptId) {
        newDeptArr.push(item);
      }
    });
    // 组合我们所需要转换的数据
    let newArr = [...newAreaArr, ...newDeptArr];
    console.log(newArr, "组合的数据");
    let newTransformArr = treeDataTranslate(newArr, "deptId", "parentId");
    // 转换成功后再次转化为我们ele组件所需要的对象
    newTransformArr.forEach(item => {
      item.value = item.deptId > 10000 ? item.deptId - 10000 : item.deptId;
      item.label = item.deptName ? item.deptName : item.areaName;
    });
    return newTransformArr;
  },
  // 组装当前用户科室下树形数据(不包含病区) 与下一函数同理,可查看(权限)
  cacheDeptByDeptId: state => {
    let { deptIdList } = state.userInfo;
    let copyDeptList = state.cacheData.cacheDept;
    if (!(deptIdList && deptIdList.length > 0)) return []; // 权限为空返回空
    if (!(copyDeptList && copyDeptList.length > 0)) return []; // 缓存科室为空返回空
    let copyDeptListCopy = JSON.parse(
      JSON.stringify(state.cacheData.cacheDept)
    );
    let newDeptArr = [];
    // 将科室和属于该科室下的子科室查找出来
    copyDeptListCopy.forEach(item => {
      deptIdList.forEach(item1 => {
        if (item.deptId === item1) {
          newDeptArr.push(item);
        }
      });
    });
    // let newDeptArr = state.cacheData.cacheDept;
    console.log("筛选后的科室参数", newDeptArr);
    // 转换为树形数据所需的格式
    let newTransformArr = null;
    newTransformArr = treeDataTranslate(newDeptArr, "deptId", "parentId");
    return newTransformArr;
  },
  // 组装缓存科室树形数据(不包含病区) 与下一函数同理,可查看
  cacheDeptTree: state => {
    // let { deptId } = state.userInfo;
    // let newDeptArr = [];
    let copyDeptList = JSON.parse(JSON.stringify(state.cacheData.cacheDept));
    // 将科室和属于该科室下的子科室查找出来
    console.log(copyDeptList, "缓存科室");
    copyDeptList.forEach(i => {
      i["rightDeptId"] = i.deptId;
    });
    // 转换为树形数据所需的格式
    let newTransformArr = treeDataTranslate(copyDeptList, "deptId", "parentId");
    console.log("父子科室关系", newTransformArr);
    // 转换成功后再次转化为我们ele组件所需要的对象
    newTransformArr.forEach(item => {
      item.value = item.deptId;
      item.label = item.deptName;
      if (item.children) {
        item.children.forEach(item1 => {
          item1.value = item1.deptId;
          item1.label = item1.deptName;
        });
      }
    });
    return newTransformArr;
  },
  // 组装缓存科室树形数据(包括病区关联)
  deptAreaTree: state => {
    // 转换为树形数据所需的格式 deptId和parentId相同会导致转换函数出错,所以+10000以区别,后期有用到再-10000恢复
    state.cacheData.cacheArea.forEach(item => {
      if (!item.parentId) {
        item.parentId = item.deptId;
        item.deptId += 10000;
      }
    });
    let newArr = [...state.cacheData.cacheDept, ...state.cacheData.cacheArea];
    newArr.forEach((item, index) => {
      item.value = item.deptId > 10000 ? item.deptId - 10000 : item.deptId;
      item.label = item.deptName ? item.deptName : item.areaName;
    });
    // 将部分和病区放入转换函数内
    let deptTree = treeDataTranslate(newArr, "deptId", "parentId");
    return deptTree;
  },
  // 根据用户病区权限返回病区列表
  areaListByAuth: state => {
    let arr = [];
    let obj = {};
    try {
      let cacheArea = JSON.parse(JSON.stringify(state.cacheData.cacheArea));
      if (cacheArea && cacheArea.length > 0) {
        cacheArea.forEach(item => {
          obj[item.areaId] = item;
        });
        state.userInfo.areaIdList &&
          state.userInfo.areaIdList.forEach(item => {
            arr.push(obj[item]);
          });
        // 过滤掉undefined项
        arr = arr.filter(item => {
          return item !== undefined;
        });
        return arr;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  // 用户拥有的病区权限id集合
  areaIdListByAuth: state => {
    console.log("用户拥有的病区权限id集合：", state.userInfo.areaIdList);
    return state.userInfo.areaIdList;
  },
  // 用户拥有的科室权限id集合
  deptIdListByAuth: state => {
    console.log("用户拥有的科室权限id集合", state.userInfo.deptIdList);
    return state.userInfo.deptIdList;
  },
  // 院区数组转化为对象
  cacheHospitalObj: state => {
    if (
      state.cacheData &&
      state.cacheData.cacheHospital &&
      state.cacheData.cacheHospital.length > 0
    ) {
      let res = arrToObj(state.cacheData.cacheHospital, "hospitalId");
      return res;
    } else {
      return {};
    }
  },
  // 获取某个模块的超级管理员权限
  adminDateAuth: state => {
    let { adminDateAuth } = state.userInfo; // 用户信息的应用权限
    let curPath = state.selectionMenuList.path; // 当前模块
    let powerAppAuth = false; // 返回的应用权限,拥有这些应用权限,则在当前模块会拥有所有病区和科室的数据权限
    if (adminDateAuth) {
      adminDateAuth.forEach(item => {
        if (item === 1 && curPath === "/assets") {
          powerAppAuth = "assets";
        } else if (item === 2 && curPath === "/emer") {
          powerAppAuth = "emer";
        } else if (item === 3 && curPath === "/care") {
          powerAppAuth = "care";
        } else if (item === 4 && curPath === "/baby") {
          powerAppAuth = "baby";
        } else {
          powerAppAuth = false;
        }
      });
    }
    return powerAppAuth;
  },
  // 判断是否拥有当前模块的超级管理员权限
  hasCurSysAdminAuth: state => {
    let { adminDateAuth } = state.userInfo; // 用户信息的应用权限
    if (!adminDateAuth) return false;
    let curPath = state.selectionMenuList.path; // 当前模块
    let authModuleMapping = {
      // 权限ID与系统模块的映射
      "/equip": 0,
      "/assets": 1,
      "/emer": 2,
      "/care": 3,
      "/baby": 4,
      "/transfusion": 5,
      "/nurse": 6,
      "/humiture": 7
      // '/xxx': 8 安防报警
    };

    // eslint-disable-next-line no-prototype-builtins
    if (authModuleMapping.hasOwnProperty(curPath)) {
      return adminDateAuth.includes(authModuleMapping[curPath]);
    } else {
      return false;
    }
  },

  // 判断是否有超级管理员权限，返回病区列表
  areaListByAdminAuth: (state, getters) => {
    let areaList = [];
    if (getters.hasCurSysAdminAuth) {
      areaList = state.cacheData.cacheArea || [];
    } else {
      areaList = getters.areaListByAuth || [];
    }
    return areaList;
  },
  // 判断是否有超级管理员权限,返回科室列表
  deptListByAdminAuth: (state, getters) => {
    let deptList = [];
    if (getters.hasCurSysAdminAuth) {
      deptList = getters.cacheDeptTree || [];
    } else {
      deptList = getters.cacheDeptByDeptId || [];
    }
    return deptList;
  },
  // 默认病区ID(有权限)
  defalutAreaId: (state, getters) => {
    return getters.areaListByAdminAuth[0]
      ? getters.areaListByAdminAuth[0]["areaId"]
      : null;
  },
  // 默认科室ID(有权限)
  defalutDeptId: (state, getters) => {
    return getters.deptListByAdminAuth[0]
      ? getters.deptListByAdminAuth[0]["deptId"]
      : null;
  },
  // 组装所属权限科室病区级联表
  cacheDeptAndAreaByAuth(state) {
    let { areaIdList, deptId } = state.userInfo;
    // 权限科室数组
    let deptList = [];
    // 权限病区数组
    let areaList = [];
    // 对缓存科室做对象化处理
    let cacheDeptData = {}; //
    state.cacheData.cacheDept.forEach(item => {
      cacheDeptData[item.deptId] = item;
    });
    // 对科室做特殊判断,应付后来的多科室情况（筛选权限科室）
    if (deptId === 0 || deptId) {
      let temp = deptId instanceof Array ? deptId : [deptId];
      temp.forEach(item => {
        deptList.push(cacheDeptData[item]);
      });
    } else {
      return [];
    }
    // 对缓存病区做对象化处理
    let cacheAreaData = {}; //
    state.cacheData.cacheArea.forEach(item => {
      cacheAreaData[item.areaId] = item;
    });
    // 筛选权限病区
    if (areaIdList && areaIdList.length > 0) {
      areaIdList.forEach(item => {
        areaList.push(cacheAreaData[item]);
      });
    } else {
      return deptList;
    }
    // 组装
    deptList.forEach(item => {
      item.areaAuthList = [];
      areaList.forEach(item1 => {
        if (item.deptId === item1.deptId) {
          item.areaAuthList.push(item1);
        }
      });
    });
    return deptList;
  }
};

const mutations = {
  SET_PREVIOUS_ROUTE(state, params){
    state.previousRoute = params;
  },
  SET_DESC_SET(state, descSetObj) {
    state.descSetObj = descSetObj;
  },
  SET_DESC_INIT(state, descInitObj) {
    state.descInitObj = descInitObj;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token);
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = Object.assign(state.userInfo, userInfo);
    // state.userInfo = userInfo;
  },
  // 设置应用菜单
  SET_MENU_LIST: (state, menuList) => {
    state.menuList = menuList;
  },
  // 设置路由
  SET_ROUTER: (state, params) => {
    state.router = params;
  },
  // 菜单权限 - 显示用
  SET_MENU_AUTH_LIST: (state, params) => {
    state.menuAuthList = params;
  },
  // 设置菜单
  SET_MENU: (state, params) => {
    state.selectionMenuList = params;
  },
  SET_CACHE_DATA: (state, cacheData) => {
    state.cacheData = cacheData;
  },
  // SET_CACHE_SITE_LIST: (state, cacheSiteList) => {
  //   state.cacheSiteList = cacheSiteList;
  // },
  SET_LOGIN_STATE: (state, loginCode) => {
    state.loginCode = loginCode;
    // 保存到本地缓存,防止用户刷新
    sessionStorage.setItem("loginCode", loginCode);
  },
  SET_CACHE_AREA: (state, cacheArea) => {
    state.cacheData.cacheArea = cacheArea;
  },
  SET_CACHE_DEPT: (state, cacheDept) => {
    state.cacheData.cacheDept = cacheDept;
  },
  SET_CACHE_USER: (state, cacheUser) => {
    state.cacheData.cacheUser = cacheUser;
  },
  SET_CACHE_HOSPITAL: (state, cacheHospital) => {
    state.cacheData.cacheHospital = cacheHospital;
  },
  SET_CACHE_BED: (state, cacheBed) => {
    state.cacheData.cacheBed = cacheBed;
  },
  // 位置缓存
  SET_CACHE_SITE: (state, cacheSite) => {
    state.cacheData.cacheSite = cacheSite;
  },
  set_EncryptionName: (state, data) => {
    state.encryptionName = data;
  }
};

function handlepageUrl(str) {
  if (
    str === "undefined" ||
    Object.prototype.toString.call(str).slice(8, -1) === "Undefined"
  ) {
    return "";
  }
  console.log(
    "str",
    str,
    Object.prototype.toString.call(str).slice(8, -1) === "Undefined"
  );

  let reg = /([a-z]+)/g;
  if (reg.test(str)) {
    str = str
      .split(/([a-zA-Z]+)/)
      .map(item => {
        let arr = ["", "-", "/"];
        if (!arr.includes(item)) {
          return item
            .split("")
            .map((item2, index2) => {
              if (index2 === 0) {
                return item2.toUpperCase();
              } else {
                return item2;
              }
            })
            .join("");
        } else {
          return "";
        }
      })
      .join("");
  }
  return str;
}
const actions = {
  // 登录
  login({ dispatch, commit }, userInfo) {
    const { loginId, loginPassword } = userInfo;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let res = await apis.sys.login({
          loginId,
          loginPassword,
          terminalType: 1 // 登录的终端类型 1=PC，2=PDA
        });
        let data = res.data;
        let token = res.data.token;
        console.log("登录信息：", res);
        commit("SET_LOGIN_STATE", res.code);
        commit("SET_USERINFO", data);
        commit("SET_TOKEN", token);
        dispatch("assets/getAssetLabelType", {}, { root: true });
        dispatch("assets/getAssetDescCustomize", {}, { root: true });
        dispatch("fengmap/GET_FLOOR_LIST", {}, { root: true });
        dispatch("assets/getAsseMapSetting", {}, { root: true });
        dispatch("assets/getAssetCameraConfig", {}, { root: true });
        const encryption = await apis.sys.configList({
          cfgCode: 14
        });
        if (encryption && encryption.code === 0 && encryption.data) {
          const { cfgVal } = encryption?.data[0] || {};
          const result = Object.prototype.toString
            .call(cfgVal)
            .replace("object", "")
            .replace("[", "")
            .replace("]", "")
            .replace(" ", "");
          let formation = null;
          if (result === "String") {
            formation = JSON.parse(cfgVal);
          } else if (result === "Object") {
            formation = cfgVal;
          }
          if (formation) {
            commit("set_EncryptionName", formation);
          }
        }
        // 读取字典管理中的配置
        if (process.env.NODE_ENV !== "development") {
          try {
            apis.sys
              .getDictDetail({
                dictType: "SYS_CONFIG"
              })
              .then(data => {
                if (data.data) {
                  const config = JSON.parse(data.data.dictValue);
                  console.log("SYS_CONFIG", config);
                  for (const key in config) {
                    if (config[key]) {
                      commit(`common/set${key}`, config[key], { root: true });
                    }
                  }
                }
              });
            apis.sys
              .getDictDetail({
                dictType: "WS_CONFIG"
              })
              .then(data => {
                if (data.data) {
                  const config = JSON.parse(data.data.dictValue);
                  console.log("WS_CONFIG", config);
                  for (const key in config) {
                    if (config[key]) {
                      Vue.prototype.$CONFIG_ENV.ws[key] = config[key];
                    }
                  }
                }
              });
          } catch (error) {
            console.error("字典管理配置获取失败", error);
          }
        }
        // 调用缓存
        // await dispatch('getCacheAll');
        resolve(res);
      } catch (error) {
        console.error(error);
        message({
          message: error.msg || "error",
          type: "error",
          duration: 3 * 1000
        });
        reject(error);
      }
    });
  },
  // 获取主页信息
  async GET_HOME_INFO({ commit }) {
    const { homeMenu } = await apis.sys.getHomeInfo();
    console.log(homeMenu);
    commit("SET_MENU_LIST", homeMenu || []);
  },
  // 获取应用路由
  async GET_ROUTER({ commit }, appId) {
    try {
      // 用户的应用信息
      const appInfo = await apis.sys.getLoginInfo({
        appId
      });
      commit("SET_USERINFO", appInfo.data);
      // 应用菜单信息
      let { data, code, msg } = await apis.sys.getLoginAppMenu({
        appId
      });
      let obj = {};
      let children = [];
      let menuAuthList = [];
      let topParentId = null;

      // 资产开发临时路由
      if (appId === 1) {
        //   console.log('接口路由', data);

        let datas = [
          {
            menuId: "",
            parentId: "1118",
            menuName: "子任务",
            menuType: 2,
            orderNum: 7,
            zhTw: "",
            enUs: "",
            lastTime: 1694741637000,
            pageUrl: "assets/assetsReview/sub-tasks",
            terminalType: 1,
            appId: 1
          }
          //     {
          //       appId: 1,
          //       lastTime: 1647676050000,
          //       menuId: "1119",
          //       menuName: "资产复盘",
          //       menuType: 1,
          //       orderNum: 2,
          //       pageUrl: "assets/assetsReview",
          //       parentId: "11",
          //       terminalType: 1
          //     },
          //     {
          //       menuId: "110307",
          //       parentId: "1199",
          //       menuName: "资产复盘任务",
          //       menuType: 2,
          //       orderNum: 7,
          //       zhTw: "",
          //       enUs: "",
          //       lastTime: 1694741637000,
          //       pageUrl: "assets/assetsReview/add-review-task",
          //       terminalType: 1,
          //       appId: 1
          //     },
          //     {
          //       menuId: "110307",
          //       parentId: "1120",
          //       menuName: "资产明细对比",
          //       menuType: 2,
          //       orderNum: 7,
          //       zhTw: "",
          //       enUs: "",
          //       lastTime: 1694741637000,
          //       pageUrl: "assets/assetsReview/review-record",
          //       terminalType: 1,
          //       appId: 1
          //     }
        ];
        data = [...data, ...datas];
        //   console.log(data, 'aaaaaaaaaa')
      }
      data.forEach(item => {
        // 菜单权限
        menuAuthList.push(item.menuId);
        // 顶层父类 Id
        if (item.menuType === 0) {
          topParentId = item.menuId;
        }
        if (item.menuType === 0 && item.pageUrl) {
          // 主路由
          obj = {
            path: "/" + item.pageUrl,
            name: item.menuName,
            component: () => import(`@/layout/index.vue`),
            meta: {
              title: item.menuName
            },
            children: []
          };
        } else if (item.menuType !== 0 && item.pageUrl) {
          // 子路由
          children.push({
            path: "/" + item.pageUrl,
            name: item.menuName,
            meta: {
              title: item.menuName,
              icon: item.pageUrl,
              keepAlive: true,
              componentName: handlepageUrl(item.pageUrl)
            },
            component: conversionUrl(item.pageUrl),
            topParentId, // 顶层父类 Id
            menuType: item.menuType, // 菜单类型 - 左边菜单显示
            menuId: item.menuId, // 当前等级 - 面包屑用
            parentId: item.parentId // 父类等级 - 面包屑用
          });
        }
        console.log(children);
      });
      // 更新菜单权限
      commit("SET_MENU_AUTH_LIST", menuAuthList);
      console.log("children", children);
      // 子路由
      obj.children = children;
      router.options.isDynamicRouter = false;
      // 更新缓存路由
      commit("SET_ROUTER", obj);
      // 更新菜单 - selectionMenuList
      commit("SET_MENU", obj);
      // 菜单切换 - 动态路由
      let routerArr = [];
      if (obj.path) {
        routerArr = constantRoutes.concat([obj], {
          path: "*",
          redirect: "/404",
          hidden: true
        });
      } else {
        routerArr = constantRoutes.concat({
          path: "*",
          redirect: "/404",
          hidden: true
        });
      }
      console.log("routerArrrouterArr", routerArr);
      router.addRoutes(routerArr);
      // 父类路由
      let path = obj.path || "/menu";
      if (children.length) {
        path = obj.children[0].path || "/menu";
      }
      return {
        path,
        code,
        msg
      };
    } catch (error) {
      return {
        code: -1,
        msg: "未能找到相关路由"
      };
    }
  },
  // 获取缓存楼层
  async getCacheSite({ commit }) {
    try {
      let { data } = await apis.help.AlarmPointAdministration_querySite();
      commit("SET_CACHE_SITE_LIST", data[0].childList);
    } catch (error) {
      console.error(error);
    }
  },
  async getDescInfo({ commit, getters, state }) {
    try {
      let descInitObj = {};
      let descSetObj = {};
      let { data } = await apis.infu.getConfigDetail({
        patientId: "-1",
        areaId: getters.areaIdListByAuth ? getters.areaIdListByAuth[0] : -1
      });
      if (data) {
        // 默认文案
        descInitObj.deptInitDesc = data.deptInitDesc || "科室";
        descInitObj.areaInitDesc = data.areaInitDesc || "病区";
        descInitObj.bedSeatInitDesc = data.bedSeatInitDesc || "床位";
        descInitObj.bedNoInitDesc = data.bedNoInitDesc || "床号";
        descInitObj.bedNoInitDesc = data.bedNoInitDesc || "床";
        descInitObj.hospitalNoInitDesc = data.hospitalNoInitDesc || "住院号";
        // 修改文案
        descSetObj.deptDesc = data.deptDesc || "";
        descSetObj.areaDesc = data.areaDesc || "";
        descSetObj.bedSeatDesc = data.bedSeatDesc || "";
        descSetObj.bedNoDesc = data.bedNoDesc || "";
        descSetObj.bedDesc = data.bedDesc || "";
        descSetObj.hospitalNoDesc = data.hospitalNoDesc || "";
        commit("SET_DESC_SET", descSetObj);
        commit("SET_DESC_INIT", descInitObj);
        console.log(
          state.descInitObj,
          state.descSetObj,
          "descInitObj descSetObj----缓存更新成功"
        );
      }
    } catch (error) {
      console.error(error);
    }
  },
  // 获取缓存数据
  getCacheAll({ commit }) {
    // 病床分页
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let obj = {
          cacheArea: [],
          cacheDept: [],
          cacheBed: [],
          cacheUser: [],
          cacheHospital: []
        };

        let arr = [];
        let totalPage = 0;
        let currPage = 0;
        let limit = 100;

        do {
          currPage++;
          let res = await apis.basi.getCacheAll({
            page: currPage.toString(),
            limit: limit.toString()
          });
          let data = res.data;
          if (currPage.toString() === "1") {
            console.log("!!赋值");
            obj.cacheArea = data.cacheArea;
            obj.cacheDept = data.cacheDept;
            obj.cacheUser = data.cacheUser;
            obj.cacheHospital = data.cacheHospital;
            obj.cacheSite = data.cacheSite; // 缓存楼层信息
            // 从缓存拿fmMapOpts配置
            if (data.cacheHospital && data.cacheHospital.length > 0) {
              const hospitalData = data.cacheHospital.find(
                i => i.hospitalId === -1
              );
              const {
                appKey: key = "",
                appName = "",
                buildId = "",
                mapId: fmapID = ""
              } = hospitalData;
              if (key && buildId && appName) {
                const fmMapOpts = { key, appName, buildId, fmapID };
                commit("common/setFmMapOpts", fmMapOpts, { root: true });
              }
            }
          }
          arr.push(...data.cacheBed.list);

          totalPage = data.cacheBed.totalPage;
          currPage = data.cacheBed.currPage;
        } while (currPage < totalPage);

        obj.cacheBed = arr;
        console.log("缓存数据：", obj);
        commit("SET_CACHE_DATA", obj);
        resolve(obj);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  async getCacheArea({ commit }) {
    // 病区缓存
    try {
      let res = await apis.basi.getCacheArea();
      let arr = res.data && res.data.length ? res.data : [];
      commit("SET_CACHE_AREA", arr);
    } catch (error) {
      console.error(error);
    }
  },
  async getCacheDept({ commit }) {
    // 科室缓存
    try {
      let res = await apis.basi.getCacheDept();
      let arr = res.data && res.data.length ? res.data : [];
      commit("SET_CACHE_DEPT", arr);
    } catch (error) {
      console.error(error);
    }
  },
  async getCacheUser({ commit }) {
    try {
      // 用户信息缓存
      let arr = [];
      let totalPage = 0;
      let currPage = 0;
      let limit = 1000;
      do {
        currPage++;
        let res = await apis.basi.getCacheUser({
          page: currPage.toString(),
          limit: limit.toString()
        });
        let data = res.data;
        arr.push(...data.list);

        totalPage = data.totalPage;
        currPage = data.currPage;
      } while (currPage < totalPage);
      commit("SET_CACHE_USER", arr);
    } catch (error) {
      console.error(error);
    }
  },
  async getCacheHospital({ commit }) {
    // 院区缓存
    try {
      let res = await apis.basi.getCacheHospital();
      let arr = res.data && res.data.length ? res.data : [];
      commit("SET_CACHE_HOSPITAL", arr);
    } catch (error) {
      console.error(error);
    }
  },
  async getCacheBed({ commit }) {
    try {
      // 床位缓存
      let arr = [];
      let totalPage = 0;
      let currPage = 0;
      let limit = 2000;
      do {
        currPage++;
        let res = await apis.basi.getCacheBed({
          page: currPage.toString(),
          limit: limit.toString()
        });
        let data = res.data;
        arr.push(...data.list);

        totalPage = data.totalPage;
        currPage = data.currPage;
      } while (currPage < totalPage);
      commit("SET_CACHE_BED", arr);
    } catch (error) {
      console.error(error);
    }
  },

  // 登录后的位置缓存
  async getCacheSiteList({ commit }) {
    try {
      let res = await apis.basi.getCacheSite();
      let arr = res.data && res.data.length ? res.data : [];
      commit("SET_CACHE_SITE", arr);
    } catch (error) {
      console.error(error);
    }
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
      // 清空所有缓存
      const happenedPatientNoticeList = localStorage.getItem(
        "happenedPatientNoticeList"
      );
      localStorage.clear();
      happenedPatientNoticeList &&
        localStorage.setItem(
          "happenedPatientNoticeList",
          happenedPatientNoticeList
        );
    });
  },
  // 获取下拉列表数据
  async getDataList() {
    console.log("@@@@@@@@@@@@@@@@@@@**********&&&&&&&&&&&&");
    let data = await this.$multipleReq("getTypeList", 328, "limit", "page");
    if (data.length > 0) {
      console.log(data, "!!!~!!!!!");
      return data;
    } else {
      this.dataList = [];
    }
  },
  // 获取院方信息
  async getCustomerInfo() {
    try {
      // 获取院方信息
      // let data = await apis.sys.getcourtyardInfo({});
      // let res = data.data;
      // if (res && res.length > 0) {
      //   let temp = {
      //     name: res[0].hospitalName || window.SITE_CONFIG.defaultCustomerInfo.name,
      //     logo: res[0].image || window.SITE_CONFIG.defaultCustomerInfo.logo,
      //     version: res[0].version || window.SITE_CONFIG.defaultCustomerInfo.version,
      //     logoLeftTop: res[0].logoLeftTop,
      //     copyright: res[0].copyright

      //   };
      //   sessionStorage.setItem('CUSTOMER_INFO', JSON.stringify(temp));
      // } else {
      //   sessionStorage.setItem('CUSTOMER_INFO', JSON.stringify(window.SITE_CONFIG.defaultCustomerInfo));
      // }
      // console.log('获取院方信息成功:', res);
      // return res;

      let data = await apis.sys.hospitalDetail({
        hospitalId: -1 // 院区ID  登录首页参数为-1
      });
      let res = data.data;
      if (res) {
        console.log("res", res);
        let temp = {
          name: res.hospitalName || window.SITE_CONFIG.defaultCustomerInfo.name,
          logo: res.logoPath || window.SITE_CONFIG.defaultCustomerInfo.logo,
          version:
            res.version || window.SITE_CONFIG.defaultCustomerInfo.version,
          logoLeftTop: res.logoPath,
          copyright: res.hospitalName
        };
        sessionStorage.setItem("CUSTOMER_INFO", JSON.stringify(temp));
      } else {
        sessionStorage.setItem(
          "CUSTOMER_INFO",
          JSON.stringify(window.SITE_CONFIG.defaultCustomerInfo)
        );
      }
      return [
        {
          hospitalName:
            res.hospitalName || window.SITE_CONFIG.defaultCustomerInfo.name,
          image: res.logoPath || window.SITE_CONFIG.defaultCustomerInfo.logo,
          version:
            res.version || window.SITE_CONFIG.defaultCustomerInfo.version,
          logoLeftTop: res.logoPath,
          copyright: res.hospitalName
        }
      ];
    } catch (error) {
      console.error("获取院方信息报错:", error);
      sessionStorage.setItem(
        "CUSTOMER_INFO",
        JSON.stringify(window.SITE_CONFIG.defaultCustomerInfo)
      );
      return [];
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
