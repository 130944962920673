import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/infu';
  return basic_url + url;
}
function INFO_URL (url) {
  let info_url = '/api/third/infu';
  return info_url + url;
}

const infuAPI = {
  // 配置管理
  getConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/detail'), method: 'post', data }, option));
  },
  // 配置更新（全局）
  configUpdateGlobal (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/updateGlobal'), method: 'post', data }, option));
  },
  // 配置更新（针对病患）
  configUpdatePerson (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/update'), method: 'post', data }, option));
  },
  // 报警记录(分页)
  getAlarmListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarm/listPage'), method: 'post', data }, option));
  },
  // 报警记录(不分页)
  getAlarmList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarm/list'), method: 'post', data }, option));
  },
  // 处理报警
  alarmUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarm/update'), method: 'post', data }, option));
  },
  // 处理历史报警
  updateHisRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarm/updateHisRecord'), method: 'post', data }, option));
  },
  // 操作记录
  getLog (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/log/listPage'), method: 'post', data }, option));
  },
  // 汇总统计概要
  getStatisticsSummary (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/busi/statistics/summary'), method: 'post', data }, option));
  },
  // 获取报警统计
  getStatisticsAlarm (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/busi/statistics/alarm'), method: 'post', data }, option));
  },
  // 获取报警统计
  getStatisticsMedicine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/busi/statistics/medicine'), method: 'post', data }, option));
  },
  // 获取病区处理项目统计
  getStatisticsAreaProcess (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/busi/statistics/areaProcess'), method: 'post', data }, option));
  },
  // 获取个人处理项目统计
  getStatisticsUserProcess (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/busi/statistics/userProcess'), method: 'post', data }, option));
  },
  // 输液管理页面
  // 首页列表
  getInfusionList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/infusion/list'), method: 'post', data }, option));
  },
  // 设置备忘
  setMemo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/memo/setting'), method: 'post', data }, option));
  },
  // 关闭备忘
  setMemoVis (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/memo/close'), method: 'post', data }, option));
  },
  // 查询病患详情
  getFindPatientInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/findPatientInfo'), method: 'post', data }, option));
  },
  // 输液详情
  getQueryByMedicineId (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/record/queryByMedicineId'), method: 'post', data }, option));
  },
  // 设备是否被使用
  getMedicineIsUseDevi (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/isUseDevi'), method: 'post', data }, option));
  },
  // 根据病区查询位置列表
  getlistAreaBuild (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/infusion/listAreaBuild'), method: 'post', data }, option));
  },

  // 床位列表分页查询
  getTutelageBindListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/listPage'), method: 'post', data }, option));
  },
  // 床位列表查询
  getTutelageBindList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/list'), method: 'post', data }, option));
  },
  // 新增绑定
  addTutelageBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/add'), method: 'post', data }, option));
  },
  // 修改绑定
  updateTutelageBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/update'), method: 'post', data }, option));
  },
  // 删除绑定
  deleteTutelageBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/delete'), method: 'post', data }, option));
  },
  // 监护设备分页
  getDeviListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/tutelageBind/deviListPage'), method: 'post', data }, option));
  },
  // 用药详情分页
  getInfuMedicine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/listPage'), method: 'post', data }, option));
  },
  // 获取用药详情子类
  getInfuMedicineChildren (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/list'), method: 'post', data }, option));
  },
  // 获取容量调整列表
  getMedicineCapacity (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicineCapacity/listPage'), method: 'post', data }, option));
  },
  // 获取报警文案设置列表
  getAlarmConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmConfig/list'), method: 'post', data }, option));
  },
  // 保存报警文案设置
  updateAlarmConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmConfig/update'), method: 'post', data }, option));
  },
  // 查询详细信息 - 列表
  queryMedicineRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/queryMedicineRecord'), method: 'post', data }, option));
  },
  // 导出详细信息
  exportMedicineRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/exportMedicineRecord'), method: 'post', data }, option));
  },
  // 根据住院号获取输液信息   更换=INFO_URL
  integrateGetTutelageByArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + INFO_URL('/integrate/getTutelageByArea'), method: 'post', data }, option));
  },
  // 查询单个病患正使用的终端接口
  queryIsUseDeviByPatientId (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/record/queryIsUseDeviByPatientId'), method: 'post', data }, option));
  },
  // 编辑病患信息
  infusionEdit (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/infusion/edit'), method: 'post', data }, option));
  },
  // 添加病患信息
  infusionAddPatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/infusion/addPatient'), method: 'post', data }, option));
  },
  // 病患 - 出院
  infusionOutHospital (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/infusion/outHospital'), method: 'post', data }, option));
  },
  // 特殊溶液
  infuSpecialList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/maintain/listPage'), method: 'post', data }, option));
  },
  // 特殊溶液新政
  infuSpecialAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/maintain/add'), method: 'post', data }, option));
  },
  // 特殊溶液编辑
  infuSpecialEdit (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/maintain/update'), method: 'post', data }, option));
  },
  // 删除特殊溶液
  infuSpecialDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/maintain/delete'), method: 'post', data }, option));
  },
  // 输液看板
  getBoardList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/list'), method: 'post', data }, option));
  },
   // 输液看板-uodate http://showdoc.xinsec.com.cn/web/#/83/5654
   updateBoardItem (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/updateBatch'), method: 'post', data }, option));
  },
  // 结束输液
  stopInfu (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/medicine/changeStatus'), method: 'post', data }, option));
  }

};

export default infuAPI;
