<template>
  <div class="list-table">
    <el-table
      lazy
      :row-key="rowKey"
      :load="load"
      :data="data"
      :stripe="stripe"
      :border="border"
      :height="tableHeight"
      :tree-props="treeProps"
      :span-method="spanMethod"
      :default-expand-all="defaultExpandAll"
      :header-cell-style="Object.assign({
        height: '56px',
        color: '#333',
        'background-color': '#F7F8FA'
      }, headerStyle)"
      :cell-style="Object.assign({
        height: '56px'
      }, cellStyle)"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <slot />
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    // 渲染树形数据时 - 必须
    rowKey: {
      type: String,
      default: ''
    },
    // 树类型字段定义
    treeProps: {
      type: Object,
      default: () => {}
    },
    // 数据数组
    data: {
      type: Array,
      default: () => []
    },
    // 表格高度
    height: {
      type: [String, Number],
      default: ''
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: false
    },
    // 是否斑马纹显示,默认不显示
    stripe: {
      type: Boolean,
      default: false
    },
    // 表头样式
    headerStyle: {
      type: Object,
      default: () => {}
    },
    // 列表样式
    cellStyle: {
      type: Object,
      default: () => {}
    },
    // 合并行或列的计算方法字段定义
    spanMethod: {
      type: Function,
      default: () => {}
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHeight: null
    }
  },
  watch: {
    height (val) {
      if (val) {
        this.tableHeight = val
      }
    }
  },
  methods: {
    // 需要加载 - 树形数据
    load (tree, treeNode, resolve) {
      this.$emit('load', tree, treeNode, resolve)
    },
    // 排序 - 事件
    sortChange (val) {
      this.$emit('sortChange', val);
    },
    // 排序 - 事件
    handleSelectionChange (val) {
      this.$emit('handleSelectionChange', val);
    }
  }
}
</script>

<style lang="scss">
  .list-table {
    .btn {
      cursor: pointer;
      color: #00CD96;
      margin-right: 16px;
    }
    /deep/ .el-table th.el-table__cell>.cell {
      padding-left: 16px;
      padding-right: 16px;
    }
    /deep/ .el-table {
      .cell {
        padding-left: 16px;
        padding-right: 16px;
      }
      .cell:empty::before {
        content:"-";
      }
    }
  }
</style>
