<template>
  <el-tooltip effect="dark" :content="tooltip" :placement="placement" :disabled="disabled">
    <el-button :type="type" :class="buttonClass">
      <i v-if="icon" class="iconsprite" :class="icon"></i>
      <span class="btn-text">
        <slot></slot>
      </span>
    </el-button>
  </el-tooltip>
</template>

<script>
import Vue from 'vue';
import { Popover } from 'element-ui';
Vue.use(Popover);
export default {
  props: {
    type: {
      type: String,
      required: false
    },
    // 图标
    icon: {
      type: String,
      required: false,
      default: ''
    },
    // 提示文字
    tooltip: {
      type: String,
      required: true
    },
    // 是否永远显示按钮文字
    alwaysShow: {
      type: Boolean,
      required: false
    },
    // 是否永远隐藏按钮文字
    alwaysHide: {
      type: Boolean,
      required: false
    },
    // 提示文字显示位置
    placement: {
      type: String,
      required: false,
      default: 'bottom'
    }
  },
  computed: {
    clientWidth () {
      return this.$store.state.common.clientWidth;
    },
    minWidth () {
      return this.$store.state.common.minWidth;
    },
    disabled () {
      return !(!this.alwaysShow && !this.alwaysHide && this.clientWidth <= this.minWidth);
    },
    buttonClass () {
      let _class = '';
      if (this.alwaysShow) {
        _class = 'always-show-text';
      } else if (this.alwaysHide) {
        _class = 'always-hide-text';
      }
      return _class;
    }
  }
};
</script>

<style lang="scss">
.btn-text {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
}
</style>
