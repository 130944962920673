import twLocale from 'element-ui/lib/locale/lang/zh-TW';
const fn = {
  // 导航栏
  navBar: {
    logout: '退出登錄',
    languageSet: '語言設置',
    ChangePassword: '修改密碼'
  },
  // 环境
  config: {
    // devTitle: '廣州醫科大學附屬第二醫院醫療物聯網應用系統',
    // devTitle: '陽江市人民醫院醫療物聯網應用系統',
    devTitle: '肇慶市第壹人民醫院 醫療物聯網應用系統',
    devLoginTitle: '醫療物聯網應用平臺'
  },
  // 登录
  login: {
    logIn: '登 錄',
    username: '賬號',
    enterUsername: '請輸入用戶名',
    password: '請輸入密码',
    usernameEmpty: '帳號不能為空',
    passwordEmpty: '密碼不能為空',
    versionNumber: '版本號',
    logging: '登錄中',
    copyright: '廣東信尚安物聯科技有限公司版權所有'
  },
  // 常见使用
  common: {
    disable: '禁用',
    normal: '正常',
    sysSet: '系統設置',
    homePage: '首頁',
    second: '秒',
    min: '分钟',
    bed: '床',
    BedDictionary: '床位字典',
    edit: '編輯',
    other: '其他',
    hint: '提示',
    left: '左',
    right: '右',
    man: '男',
    woman: '女',
    unknown: '未知',
    none: '沒有',
    have: '有',
    inHos: '在院',
    outHos: '離院',
    cancel: '取 消',
    confirm: '確 定',
    all: '全部',
    name: '姓名',
    sex: '性別',
    age: '年齡',
    phone: '電話',
    nowStatus: '當前狀態',
    hosNum: '住院號',
    bedNum: '床號',
    listMode: '列表模式',
    bedMode: '床位模式',
    inpatientWard: '病區',
    selectKeyword: '請輸入關鍵字搜索',
    status: '狀態',
    selectStatus: '請選擇狀態',
    isbinding: '已綁定',
    notBound: '未綁定',
    isbind: '已綁',
    change: '更換',
    operation: '操作',
    device: '設備',
    deviceRecord: '設備記錄',
    detail: '詳情',
    changeDevice: '更換設備',
    bindingDevice: '綁定設備',
    distributionEquipment: '分配設備',
    bindingSuccess: '綁定成功',
    changebindSuccess: '更換成功',
    unbindSuccess: '解綁成功',
    fiveMin: '5 分鐘後',
    tenMin: '10 分鐘後',
    thirtyMin: '30 分鐘後',
    minLater: '分鐘後',
    pleaseInput: '請輸入',
    pleaseSelect: '請選擇',
    newSuccess: '新建成功',
    remain: '剩余',
    residue: '剩余量',
    speed: '速度',
    total: '總量',
    type: '類型',
    selectType: '請選擇類型',
    unconfirmed: '未確認',
    confirmed: '已確認',
    alarmType: '報警類型',
    alarmInfo: '報警信息',
    alarmTime: '報警時間',
    confirmTime: '確認時間',
    check: '查看',
    new: '新增',
    leastValue: '最小值',
    maxValue: '最大值',
    createTime: '創建時間',
    updateTime: '更新時間',
    remark: '備註',
    del: '刪除',
    close: '關閉',
    open: '開啟',
    minute: '分鐘',
    save: '保存',
    custom: '自定義',
    default: '默認',
    next: '次',
    formatNum: '格式錯誤，僅限數字',
    tenChar: '只能輸入十個字符',
    delSuccess: '刪除成功',
    saveSuccess: '保存成功',
    editSuccess: '修改成功',
    cancelledDelete: '已取消刪除',
    deviceType: '設備類型',
    bindingState: '綁定狀態',
    divideState: '分配狀態',
    useState: '使用狀態',
    equipment: '設備',
    selectDeviceType: '請選擇設備類型',
    enterSearchKeyword: '請輸入搜索關鍵字',
    pptDowload: '模板下載',
    bulkImport: '批量導入',
    binding: '綁定',
    online: '在線',
    outline: '離線',
    deviceAbnormal: '設備異常',
    template: '模板',
    errorDataExport: '導出數據出錯',
    sureDelDevice: '確定刪除此設備?',
    enterDeviceID: '請輸入設備ID',
    clickUpload: '點擊上傳',
    selectFile: '請選擇文件',
    importSuccess: '批量導入成功',
    uploadMost: '最多只能上傳',
    afile: '個文件',
    selectBindState: '請選擇綁定狀態',
    departments: '科室',
    selectSection: '請選擇科室',
    area: '病區',
    WardName: '病區名稱',
    areaDictName: '病區字典',
    WardCode: '病區编碼',
    selectArea: '請選擇病區(支持搜索)',
    selectAreaTips: '請選擇病區',
    adf: '所屬科室',
    roomNumber: '房號',
    bedNo: '床號',
    bedNumber: '床位數',
    changeBind: '更綁',
    unbind: '解綁',
    relieve: '解除',
    replace: '更換',
    deleteCurrentBed: '確定刪除當前床位?',
    unbindCurrentDevice: '確定解綁此設備?',
    changeBinding: '更改綁定',
    changeDistribution: '更改分配',
    distribution: '分配',
    AllocationStatus: '分配狀態',
    selectBindType: '請選擇綁定類型',
    changeBindSuccess: '更綁成功',
    selsectRoomNum: '請選擇房號',
    enterRoomNum: '請輸入房號',
    selsectBedNum: '請選擇床號',
    enterBedNum: '請輸入床號',
    messageRemind: '消息提醒',
    affirm: '確認',
    affirmSuccess: '確認成功',
    remindTimeOver: '提醒時間到',
    processed: '已處理',
    unprocessed: '未處理',
    viewed: '已查看',
    transboundaryAlarm: '越界報警',
    oneButtonAlarm: '一鍵報警',
    dismantlTheAlarm: '拆除報警',
    cancelHelp: '取消求助',
    level: '等級',
    class: '級',
    getInto: '進入',
    leave: '離開',
    processingTime: '處理時間',
    handle: '處理',
    year: '年',
    month: '月',
    reqERR: '請求錯誤',
    for: '對',
    deleting: '進行刪除',
    deleting1: '進行徹底刪除',
    modify: '修改',
    operationS: '操作成功',
    gotIt: '知道了',
    optionDate: '選擇日期',
    startTime: '開始時間',
    endTime: '結束時間',
    selectTimeRange: '選擇時間範圍',
    floor: '樓層',
    tower: '樓',
    stop: '停止',
    ward: '病房',
    hosBed: '病床',
    success: '成功',
    admissionTime: '入院時間',
    outHosTime: '離院時間',
    deviceName: '設備名稱',
    coord: '坐標',
    address: '地址',
    importFromTemplate: '從模板中導入',
    No: '序號',
    with: '與',
    shouldBluetoothBracelet: '將藍牙手環',
    viewLocation: '查看位置',
    OneKeyProcessing: '一鍵處理',
    closedAndProcessed: '關閉並已處理',
    AlarmLevel: '報警等級',
    handleSuccess: '處理成功',
    alarmTips: '報警音已關閉，是否需要開啟？',
    yes: '是',
    no: '否',
    search: '搜索',
    selectDateTips: '請選擇日期',
    selectEndTimeTips: '請選擇結束時間',
    processInfo: '處理信息',
    cantSeeMapTips: '無法查看地圖位置',
    screen: '篩選',
    years: '歲',
    editProfile: '修改資料',
    forDetails: '查詢詳情',
    hardLoading: '努力加載中',
    noData: '暫無數據',
    add: '添加',
    inputName: '請輸入姓名',
    selectDateTime: '選擇日期時間',
    endTimeCannotEmpty: '結束時間不能為空',
    timeFormatNotCorrect: '時間格式不正確',
    chooseEarlierThan: '選擇的時間不能早於',
    patientIDcannotEmpty: '患者ID不能為空',
    category: '類別',
    noDataFound: '沒有找到數據',
    nothing: '無',
    grade: '評分',
    updateFailedTips: '更新失敗，請重試',
    activated: '已激活',
    AlarmFromHospital: '離院報警',

    StartDate: '起始日期',
    AllOneClickProcessing: '全部壹鍵處理',
    historicalInformation: '歷史消息',
    syncSuccess: '同步成功',
    strip: '條',
    Binding: '綁定情況',
    bedID: '床墊ID',
    enterSearchBedNum: '請輸入床號搜索',
    Bed: '床墊',
    NewBed: '新增床墊',
    Assigned: '已分配',
    Unassigned: '未分配',
    ItisBottom: '已經最底了',
    ReleasedSuccessfully: '解除成功',
    AssignmentSuccessful: '绑定成功',
    TipsInfo: '提醒信息',
    DirectProcessing: '直接處理',
    ProcessAndComment: '處理並備註',
    alarmLocation: '報警位置',
    NoNews: '暫無消息',
    NoprocessNews: '歷史未處理消息',
    DealAll: '全部處理',
    choice: '選擇',
    addName: '新增名單',
    Scheduling: '排班',
    Reporting: '上報',
    amount: '數量',
    retry: '重試',
    detailed: '明細',
    determine: '確定',
    RealTimeLocation: '實時位置',
    date: '日期',
    TipsTime: '提醒時間',
    function: '功能',
    selectRegion: '请选择区域'
  },

  // 语言
  language: {
    tips: '切換語言成功'
  },
  // home页
  home: {
    title: '歡迎使用信尚安物聯網系統',
    // title: '歡迎使用智能輸液系統',
    loadingText: '拼命加載中',
    clickLeft: '請點擊左側菜單欄進行相關操作'
  },
  // 智能输液
  transfusion: {
    inTheInfusion: '輸液中',
    stopInfu: '暫停輸液',
    infusionInsuf: '余量不足',
    infusionOut: '即將輸完',
    weightAbnormal: '重量異常',
    deviceOffline: '設備離線',
    infusionOver: '藥液輸完',
    aneroidAlarm: '無液報警',
    speedAnomalies: '滴速異常',
    infusionJam: '輸液堵塞',
    speedToFast: '滴速過快',
    speedToSlow: '滴速過慢',
    batteryLow: '電量不足',
    batteryAmple: '電量充足',
    lowBattery: '低電量',
    callHelp: '呼叫求助',
    sort: '排序顯示',
    bedSort: '床號排序',
    batterySort: '電量排序',
    marginSort: '余量排序',
    endSort: '預計時間排序',
    expectedEnd: '預計結束',
    liquidMargin: '液體余量',
    electricQuantity: '電量',
    timeRemind: '計時提醒',
    marginRemind: '余量提醒',
    memo: '備忘',
    memorandum: '備忘錄',
    margin: '余量',
    infusionTerminal: '輸液終端',
    selsetTerminal: '請選擇輸液終端ID',
    wirelessPager: '無線呼叫器',
    selectWireless: '請選擇無線呼叫器ID',
    unbindTerminal: '解綁輸液終端',
    unbindWireless: '解綁無線呼叫器',
    unbindTerminalText: '解除綁定則無法實時監測到輸液進展，請謹慎操作！',
    isCheckUnbindTerminal: '是否解除終端綁定？',
    unbindWirelessText: '解除綁定則患者無法求助，請謹慎操作！',
    isCheckUnbindWireless: '是否解除無線呼叫器綁定？',
    remindWay: '提醒方式',
    customContent: '自定義內容',
    residualFluidVolume: '剩余液量',
    editContent: '提示內容',
    tipsTime: '提醒時間',
    enterTipsTime: '請輸入提醒時間',
    remindConditions: '提醒條件',
    closeRemind: '關閉提醒',
    selectRemindTime: '請選擇提醒時間',
    enterMargin: '請輸入余量',
    closeRemindSuccess: '關閉提醒成功',
    closeRemindText: '是否關閉當前備忘提醒的內容？',
    medicalInfo: '用藥信息',
    criticalCare: '特級護理',
    docAdvice: '醫囑號',
    drugNames: '藥品名稱',
    usage: '用法',
    HZ: '頻率',
    ST: '皮試',
    openToldTime: '開囑時間',
    executionTime: '執行時間',
    collator: '核對人',
    executor: '執行人',
    infuDtails: '輸液詳情',
    infuDetector: '輸液終端',
    deviceNum: '設備ID',
    BindBedNo: '綁定床號',
    tareWeightSet: '皮重設置',
    spec: '規格',
    selectSpec: '請選擇規格',
    nominalCap: '標準容量',
    selectNominalCap: '請選擇容量',
    tare: '皮重',
    thresholdManage: '閾值管理',
    dripNum: '點滴系數',
    inputDripNum: '請輸入點滴系數',
    selectDripNum: '請選擇點滴系數',
    inputDripText: '每毫升溶液的滴數為點滴系數',
    dripSpeed: '輸液滴速',
    enterMinDrip: '請輸入最小滴速',
    until: '至',
    enterMaxDrip: '請輸入最大滴速',
    dropAndmin: '滴 / 分',
    otherSet: '其他設置',
    timeRemain: '剩余時間',
    inputTimeRemain: '請輸入剩余時間',
    liquidRatio: '剩余液量占比',
    inputLiquidRatio: '請輸入剩余液量占比',
    selectUnit: '請選擇單位',
    ReportInter: '上報間隔',
    TerminalInter: '終端數據上報間隔',
    enterMinNum: '請輸入最小數',
    selectFrequency: '請選擇頻次',
    fiveSecondsFitSug: '建議',
    fiveSecondsFitApr: '為宜',
    record: '註: 建議以默認時間為準，如若需自定義建議咨詢管理員',
    typeSet: '類型設置',
    oneself: '自己',
    thirdParty: '第三方',
    selectReminder: '請選擇提醒方式',
    inputMinRate: '請輸入滴速最小值',
    inputMaxRate: '請輸入滴速最大值',
    inputUnit: '請輸入單位',
    removeSet: '確定刪除此設置？',
    dropText: '最大滴速不能小於或等於最小滴速',
    pleaseSupplement: '請補充輸液滴速的起始範圍',
    inputNominalCap: '請輸入標準容量',
    inputTareWeight: '請輸入皮重重量',
    enterTareMax: '請輸入皮重最大值',
    enterTareMin: '請輸入皮重最小值',
    enterRemarks: '請輸入備註，50字內',
    content50Char: '內容已經超過50個字符',
    content16Char: '內容已經超過16個字符',
    enterCapacity: '請輸入容量',
    enterTareWeight: '請輸入皮重',
    enterMinValue: '請輸入最小值',
    enterMaxValue: '請輸入最大值',
    terminalExport: '輸液管理-終端設備導出',
    terminalImport: '輸液終端批量導入',
    wirelessImport: '無線呼叫器批量導入',
    bedBatchImport: '床位批量導入',
    terminalBindingStatus: '輸液終端綁定狀態',
    wirelessBindingStatus: '無線呼叫器綁定狀態',
    delFailedText: '對不起，刪除失敗',
    bindDeviceText: '當前床位存在綁定設備，請解綁後再重新操作',
    selectInfoTerminal: '請選擇輸液終端',
    selectInfoWireless: '請選擇無線呼叫器',
    addinfuChannel: '添加輸液通道',
    deviceBallast: '設備空載',
    pleaseBindTerminal: '請綁定輸液終端',
    lightOff: '空載關機',
    ReportInterTipM: '提交失敗，錄入內容需不低於2分鐘',
    ReportInterTipS: '提交失敗，錄入內容需不低於120秒',
    setAddFailTip: '提交失敗，最小值不可大於最大值，請修改後重試',
    setAddFailTipT: '提交失敗，最大值不可小於皮重，請修改後重試',
    positive: '陽性',
    feminine: '陰性',
    dropMinute: '滴/分',
    reminderMethod: '提醒方式',
    countDrops: '計算滴速',
    enterDrops: '請輸入計算滴速',
    enterRange: '可輸入的數值範圍',
    PreVerification: '預分配校驗',
    VerificationFailure: '校驗失敗可臨時使用',
    VerificationFailure1: '驗失敗不可臨時使用',
    ThirdPartyCallers: '第三方呼叫器',
    DropRateDisplay: '滴速顯示',
    OnlyEntered: '只能輸入30-60之間的正整數',
    OnlyEntered1: '只能輸入1-25之間的正整數',
    ToBeInfused: '待輸液',
    treatmentCount: '病區處理項目統計',
    individualCount: '個人處理項目統計',
    selectPerson: '請選擇具體人員',
    CurrentInfusion: '當前輸液人數',
    todayInfusion: '今日輸液人數',
    todayAlarm: '今日報警次數',
    todayCall: '今日呼叫次數',
    currentTerminalPow: '當前輸液終端低電量數',
    currentWirelessPow: '當前無線呼叫器低電量數',
    AlarmTypeCount: '報警類型佔比統計',
    infusionEndTypeCount: '輸液結束類型佔比統計',
    AlarmProcessingTimes: '報警處理次數',
    callHelpHandlingTime: '呼叫求助處理次數',
    infusionOperaHandlingTime: '輸液操作處理次數',
    frequency: '次数',
    CallForHelp: '呼叫求助報警',
    speedAlarm: '滴速異常報警',
    MemoAlarm: '備忘錄報警',
    infuBlockageAlarm: '輸液堵塞',
    fastSpeedAlarm: '滴速過快報警',
    slowSpeedAlarm: '滴速過慢報警',
    PDAEnd: 'PDA結束輸液關機',
    btnShowdown: '物理按鈕關機',
    AutoShutdown: '自動關機',
    BuiltInLogo: '內置標識',
    CustomIdentity: '自定義標識',
    enterRFID: '請輸入內置無緣RFID標籤ID',
    enterNum: '請輸入編號',
    sixLength: '長度在 1 到 6 個字符',
    fiftyLength: '長度在 1 到 50 個字符',
    CustomNumber: '自定義編號',
    DropRate: '滴速',
    bedPosition: '床位位置',
    closeAdvance: '收起高級搜索',
    Advance: '高級搜索',
    ManualConfirmation: '人工確認',
    SystemValidation: '系統確認',
    patientsNameShow: '病患名显示',
    desensitization: '脱敏',
    Nodesensitization: '无使脱敏',
    drugSearch: '藥品搜索'
  },
  // 病患管理
  care: {
    sendHelp: '發出一鍵求助',
    wristbandRemoved: '配帶的手環被拆除',
    CancelHelp: '取消一鍵求助',
    Explain: '處理說明',
    statisticalMonth: '統計月份',
    chooseYM: '選擇年月',
    patientPopulation: '病患人數',
    people: '人',
    Nostatistical: '暫無統計數據',
    SummaryFigures: '人數匯總',
    searchName: '請輸入姓名搜索',
    checkOut: '臨時簽出',
    signOut: '簽出',
    signIn: '簽入',
    checkIn: '已簽入',
    outTime: '簽出時間',
    inTime: '簽入時間',
    outOperator: '簽出操作人',
    inOperator: '簽入操作人',
    locateFailureTips: '定位失敗！該資產未綁定藍牙定位標簽，無法定位',
    locateFailureTips2: '定位失敗！請檢查是否設備異常或網絡斷開！',
    patientsName: '病患姓名',
    cancelTrack: '取消追蹤',
    realtimeTrack: '實時追蹤',
    historicalTrack: '歷史軌跡',
    queryTrajectory: '查詢軌跡',
    TrajectoryQuery: '軌跡查詢',
    trackList: '軌跡列表',
    showTrajectories: '顯示軌跡',
    enterPatientName: '請輸入病患姓名',
    checkTrajectoryTips: '查詢的軌跡可能包含其他患病軌跡，該病患的歷史軌跡應在',
    timeRange: '時間範圍內',
    bracelet: '手環',
    location: '定位',
    braceletSet: '手環設置',
    record: '記錄',
    heartRate: '心率',
    willPatient: '將病患',
    inHosRecords: '住院記錄',
    hosSerialNum: '住院流水號',
    elapsedTime: '經過時間',
    operationalContext: '操作內容',
    fornowTips: '暫時沒有操作記錄',
    admittedHos: '入院',
    leaveHos: '出院',
    changeMajor: '轉科',
    penName: '圍欄名稱',
    intoAlarm: '進入報警',
    departure: '離開報警',
    checkFencePosition: '查看圍欄位置',
    enterBraceletLabel: '請輸入手環標簽',
    selectBraceletType: '請選擇手環類型',
    alarmFence: '報警圍欄',
    unbindBracelet: '解綁手環',
    selectBraceletLabel: '請選擇手環標簽',
    fenceAlarm: '圍欄報警',
    oneKeyHelp: '壹鍵求助',
    braceletWasRemoved: '佩戴的手環被拆除',
    callForHelp: '發出壹鍵求助',
    cancelForHelp: '取消壹鍵求助',
    crossBorder: '越界',
    retention: '滯留',
    levelFence: '圍欄等級',
    levelSet: '等級設置',
    fenceLevelSet: '圍欄等級設置',
    ecgPasteID: '心電貼ID',
    bloodOxygenClampID: '血氧夾ID',
    temperaturePasteID: '體溫貼ID',
    temperatureBoxID: '體溫盒ID',
    divideBed: '分配床位',
    useBed: '使用床位'
  },
  assets: {
    enterChannels: '請輸入渠道',
    houseEntities: '區院',
    fillMeasurement: '請填寫計量單位',
    enterSNnum: '請輸入資產SN號',
    back: '返回',
    PreviousStep: '上一步',
    NextStep: '下一步',
    AssetCode: '资产編碼',
    WardManage: '管理病區',
    deptManage: '管理部門',
    print: '打印',
    StorageTime: '录入時間',
    ResidualRate: '殘值率',
    model: '型號',
    BasicMessage: '基本信息',
    SecurityTime: '過保時間',
    PleaseSelectManagementWard: '請選擇管理病區',
    AssetSNCode: '資產SN號',
    AssetName: '資產名稱',
    user: '使用人',
    PleaseInputUser: '請填寫使用人',
    StorLocation: '存放位置',
    DistrictCourt: '請選擇院區',
    Loudong: '請選擇樓棟',
    LoudongIn: '請输入樓棟',
    storey: '請選擇樓層',
    userTime: '使用期限',
    Month: '月',
    UnitMeasure: '計量單位',
    source: '來源',
    SelectSource: '請選擇來源',
    Classification: '資產分類',
    PurchaseTime: '購入時間',
    money: '金額',
    brand: '品牌',
    PleaseSelectBrand: '請選擇品牌',
    WarrantyStart: '保修起始',
    channel: '渠道',
    ExtendedInformation: '擴展信息',
    configuration: '配置',
    Additional: '額外配置',
    save: '保存',
    yuan: '元',
    Code: '編碼',
    Name: '名稱',
    classification: '分類',
    SelectCategory: '請選擇分類',
    MapMode: '地圖模式',
    PrintLabel: '打印標簽',
    ImportAssets: '導入資產',
    AssetsInstock: '資產入庫',
    Label: '標簽',
    BoundLabel: '已綁定標簽',
    more: '更多',
    borrow: '借用',
    return: '退還',
    maintain: '維修',
    MaintenanceCompleted: '維修完成',
    scrap: '報廢',
    lose: '遺失',
    AssetInformation: '資產信息',
    OperationRecords: '操作記錄',
    AlarmRecord: '報警記錄',
    LocationRecords: '位置記錄',
    ElectronicSignature: '電子簽收',
    LoanOfAssets: '資產借用',
    BorrowWard: '借用病區',
    ChooseAreaToUse: '請選擇借用病區',
    BorrowTime: '借用時間',
    BorrowUser: '借用人',
    EnterTheBorrower: '請輸入借用人',
    LabelBinding: '標簽綁定',
    Binded: '已綁定',
    LabelType: '標簽類型',
    BluetoothTab: '藍牙標簽',
    SelectTab: '請選擇標簽',
    NormalLabel: '普通標簽',
    SelectTabType: '請選擇標簽類型',
    AlarmFence: '報警圍欄',
    CategoryOfAssets: '資產類別',
    UseOfTheWard: '使用病區',
    IntoTheLibrary: '入庫人',
    Modifier: '修改人',
    ModifyTime: '修改時間',
    Months: '個月',
    SNNumber: 'SN號',
    AssetsLocation: '資產位置',
    ViewMapLocation: '查看地圖位置',
    time: '時間',
    location: '位置',
    NoLocationInformationYet: '暫時沒有位置信息',
    ImportFromTemplate: '從模板中導入',
    SequenceNumber: '序號',
    NameOfFence: '圍欄名稱',
    GetIntoTheAlarm: '進入報警',
    LeaveThePolice: '離開報警',
    CheckTheFence: '查看圍欄位置',
    Operator: '操作人',
    ApplyArea: '申領病區',
    Claimant: '申領人',
    OperationContent: '操作内容',
    NoOperationalRecord: '暫時沒有操作記錄',
    ImportInTemplate: '模板裏導入',
    TemplateName: '模板名稱',
    leadingIn: '導入',
    property: '資產',
    ViewDetails: '查看詳情',
    SelectBorrowingTime: '請選擇借用時間',
    EnterAssetCode: '請輸入資產編碼',
    CodeTips: '資產編碼不能超過20個字符',
    NameTips: '資產名稱不能超過20個字符',
    AssetClassification: '請選擇資產分類',
    SelectManagement: '選擇管理科室',
    SelectStoragTime: '請選擇入庫時間',
    SelectPurchaseTime: '請選擇購入時間',
    ModelTips: '型號不能超過20個字符',
    UnitTips: '計量單位不能超過 10 個字符',
    ChannelTips: '渠道不能超過50個字符',
    AssetSNNTips: '資產SN號不能超過50個字符',
    ConfigureTips: '配置不能超過200個字符',
    AdditionalTips: '額外配置不能超過200個字符',
    ApplyTime: '申領時間',
    Download: '下載簽收單',
    ChangeApplicant: '變更申領人',
    OriginalC: '原申領人',
    NewApplicant: '新申領人',
    ConfirmDownload: '確認下載',
    EditSuccess: '編輯成功',
    DownloadPre: '下載預覽',
    FormatTips: '金額格式不正確',
    EnterAmount: '請輸入金額',
    FillInteger: '請填寫整數',
    LessThanZ: '不能小於0',
    FillUseLife: '請填寫使用期限',
    AssetManage: '資產管理',
    EditAssets: '編輯資產',
    ErrorTips: '獲取數據出錯',
    UploadFormat: '上傳頭像圖片衹能是JPG、JPEG、PNG、GIF格式',
    UploadExceed: '上傳頭像圖片大小不能超過 3MB!',
    AddOne: '最多添加一張圖片',
    MissingID: '缺少資產ID',
    ErrorRequest: '請求出錯',
    selectTab: '請選擇需要打印的標簽',
    limitTips: '無源RFID標簽綁定數量已經達到上限',
    ReturnSucc: '退還成功',
    number: '編號',
    ImportSucc: '導入成功',
    floor: '樓',
    FenceIDEmpty: '圍欄ID爲空',
    EnterAssets: '請輸入資產',
    search: '搜索',
    EnteBrand: '請輸入品牌名稱',
    alarmProcessing: '報警處理',
    enterAssets: '請輸入資產',
    enterAssetsTips: '請輸入資產編碼或名稱',
    assetsModel: '資產型號',
    totalAssets: '總資產',
    idleAssets: '空閑資產',
    borrowAssets: '借用資產',
    normalAssets: '正常資產',
    useAssets: '使用資產',
    maintenanceAssets: '維修資產',
    scrapAssets: '報廢資產',
    loseAssets: '遺失資產',
    statusPercentage: '狀態占比',
    endemicArea: '病區分布',
    timeDistribution: '時間分布統計年份',
    selectYear: '請選擇年份',
    classifyStatistics: '暫無分類統計數據',
    statusStatistics: '暫無狀態統計數據',
    areaStatistics: '暫無病區統計數據',
    timeStatistics: '暫無時間分布統計數據',
    leisure: '空閑',
    claimed: '已申領',
    apply: '申領',
    enterClassify: '請輸入分類名搜索',
    importClassify: '導入分類',
    addClassify: '新增分類',
    classifyName: '分類名稱',
    categoryParent: '上級分類',
    LengthTips: '長度在 1 到 50 個字符',
    ChooseFill: '請選擇填寫備注信息',
    LengthTips2: '長度在 0 到 50 個字符',
    AddSuccess: '添加成功',
    BrandName: '品牌名稱',
    CategoryName: '分類名稱',
    UpperClassify: '上級分類',
    SelectSCategory: '請選擇上級分類',
    exampleT: '例如： 台',
    EnteCategory: '請輸入分類名稱',
    FillmeasureInfo: '請填寫計量單位信息',
    LengthTips3: '長度在 0 到 10 個字符',
    LengthTips4: '長度在 0 到 200 個字符',
    EnterToSearch: '請輸入分類名搜索',
    ImportClassify: '導入分類',
    NewClassify: '新增分類',
    del: '刪除',
    EnterBrand: '請輸入品牌名搜索',
    NewBrand: '新增品牌',
    OnlyPrint: '一次最多衹能打印',
    tabs: '個標簽',
    EndOfLife: '進行報廢操作',
    LossOperate: '進行遺失操作',
    Return: '進行退還',
    IdleAssets: '空閑資產',
    BorrowAssets: '借用資產',
    NormalAssets: '正常資產',
    MaintenAssets: '維修資產',
    EndOfLifeAssets: '報廢資產',
    LostAssets: '遺失資產',
    screen: '篩選',
    NoDisaggregated: '暫無分類統計數據',
    StatusRatio: '狀態占比',
    TemporaryStatus: '暫無狀態統計數據',
    Distribution: '病區分布',
    NoWardArea: '暫無病區統計數據',
    TimeDistribution: '時間分布統計年份',
    SelectYear: '請選擇年份',
    NoTimeDistribution: '暫無時間分布統計數據',
    TaskName: '任務名稱',
    SelectAssets: '選擇資產',
    AssetsSelected: '已按條件選擇資產',
    Numberselected: '已選資產數',
    SelectExecutor: '請選擇執行人',
    TaskDescrip: '任務描述',
    Tip: '提示：普通盤點只針對有RFID標簽的資產。',
    chooseAssets: '請按資產選擇或按條件選擇資產',
    EnterTask: '請輸入任務名稱',
    selectAssets: '請選擇資產',
    LengthTips5: '長度在 200 個字符內',
    InventAssets: '資產盤點',
    EditTasks: '編輯資產盤點任務',
    AdditionalTask: '新增資產盤點任務',
    Tip2: '提示：自動盤點只針對所有藍牙標簽的資產。',
    ImplementInvent: '執行盤點',
    LengthTips6: '長度在 50 個字符內',
    InventSucc: '盤點成功',
    result: '結果',
    SelectResults: '請選擇盤點結果',
    Deficiencies: '盤虧',
    NotYetInvent: '尚未盤點',
    EnterAsset: '請輸入資產名稱搜索',
    InventResults: '盤點結果',
    NoCount: '未盤點',
    TotalTime: '共耗時',
    TaskType: '任務類型',
    AutoInvent: '自動盤點',
    ManualInvent: '手動盤點',
    TotalAssets: '任務總資產數',
    InventResult: '資產盤點結果',
    InventInfo: '盤點信息',
    InventDetails: '盤點明細',
    SelectByAssets: '按資產選擇',
    allAssets: '全部資產',
    NumberSelected: '已選擇資產數',
    ChoosebyC: '按條件選擇',
    AllCategory: '全部分類',
    selectCategory: '已選分類',
    NoDataSelect: '未選擇數據',
    SelectTabTab: '請選擇標簽標簽',
    NotStart: '未開始',
    InProgress: '進行中',
    Finished: '已結束',
    NewInventTask: '新建盤點任務',
    InventoryNum: '盤點單號',
    WaitStart: '等待自動盤點開始',
    WaitCountResults: '等待自動盤點結果',
    WaitingResults: '等待結果',
    ExportD: '導出明細',
    ViewResults: '查看結果',
    ResultOfInvent: '資產盤點-盤點結果-',
    BorrowSucc: '借用成功',
    SavedAssets: '已保存的資產',
    SelectClassify: '--請選擇上級分類--',
    enterAssetName: '請輸入資產名稱',
    fillNumber: '請填寫型號',
    deliveryTime: '出庫時間'
  },
  emer: {
    EmerFoundationSetup: '基礎設置',
    CategoryNameTips1: '分類名稱不能為空',
    CategoryNameTips2: '分類名稱不能超過20個字符',
    RemarksTips: '備註不能超過200個字符',
    LCstatisticalReports: '環節對比-統計報表-',
    Emer: '急診',
    GodIn: '神內',
    GodOut: '神外',
    Intervene: '介入',
    LinkCompare: '環節對比',
    NoTreatOption: '沒有選擇治療環節',
    NoStatisticsYet: '暫時沒有統計數據',
    MeanTimeOfAve: '平均到達時間',
    WhereTimeOut: '其中超時',
    Examples: '例',
    Percentage: '占比',
    Standards: '標準',
    DTStoStroke: 'DTS入門至卒中小組會診醫生',
    TimeDTCAndCt: 'DTC入門至完成CT檢查時間',
    TimeDNTAndAdmin: 'DNT入門至溶栓給藥時間',
    TimeOttAndTreat: 'OTT發病至治療時間',
    OPTtoFemoral: 'OPT入門至股動脈穿刺時間',
    DRTtoRepass: '再通後DRT入門至再通時間',
    TimeRange: '時間範圍',
    SelectTimeRange: '請選擇時間範圍',
    StartDate: '開始日期',
    EndDate: '結束日期',
    Hours: '小時',
    bindChestCard: '綁定胸卡',
    pleaseInputMac: '請輸入MAC地址',
    macAddress: 'MAC地址',
    macTips: 'MAC地址不能為空',
    macTips2: 'MAC地址只能包含英文字母和數字',
    realTimePatient: '實時病患',
    minuteAutomaticRefresh: '分鐘自動刷新',
    autoCreatePatients: '自動創建病患',
    addPatient: '添加病患',
    patientsInfo: '患者信息',
    clearLink: '清空環節',
    endLink: '結束環節',
    completed: '已完成',
    stayTime: '停留時長',
    entryTime: '進入時間',
    leaveTime: '離開時間',
    delLinkRecords: '刪除該環節記錄？',
    clearLinkRecords: '清空該患者所有環節記錄？',
    enterSNnumber: '請輸入SN號',
    medicalRecordNo: '病歷號',
    emerClassify: '急診分類',
    enterNameTips: '請輸入10個字符以內的姓名',
    snNoEmpty: 'SN號不能為空',
    snNoTips: 'SN號只能包含英文字符、數字',
    snNoTips2: 'SN號不能超出12個字符',
    pleaseEnterNumber: '請輸入數字',
    ageCannotZero: '年齡不能小於0',
    ageCannotMax: '年齡不能超過999',
    medicalNoTips: '病歷號不能超過50個字符',
    medicalNoTips2: '備註不能超過200個字符',
    chooseToLeave: '選擇離開時間並結束環節',
    waitingStar: '等待開始',
    chestCard: '胸卡',
    beginLink: '開始環節',
    nameOfLink: '填寫環節名稱',
    chooseFence: '選擇圍欄',
    midstLink: '中間環節',
    linkTips: '至少需要保留壹個環節',
    fenceNotEmpty: '圍欄不能為空',
    linkTips2: '環節名稱不能為空',
    linkTips3: '環節名稱不能超過20個字符',
    fencesTips: '圍欄不能重復選擇',
    exportTimeTable: '導出時間管理表',
    recordTime: '記錄時間',
    timeSheet: '時間管理表',
    hisPatient: '歷史病患',
    hospitalID: '門診/住院 ID',
    emerDoctor: '急診接診醫生',
    nerveDoctor: '神內會診醫生',
    neurDoctor: '神外會診醫生',
    InterDoctor: '介入組醫生',
    disease: '發病',
    diseaseTime: '發病時間',
    comingToHospital: '來院方式',
    ourHos: '我院',
    outerHos: '外院',
    selfHos: '自行來院',
    inTheHos: '院內發病',
    toCourtDate: '來院日期',
    preHos: '院前',
    timeForHelp: '呼救時間',
    ambulanceTime: '出車時間',
    timeOfArrival: '到達現場 / 首次醫療接觸時間',
    timeOfTransit: '離開現場/轉運時間',
    clinicalReception: '接診',
    timeOfArrivalAtGate: '到達大門時間',
    timeOfMedical: '首次醫療接觸時間',
    actualPassTime: '實際開綠通時間',
    emerDoctorTime: '急診醫生接診時間',
    apoplexyTime: '通知卒中會診醫生時間',
    arrivalTime: '到達時間',
    bloodTime: '抽血完成時間',
    bloodDeliveryTime: '通知送血時間',
    bloodDrawingReport: '抽血報告時間（快速血糖）',
    bloodDrawingReport2: '抽血報告時間（血常規）',
    adviseDeliveryTime: '通知運送時間',
    deliveryAdviseTime: '運送到達時間',
    sendingCTtime: '送CT時間',
    timeOfCTroom: '到達CT室時間',
    timeOfHead: '首份頭顱CT開始時間',
    CTcompletionTime: 'CT完成時間',
    CTAcompletionTime: '完成CTA時間（必要時）',
    reportTime: '報告時間',
    consciousness: '意識',
    questions: '提問',
    instruction: '指令',
    stare: '凝視',
    view: '視野',
    facioplegia: '面癱',
    upLeft: '左上',
    upRight: '右上',
    lowerLeft: '左下',
    lowerRight: '右下',
    freemasonry: '共濟',
    feel: '感覺',
    aphasia: '失語',
    articulation: '構音',
    neglect: '忽視',
    totalPoints: '總分',
    treatment: '治療方式',
    thrombolysis: '單純靜脈溶栓',
    thrombolyticDrug: '溶栓藥',
    dateTreatment: '治療日期',
    thrombectomy: '單純動脈取栓',
    bridging: '橋接（溶栓+取栓）',
    chooseReason: '選擇原因',
    intravenous: '靜脈溶栓',
    otherReason: '其他原因',
    thrombolytic: '溶栓給藥時間',
    punctureTime: '股動脈穿刺時間 / 造影開始時間',
    therapyTime: '血管內治療開始時間',
    recanalization: '再通',
    recanalizationTime: '再通時間',
    patientsTo: '患者去向',
    centre: '中心',
    neurologyDepart: '神經內科',
    neurosurgeryDepart: '神經外科',
    viewHos: '出觀離院',
    aroundEmer: '繞行急診',
    emerDeath: '急診死亡',
    hospitalization: '住院治療',
    carotidEndarterectomy: '頸動脈內膜剝脫術',
    carotidArteryStenting: '頸動脈支架成形術',
    hematoma: '去骨瓣開顱血腫清除術',
    aspiration: '立體定向椎血腫抽吸術',
    outcomePatients: '患者轉歸',
    significantComplication: '嚴重並發癥',
    symptomaticHemorrhage: '癥狀性出血',
    die: '死亡',
    selectTreatmentTime: '請選擇治療時間',
    SequenceNumberTip: '序號必須為數值',
    SequenceNumberExceed: '序號不能超過10位',
    othersTips: '其他不能超過100個字符',
    scoreNumberTips: '評分只能為0到10的整數',
    ToHospital: '到院',
    ChooseTreatment: '請選擇治療方式',
    AllOfThem: '全選',
    EmptyIt: '清空',
    ReverseElection: '反選',
    TherapeuticSession: '治療環節',
    ChooseTreat: '請選擇治療環節',
    ListNames: '名單列表',
    BatchExport: '批量導出',
    ContactNum: '聯系電話',
    doc: '醫生',
    IntroductFirstContact: '入門-首次接觸',
    FirstContactgoGreen: '首次接觸-開通綠通',
    FirstContactTEMT: '首次接觸-急診醫生接診',
    FirstContactCallDoc: '首次接觸-通知卒中醫生',
    EMTComInCallstrokeDoc: '急診醫生接診-通知卒中醫生',
    PageDocConsultantIsHere: '通知卒中醫生-會診醫生到達',
    BloodDrawSugarReport: '抽血-血糖報告',
    BloodDRAWCBCReport: '抽血-血常規報告',
    FirstContactNotify: '首次接觸-通知運送',
    OpenGreenNotify: '開通綠通-通知運送',
    EmtsNotify: '急診醫生接診-通知運送',
    ConsultantNotify: '會診醫生到達-通知運送',
    NotifyArriving: '通知運送-運送到達',
    TransportHereCT: '運送到達-送CT',
    CTCTroom: '送CT-到達CT室',
    ComeUpStartCT: '到達CT-開始CT',
    StartCTClearCT: '開始CT-完成CT',
    CTReportComplete: '完成CT-CT報告',
    TotalNumPatient: '病患總人數',
    NumOfMale: '男性病患人數',
    ProportionOfMale: '男性病患占比',
    NumOfFemale: '女性病患人數',
    ProportionOfFemale: '女性病患占比',
    UnknownSex: '未知性別人數',
    UnknownSexRatio: '未知性別占比',
    CurrentCountTime: '當前統計時間',
    Year: '年份',
    Month: '月份',
    CustomTimeFilter: '自定義時間篩選',
    AgeShare: '年齡段占比',
    AveLinkLength: '環節平均時長',
    Under30: '30歲以下',
    to50: '31歲~50歲',
    to70: '51歲~70歲',
    Over71: '71歲以上',
    UnknownAgeRange: '未知年齡段',
    UnknownEmerCategory: '未知急診分類',
    DateChoseIncorrect: '選擇的日期有誤',
    Birthday: '出生日期',
    HospitalNum: '住院號',
    OutpatientNumber: '門診號',
    IDNumber: '身份證號',
    Nation: '民族',
    HospitalWay: '入院途徑',
    ChestCardID: '胸卡ID',
    Saved: '已保存',
    Submitted: '已提交',
    HasBeenReported: '已上報',
    Diagnose: '診斷',
    DataExport: '數據導出',
    PatientQualityControlForm: '病患質控表',
    DoctorQualityControlForm: '醫生質控表',
    NIHSSGrade: 'NIHSS 評分',
    TreatmentOfType: '治療類型',
    ONT: 'ONT',
    DNT: 'DNT',
    OPT: 'OPT',
    DPT: 'DPT',
    DRT: 'DRT',
    ThePercentageFilledIn: '已填占比',
    LastUpdateTime: '最後更新時間',
    TimerShaft: '時間軸',
    TimeTable: '管理表',
    FirstAidMessage: '急救信息',
    ConsultationMessage: '會診信息',
    Thrombolysis: '溶栓',
    ApoplexyDoctor: '卒中會診醫生',
    IntravenousDoctor: '靜脈溶栓醫生',
    InterveneDoctor: '介入醫生',
    TimeOfFindingSymptoms: '發現癥狀開始時間',
    LastNormalTime: '最後正常時間',
    WhetherToHaveAStrokeAfterWakingUp: '是否醒後卒中',
    TimeOfCerebralInfarction: '腦梗發病時間',
    Known: '已知',
    WakeUpStroke: '醒後卒中',
    Ambulance: '120出車',
    GoToTheHospitalByYourself: '自行到院',
    GetSickInTheHospital: '院內發病',
    FromOtherHospitals: '外院就診',
    FromWhichHospital: '轉診醫院',
    BeforeArrivingAtTheHospital: '院前',
    CallTime: '呼叫時間',
    DepartureTime: '發車時間',
    FirstTreatmentTime: '首次醫療接觸時間',
    ArriveAtTheScene: '到達現場',
    SelfDescriptionAndSymptom: '主訴及癥狀',
    ComprehensiveAssessment: '綜合評估',
    Sober: '清醒',
    Dim: '模糊',
    Delirium: '譫妄',
    Drowsiness: '嗜睡',
    Lethargy: '昏睡',
    LightComa: '淺昏迷',
    ModerateComa: '中昏迷',
    DeepComa: '深昏迷',
    AddPatient: '新增病患',
    ConsultationScheduling: '會診排班',
    TrainScheduling: '出車排班',
    ReportDataExport: '導出上報數據',
    TimeSet: '時段設置',
    date: '日期'
  },
  equip: {
    newBracelet: '新增手環',
    newLabel: '新增標籤',
    tagID: '標籤ID',
    informationView: '信息查看',
    assetID: '請輸入資產標籤ID',
    selectDevice: '請輸入設備類型',
    insufficient: '電量不足',
    sufficient: '電量充足',
    enterID: '請輸入標籤ID',
    limitLabel: '標籤ID不能超過50個字符',
    SelectBind: '請選擇綁定',
    DelLabel: '是否刪除此標簽',
    FenceAlarmType: '圍欄報警類型',
    AssociatFenceArea: '關聯圍欄區域',
    StartDraw: '開始畫',
    EndDraw: '結束畫',
    EnterFenceName: '請輸入圍欄名稱',
    to16: '長度在 3 到 16 個字符',
    SelectAlarmType: '請選擇報警類型',
    to120: '長度在 2 到 120 個字符',
    StartPaintFence: '請開始畫圍欄',
    DotsTips: '並且畫的點至少為3個',
    formWrongTips: '表單內容有誤, 請檢查後再提交',
    NewRW: '新增讀寫器',
    EquipmentNum: '設備編號',
    IpAddress: 'IP地址',
    OnlineState: '在線狀態',
    TriggerSortEvent: '觸發了排序事件',
    XAxisCoordinates: 'X軸坐標',
    YAxisCoordinates: 'Y軸坐標',
    IpFormatErr: 'IP格式錯誤',
    IPnotEmpty: 'IP不能為空',
    EnterDeviceName: '請輸入設備名稱',
    EnterDeviceNum: '請輸入設備編號',
    LackOf: '缺少',
    SelectCoordinates: '請選取坐標',
    DeviceAddr: '設備地址',
    SignalRepeater: '信號中繼器',
    SignalReceiver: '信號接收器',
    ExitCamera: '出口監視器',
    ToWard: '所屬病區',
    EquipmentStatus: '設備狀態',
    Abnormity: '異常',
    EquipmentType: '設備型號',
    FirmwareVersion: '固件版本',
    DeviceIpAddr: '設備IP地址',
    MacAddress: 'MAC地址',
    OperationMode: '工作方式',
    WorkPort: '工作端口',
    TargetIP1: '目標IP1',
    PortIP1: '端口IP1',
    TargetIP2: '目標IP2',
    PortIP2: '端口IP2',
    TargetIP3: '目標IP3',
    PortIP3: '端口IP3',
    TargetIP4: '目標IP4',
    PortIP4: '端口IP4',
    SubnetMask: '子網掩碼',
    GatewayIp: '網關IP',
    HowGetIP: '獲取IP方式',
    StaticAcquisition: '靜態獲取',
    DynamicAcquisition: '動態獲取',
    WebPort: '網頁端口',
    CommandPort: '命令端口',
    SelectCoordinate: '選取坐標',
    EnterDeviceAddr: '請輸入設備地址',
    ModifyButton: '修改按鈕',
    NewEquipment: '新增設備',
    AddTime: '添加時間',
    LastActiveTime: '最後活躍時間',
    port: '端口',
    ParameterSet: '參數設置',
    delDeviceOrNot: '是否刪除此設備?',
    EnterNameSearch: '請輸入名稱搜索',
    AddFence: '新增圍欄',
    HoldOverAlarm: '滯留報警',
    EnterTplNameSearch: '請輸入模板名搜索',
    NewTemplate: '新增模板',
    TplName: '模板名稱',
    EnterAlarmFenceTplname: '請輸入報警圍欄模版名稱',
    to20: '長度在 3 到 20 個字符',
    To120: '長度在 0 到 120 個字符',
    NewType: '新增類型',
    DevTypeName: '設備類型名稱',
    Business: '所屬業務',
    TechnologyType: '技術類型',
    SelectTecType: '請選擇技術類型',
    SelectBusiness: '請選擇所屬業務',
    EnterDevTypeName: '請輸入設備類型名稱',
    DevTypeNameTips: '設備類型名稱不能超過20個字符',
    Obstetrics: '產科'
  },
  // 系统设置
  sys: {
    NumberOfAssociatedDevices: '關聯設備數量',
    AffiliatedHospital: '所屬院區',
    enterEmployeSearch: '請輸入員工編號搜索',
    enterRoleSearch: '請輸入角色名稱搜索',
    batchRemove: '批量刪除',
    roleName: '角色名稱',
    proceed: '進行',
    confirmTo: '確定對',
    phoneNum: '手機號',
    employeeID: '員工編號',
    password: '密碼',
    confirmPassword: '確認密碼',
    customAccount: '自定義賬號',
    email: '郵箱',
    depart: '所屬部門',
    clickSelectDepartment: '點擊選擇部門',
    respectiveWard: '所屬病區',
    clickSelectWard: '點擊選擇應用範圍',
    role: '角色',
    passTips: '密碼不能為空',
    confirmPasswordTips: '確認密碼不能為空',
    pwdTips: '確認密碼與密碼輸入不壹致',
    emailTips: '郵箱格式錯誤',
    phoneNumTips: '手機號格式錯誤',
    wardTips: '病區不能為空',
    nameTips: '姓名不能為空',
    pwdText: '長度為 8 到 16 個字符',
    pwdTipsText: '長度為 8 到 16 個字符，至少存在壹個字母，其他可以是任意字符',
    employeeTips: '員工編號不能為空',
    authorization: '授權',
    roleNameTips: '角色名稱不能為空',
    departTips: '部門不能為空',
    Enquiry: '查詢',
    ParameterName: '參數名',
    ParameterVal: '參數值',
    parameterNameTips: '參數名不能為空',
    parameterValTips: '參數值不能為空',
    IntoDepartment: '導入科室',
    DepartID: '科室ID',
    NameDepartment: '科室名稱',
    CodingNameDepartment: '科室编碼',
    SynchronizeData: '同步數據',
    SuperiorDepart: '上級科室',
    SORTnum: '排序號',
    ClickToSelectSupDepa: '點擊選擇上級科室',
    depaNameTips: '科室名稱不能為空',
    SupDepaTips: '上級科室不能為空',
    FirstlevelDepaTips: '壹級科室不能選擇上級科室',
    DictionaryName: '字典名稱',
    DictionaryType: '字典類型',
    DictionaryCode: '字典碼',
    DictionaryVal: '字典值',
    SORT: '排序',
    DicNameTips: '字典名稱不能為空',
    DicTypeTips: '字典類型不能為空',
    DicCodeTips: '字典碼不能為空',
    DicValTips: '字典值不能為空',
    DefaultField: '預設字段',
    enterContents: '請輸入內容',
    NewLocation: '新增位置',
    HptDistrict: '院區',
    Buildings: '樓棟',
    selectDistrict: '請選擇院區',
    NewHplArea: '新增院區',
    EditorialOffice: '編輯院區',
    SuperiorUnit: '上級單位"',
    FillBuildingName: '請填寫樓棟名稱',
    buildingNameTips: '樓棟名稱長度在 1 到 50 個字符內',
    institutionName: '院區名稱:',
    enterHplName: '請輸入院區名稱',
    HplNameTips: '院區名稱中不能帶有空格',
    HplNameTips2: '院區名稱長度在1-50字符內',
    UserNameAndAction: '用戶名／用戶操作',
    UserName: '用戶名',
    UserOperation: '用戶操作',
    RequestMethod: '請求方法',
    ReqParameter: '請求參數',
    ExecutionTime: '執行時長(毫秒)',
    SuperMenu: '上級菜單',
    ContentsTable: '目錄',
    Menu: '菜單',
    btn: '按鈕',
    MenuUrl: '菜單URL',
    AuthorizationSigns: '授權標識',
    clickSelectMenu: '點擊選擇上級菜單',
    MenuRoute: '菜單路由',
    MultipleCommas: '多個用逗號分隔',
    SuchAs: '如',
    menuURLtips: '菜單URL不能為空',
    menuNameTips: '菜單名稱不能為空',
    parentMenuTips: '上級菜單不能為空',
    FenceCacheInfoSync: '圍欄緩存信息同步',
    TagCacheInfoSync: '標簽緩存信息同步',
    SYNCfailure: '同步失敗',
    newPassword: '新密碼',
    confirmNewPassword: '重复新密碼',
    Common: '常用',
    resetPassword: '修改密碼',
    oldPassword: '原密碼',
    alarmSoundSetting: '報警聲音設置',
    siteInfo: '位置信息'

  },
  job: {
    BatchSuspension: '批量暫停',
    BatchRecovery: '批量恢復',
    BatchExecution: '批量立即執行',
    LogList: '日誌列表',
    MethodName: '方法名稱',
    Parameters: '參數',
    Expression: '表達式',
    TimeOut: '暫停',
    Recover: '恢復',
    Immediately: '立即執行',
    userNameTips: '用戶名不能為空',
    methodNameTips: '方法名稱不能為空',
    cronExpressTips: 'cron表達式不能為空',
    TaskId: '任務ID',
    LogID: '日誌ID',
    Failure: '失敗',
    UnitTime: '耗時(單位: 毫秒)'
  },
  oss: {
    cloudConfig: '雲存儲配置',
    uploadFiles: '上傳文件',
    operateNextTips: '操作成功, 是否繼續操作?',
    imgTips: '只支持jpg、png、gif格式的圖片！',
    dragTips: '將文件拖到此處，或',
    storageType: '存儲類型',
    qiniu: '七牛',
    Aliyun: '阿裏雲',
    qcloud: '騰訊雲',
    freeTips: '免費申請(七牛)10GB儲存空間',
    domainName: '域名',
    boundDomainName: '綁定的域名',
    pathPrefix: '路徑前綴',
    notSetTips: '不設置默認為空',
    spaceName: '空間名',
    storageSpaceName: '七牛存儲空間名',
    regionOrigin: '所屬地區',
    originTips: '如：sh（可選值 ，華南：gz 華北：tj 華東：sh）'
  },
  // 护理白板
  nurse: {
    remarkContent: '備註內容',
    createUserName: '創建人',
    addRemark: '新建備忘',
    lengthTip100: '長度在 1 到 100 個字符',
    dictName: '字典名稱',
    addDict: '新建數據字典',
    dictNew: '新建',
    dictCode: '字典編碼',
    selectPrevDict: '點擊選中上級字典',
    prevDict: '上級字典',
    SuperiorParameter: '上級參數',
    lengthTip20: '長度在 1 到 20 個字符',
    boardName: '白板名稱',
    scope: '應用名稱',
    source: '來源',
    boardId: '板塊',
    Module: '所屬模塊',
    selectBoardId: '請選擇板塊ID',
    dictNamePlaceholder: '請輸入字典名稱',
    StartNursingScreen: '啟動護理大屏',
    NewNursingWhiteboard: '新增護理白板',
    PleaseEnterWhiteboardName: '請輸入白板名稱',
    preview: '預覽',
    ApplicationWard: '應用範圍',
    HaveChosen: '已選擇',
    SickWards: '個病區',
    LayoutStyle: '佈局風格',
    ViewExamples: ' 查看示例',
    SetAsDefault: '設為默認',
    CancelDefault: '取消默認',
    delThis: '是否刪除這項內容',
    startUp: '啟動',
    RecycleBin: '回收箱',
    drafts: '草稿箱',
    Release: '發佈',
    delete: '刪除',
    edit: '編輯',
    DeleteTime: '刪除時間',
    UndercutDeletion: '徹底刪除',
    recovery: '恢復',
    ManualEntry: '手工錄入',
    notice: '通知詳情',
    setting: '設置',
    projectUpdateTime: '項目更新時間',
    systemdefault: '系統默認',
    secondDay: '次日',
    update: '更新',
    dataManagement: '資料管理',
    projectName: '項目名稱',
    exportProject: '導出項目',
    projectMaintain: '項目維護',
    CareMatters: '護理事項',
    medicglance: '醫護壹覽',
    patientView: '患者壹覽'

  },
  baby: {
    unbundleTip: '“確定”操作解綁後纔將該嬰兒與該牀位解綁。',
    outHosNotUnbound: '出院待解綁',
    BabyLabel: '嬰兒標簽',
    MotherTag: '母親標簽',
    Unused: '未使用',
    Inuse: '使用中',
    LabelCutAlarm: '標簽剪斷報警',
    ExitCameraAlert: '出口監視器報警',
    EquipAlarm: '設備報警',
    PairAlarm: '配對報警',
    CheckoutTips: '簽出未歸報警',
    LowBatAlarm: '低電量報警',
    MonBabyAlarm: '母嬰配對報警',
    offlineAlarm: '離線報警',
    activateAlarm: '激活報警',
    bedOccupancyAlarm: '床位佔用報警',
    Handler: '處理人',
    ChooseComPhrase: '選擇常用語',
    Falsealarm: '報警誤觸，已檢查無異常！',
    equipFailure: '設備失靈，已檢查！',
    SetInspectItem: '巡查事項設置',
    babyName: '嬰兒姓名',
    babySex: '嬰兒性別',
    MotherName: '母親姓名',
    MotherHplNum: '母親住院號',
    SearchResults: '巡查結果',
    patrolTime: '巡查時間',
    InspectOne: '巡查人',
    AddInspectItems: '添加巡查事項',
    enterKeyword: '請輸入關鍵字',
    signIO: '簽入簽出',
    MotherBabyPairs: '母嬰配對',
    PatrolRecords: '巡查記錄',
    calRecords: '離線記錄',
    OperationRecord: '操作記錄',
    OffWarning: '關閉警告音',
    TemporarilyOut: '臨時遷出',
    Returnrest: '回歸剩余',
    babyLabelTips: '嬰兒標簽電量過低',
    mom: '母親',
    baby: '嬰兒',
    BoundMomTag: '已綁定母親標簽',
    WaitBabyInfo: '等待嬰兒信息',
    inactive: '未激活',
    BoundBabyLabel: '已綁定嬰兒標簽',
    notBoundBabyLabel: '未綁定嬰兒標簽',
    BabyTagActivated: '已激活嬰兒標籤',
    BabyInfoAssigned: '已分配嬰兒信息',
    PreAssignedLabel: '預分配嬰兒標簽',
    labelSet: '標簽設置',
    history: '歷史記錄',
    PreBoundLabel: '預綁嬰兒標簽',
    BabyLocation: '嬰兒位置',
    handleOperation: '請處理報警後操作',
    checkBabyIn: '請將該嬰兒簽入後操作',
    babyTagOffline: '嬰兒標簽處於離線狀態',
    will: '將',
    ToCheckIn: '進行簽入',
    CheckinS: '簽入成功',
    ClearAlarmSucc: '清除聲光報警成功',
    HavetoLabel: '已綁標簽',
    UnbundLabel: '解綁標簽',
    LabelBaby: '將嬰兒標簽',
    ForSolutionTo: '進行解綁',
    PairingSUCC: '配對成功',
    MatchFailure: '配對失敗',
    MatchTime: '配對時間',
    operatingInfo: '操作信息',
    CompletePreBind: '完成預綁嬰兒標簽綁定',
    CompleteLabelBind: '完成嬰兒標簽綁定',
    OperationChange: '操作更換標簽',
    OperationUnbind: '操作解綁標簽',
    CheckOutOper: '操作簽出',
    OperatingCheckin: '操作簽入',
    ReceivePushMom: '接收推送母親信息',
    ReceivePushBaby: '接收推送嬰兒信息',
    ReceiveBedChange: '接收推送換床信息',
    ReceiveDischarge: '接收出院信息',
    OutHospitalUnbundleLabel: '操作出院解綁標籤',
    operationTime: '操作時間',
    BabyLabelBind: '嬰兒標簽綁定',
    ReasonCheckOut: '簽出事由',
    PlanRegressTime: '計劃回歸時間',
    ToCheckOut: '進行簽出',
    After5min: '5分鐘後',
    After10min: '10分鐘後',
    afterHalfhour: '半小時後',
    afterAHour: '1小時後',
    afterTwoHour: '2小時後',
    afterThreeHour: '3小時後',
    seeADoc: '就診',
    nurse: '護理',
    takeABath: '洗澡',
    FamiliesFrom: '家屬帶離',
    CheckOutSucc: '簽出成功',
    babyNormal: '嬰兒無異常',
    babyAbnormal: '嬰兒有異常',
    locatorData: '定位數據',
    numberBabiesHpl: '在院嬰兒數',
    Newbabiestoday: '今日新增嬰兒數',
    NumDischargedT: '今天出院人數',
    CheckOutT: '今日簽出數',
    CheckInT: '今日簽入數',
    NumPatrolsT: '今日巡查數',
    TagAlarmNumT: '今天標簽告警數',
    NumbMatAndInfaT: '今日母嬰配對數',
    numboys: '男孩人數',
    numgirl: '女孩人數',
    AnUnknownNum: '未知人數',
    SexRatioOfInfants: '在院嬰兒性別占比',
    LabUseStatisticalP: '標簽使用統計占比',
    haveBeenUsed: '已使用',
    Taguse: '標簽使用',
    TnfantManageAlarmRatioT: '今日嬰兒管理報警占比',
    UnusedPairFailalarm: '未使用配對失敗報警',
    ExportAlarm: '出口報警',
    TBbabylabAlarmRatio: '今日嬰兒標簽報警占比',
    labelcut: '標簽剪斷',
    EquipHisAlarmRatio: '設備歷史報警占比',
    signalMonitor: '信號監視器',
    TWorkContentRatio: '今日工作內容占比',
    Babypatrol: '嬰兒巡查',
    MoveOutof: '簽入簽出',
    distributionTime: '時間分布',
    pm: '時',
    No: '日',
    workloadStatistics: '工作量統計',
    chartInfo: '圖表信息',
    BindBabyInfo: '綁定嬰兒信息',
    ActivatedTips: '已激活，請及時確認完成嬰兒綁定！',
    selectBedNum: '請選擇要綁定的床號',
    selectBaby: '請選擇要綁定的嬰兒',
    LOOKTG: '返回上壹頁',
    enterRemarks: '請輸入備註',
    BabyLabelsAssigned: '已分配嬰兒標簽',
    noBabyLocation: '無該嬰兒定位信息',
    bindTagReminder: '綁定標籤提醒',
    LabelUntyingReminder: '標籤解綁提醒',
    bindBabyInTime: '請及時綁定嬰兒',
    CheckOutNoReturn: '簽出未歸',
    NoBabyListNow: '暫時沒有嬰兒信息',
    NoBedListNow: '暫時沒有床號信息',
    SyncBabyInformation: '同步嬰兒信息'
  },
  humiture: {
    customNum: '自定義編號',
    MonitoringPoint: '監測點',
    addMonitoringPoint: '添加監測點',
    PleaseBindHumiture: '請綁定溫濕度終端',
    delMonitoringPoint: '刪除監測點',
    deviceManagement: '設備管理',
    MonitoringPointDeatil: '監測詳情'
  },
  ...twLocale
};

export default fn;
