import axios from "@/utils/request";

const PROXY = process.env.NODE_ENV === "development" ? "/proxy" : "";

function Basic_Url(url) {
  let basic_url = "/api/";
  return basic_url + url;
}

const helpAPI = {
  /* 任务工单 */
  // 审核工单
  order_auditOrder(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/auditOrder"),
          method: "post",
          data
        },
        option
      )
    );
  },
  order_user_list(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/queryUserSite"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 工单记录列表
  order_record_list(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/orderRecord/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 派单
  taskOrderAllotOrder(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/allotOrder"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 转派
  taskOrderRedeploy(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/redeploy"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 个人设置 */
  settings_detail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/userSetting/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  settings_update(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/userSetting/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 摄像头管理 */
  AlarmCamera_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/cameraListPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  AlarmCamera_addOrEdit(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/camera/") + option,
          method: "post",
          data
        },
        option
      )
    );
  },
  AlarmCamera_detail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/camera/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  AlarmCamera_delete(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/camera/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 胸卡 */
  // 胸卡列表
  label_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/label/listPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 胸卡编辑/新增
  label_addOrEdit(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/label/") + option,
          method: "post",
          data
        },
        option
      )
    );
  },
  // 胸卡详情
  label_detail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/label/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 胸卡删除
  label_delete(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/label/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 终端告警记录 */
  alarmTerminalRecord_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/listPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 操作记录 */
  operationRecord_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/log/listPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 终端设置 */
  // 分页查询
  terminalSet_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/guardPointBind/guardListPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 添加或编辑
  terminalSet_addOrEdit(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/secuGuard/") + option,
          method: "post",
          data
        },
        option
      )
    );
  },
  // 删除终端
  terminalSet_delete(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/secuGuard/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 不分页查询
  terminalSet_list(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/secuGuard/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 不分页查询1
  terminalSet_list1(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("devi/DeviSecuGuard/list"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 报警记录
  // 报警记录列表分页查询
  AlarmRecord_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/listPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 处理报警记录
  AlarmRecord_handleRecord(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 报警记录列表不分页查询
  AlarmRecord_unhandleRecord(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 一键处理所有历史报警信息
  AlarmRecord_handleAllOldRecord(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/updateHisRecord"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 报警点管理 */
  // 报警点管理 不分页查询
  AlarmPointAdministration_list(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询楼栋楼层
  AlarmPointAdministration_querySite(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/querySite"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 添加或编辑报警点
  AlarmPointAdministration_editOrAdd(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/") + option,
          method: "post",
          data
        },
        option
      )
    );
  },
  // 可绑定摄像头列表
  AlarmPointAdministration_cameraPointBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/queryCamera"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 解绑报警点
  AlarmPointAdministration_unBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url(),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 删除报警点
  AlarmPointAdministration_delete(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 无线呼叫器 - 绑定 - 添加
  addGuardBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        { url: PROXY + Basic_Url("infu/guardBind/add"), method: "post", data },
        option
      )
    );
  },
  // 无线呼叫器 - 绑定 - 更新
  updateGuardBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("infu/guardBind/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 无线呼叫器 - 绑定 - 删除
  deleteGuardBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("infu/guardBind/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 无线呼叫器 - 绑定 - 查询
  detailGuardBind(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("infu/guardBind/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 参数设置查询
  queryConfig(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/config/queryConfig"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 参数设置
  setConfig(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/config/setConfig"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 20211129 V2.0.0安全求助
  // 查询参数设置
  configList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/config/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 参数设置修改
  configUpdate(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/config/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 参数设置 - 查询报警类型列表
  alarmTypeList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmType/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 求助报警记录列表(分页)
  alarmRecordQueryHelpAlarm(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/queryHelpAlarm"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查看工单详情
  taskOrderDetail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 关闭工单
  taskOrderCloseOrder(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/taskOrder/closeOrder"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 查看时间轴
  pointTaskTimelLine(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/pointTask/timelLine"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 地图模式查询
  pointQueryMap(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/queryMap"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询楼栋楼层
  pointQuerySite(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/querySite"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 报警点详情
  pointQueryDetail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/queryDetail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 报警点编辑用到的接口
  // 查看报警点
  pointDetail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 修改报警点
  pointUpdate(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查看摄像头
  cameraPointBindDetail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 关闭任务
  pointTaskCloseTask(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/pointTask/closeTask"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 摄像头模式查询
  pointQueryCameraMode(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/queryCameraMode"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 清除报警
  alarmRecordClearAlarm(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/clearAlarm"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 报警曲线
  alarmRecordQueryAlarmCurve(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/queryAlarmCurve"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询某月有报警数据的天
  alarmRecordQueryableTimes(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/queryableTimes"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 操作记录列表查询
  logList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/log/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 清除已处理历史报警
  alarmRecordClearHisAlarm(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/alarmRecord/clearHisAlarm"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 统计汇总 */
  // 查询无工单求助报警事件类型统计
  queryNotOrderHelpAlarmEventPctg (data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryNotOrderHelpAlarmEventPctg"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询求助报警事件类型统计
  queryHelpAlarmEvent(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryHelpAlarmEventPctg"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询终端报警类型统计
  queryDeviceAlarm(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryDeviceAlarmPctg"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询无工单求助报警例数统计
  queryNotOrderHelpAlarmCount (data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryNotOrderHelpAlarmCount"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询求助报警例数统计
  queryHelpAlarmCount(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryHelpAlarmCount"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询终端报警例数统计
  queryDeviceAlarmCount(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/statistics/queryDeviceAlarmCount"),
          method: "post",
          data
        },
        option
      )
    );
  },
  /* 安保人员 */
  // 安保人员列表-分页
  person_listPage(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/listPage"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 删除安保人员信息
  person_delete(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/delete"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 安保人员详情
  person_detail(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/detail"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 安保岗位列表
  postList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/post/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 新增/编辑安保岗位
  postUpdate(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/post/update"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询用户列表
  person_user_list(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/userList"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 安保人员新增/编辑更新
  person_add_update(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/") + option,
          method: "post",
          data
        },
        option
      )
    );
  },
  // 根据楼层查询安保人员定位
  pointFloorLocation(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/point/floorLocation"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 安保人员列表-不分页
  personList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/list"),
          method: "post",
          data
        },
        option
      )
    );
  },

  // 安保人员查看实时定位
  personLocation(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/location"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 查询胸卡列表-分页
  labelList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/label/list"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 2.2.4
  getNearList(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/person/nearList"),
          method: "post",
          data
        },
        option
      )
    );
  },  
  // 2.2.5 视频平台设置
  getCameraConfig(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/getCameraConfig"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 新增或更新摄像头配置
  deviceCameraConfig(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/deviceCameraConfig"),
          method: "post",
          data
        },
        option
      )
    );
  },
  // 获取播放token
  cameraToken(data = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + Basic_Url("secu/cameraPointBind/getWvpToken"),
          method: "post",
          data
        },
        option
      )
    );
  },
  //wvp 视频播放， 运维代理的
  cameraVideo(data = {}, headers = {}, option = {}) {
    return axios.request(
      Object.assign(
        {
          url: PROXY + `/api/play/start/${data.serial}/${data.channelId}`,
          method: "get",
          data,
          headers: headers
        },
        option,
      )
    );
  },
  // 按楼层查询实时定位
  getLocation (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/floor'),
      method: 'post',
      data
    }, option));
  },
  // 查询单个标签实时定位
  getLabelLocation (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/locate'),
      method: 'post',
      data
    }, option));
  },
  // 地图模式用户列表
  getFuzzyList (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/fuzzyList'),
      method: 'post',
      data
    }, option));
  },
  // 根据标签查询最近的摄像头列表
  queryNearCamera (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/queryNearCamera'),
      method: 'post',
      data
    }, option));
  },
  // 查询历史轨迹
  locateHistory (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/locateHistory'),
      method: 'post',
      data
    }, option));
  },
  historyDay (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/map/historyDay'),
      method: 'post',
      data
    }, option));
  },
  // 2.2.8

  // 求助报警记录导出
  exportHelpAlarm (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('secu/alarmRecord/exportHelpAlarm   '),
      method: 'post',
      data
    }, option));
  }
};

export default helpAPI;
