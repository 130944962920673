/*
 * @Author: your name
 * @Date: 2019-08-01 09:37:32
 * @LastEditTime: 2019-12-09 20:01:52
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \xinsec-new-security-vue\src\store\index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
import user from './modules/userHandle';
import users from './modules/user';
import tagsView from './modules/tagsView';
import common from './modules/common';
import cacheRequest from './modules/cacheRequest';
import assets from './modules/assets';
import baby from './modules/baby';
import transfusion from './modules/transfusion';
import emer from './modules/emer';
import equip from './modules/equip';
import patient from './modules/patient';
import care from './modules/care';
import nurse from './modules/nurse';
import humiture from './modules/humiture';
import userHandle from './modules/userHandle';
import cloneDeep from 'lodash/cloneDeep';
import help from './modules/help';
import pectoralgia from './modules/pectoralgia';
import fengmap from './modules/fengmap';
Vue.use(Vuex);

// vuex 数据持久化
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

const store = new Vuex.Store({
  state: {
    version: 'v1.0.0'
  },
  modules: {
    app,
    settings,
    user,
    users,
    tagsView,
    common,
    cacheRequest,
    assets,
    baby,
    emer,
    equip,
    patient,
    transfusion,
    care,
    nurse,
    humiture,
    userHandle,
    help,
    pectoralgia,
    fengmap
  },
  getters,
  // vuex 数据持久化
  plugins: [vuexLocal.plugin],
  mutations: {
    // 重置vuex本地储存状态
    resetStore (state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key]);
      });
    }
  }
});

export default store;
