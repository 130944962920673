import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/plat';
  return basic_url + url;
}

const platformAPI = {
  // 消息平台 messagePlatform
  // 接收的信息
  // 分页查询
  receiveMessage_list (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/receive/listPage'),
      method: 'post',
      data
    }, option));
  },
  // ======================
  // 订阅信息 subscribe
  // 订阅信息分页查询
  subscribe_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/subscription/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 订阅信息 添加或更新
  subscribe_addoredit (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/subscription/') + option,
      method: 'post',
      data
    }, option));
  },
  // 订阅信息 删除
  subscribe_delete (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/subscription/delete'),
      method: 'post',
      data
    }, option));
  },
  // ========================
  // 发送信息  sendMessage
  // 分页查询
  sendMessage_listPage (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/send/listPage'),
      method: 'post',
      data
    }, option));
  },
  // 重新发送
  sendMessage_reSend (data = {}, option = {}) {
    return axios.request(Object.assign({
      url: PROXY + Basic_Url('/data/send/resend'),
      method: 'post',
      data
    }, option));
  }
};
export default platformAPI;
