<template>
  <div class="pages-view">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="$store.state.common.paginationOptions.pageSizes"
      :page-size="$store.state.common.paginationOptions.pageSize"
      :layout="$store.state.common.paginationOptions.layout"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    // 当前页数
    currentPage: {
      type: [String, Number],
      default: 1
    },
    // 总条数
    total: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    // 切换每页条数
    handleSizeChange (val) {
      this.$store.commit('common/setPageSize', val);
      this.$emit('change', {
        currentPage: this.currentPage,
        pageSize: val
      })
    },
    // 跳转到具体页数
    handleCurrentChange (val) {
      // 抛出 change 方法
      this.$emit('change', {
        currentPage: val,
        pageSize: this.$store.state.common.paginationOptions.pageSize
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .pages-view {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
