var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "dark",
        content: _vm.tooltip,
        placement: _vm.placement,
        disabled: _vm.disabled
      }
    },
    [
      _c("el-button", { class: _vm.buttonClass, attrs: { type: _vm.type } }, [
        _vm.icon
          ? _c("i", { staticClass: "iconsprite", class: _vm.icon })
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "btn-text" }, [_vm._t("default")], 2)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }