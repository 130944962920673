// http请求数据缓存
export default {
  namespaced: true,
  state: {
    // 查询所有部门信息缓存 /admin/sys/dept/getAllDept
    allDept: {},
    allArea: {},
    assetsSignImg: {} // 资产电子签收图片缓存
  },
  mutations: {
    updateAllDept (state, allDept) {
      state.allDept = allDept;
    },
    updateAllArea (state, allArea) {
      state.allArea = allArea;
    },
    setAssetsSignImg (state, data) {
      state.assetsSignImg[data.recordId] = data.signStr;
    }
  }
};
