import store from '@/store';

// 根据权限标识判断是否拥有权限
export function $isAuth (key, type) {
  let auth = String(key);
  if (type || Number(type) === 0) {
    auth = getKey(key, Number(type));
  }
  // 菜单显示权限
  if (store.state.userHandle.menuAuthList.indexOf(auth) === -1) {
    return false;
  }
  return true;
}

// 用户权限/角色权限 - 转换
function getKey (key, type) {
  switch (type) {
    case 0: // 系统管理
      return getSysKey(key);
    case 1: // 资产管理
      return getAssetsKey(key);
    case 2: // 卒中时间管理
      return getEmerKey(key);
    case 3: // 智慧看护
      return getCareKey(key);
    case 4: // 婴儿安全
      return getBabyKey(key);
    case 5: // 设备管理
      return getEquipKey(key);
    case 6: // 智能输液
      return getTransfusionKey(key);
    case 7: // 护理白板
      return getNurseKey(key);
    case 8: // 温湿度监测
      return getHumitureKey(key);
    case 9: // 安全求助
      return getHelpKey(key);
    case 10: // 胸痛时间管理
      return getPectoralgiaKey(key);
    case 11: // 数据大屏
      return getScreenKey(key);
    case 12: // 数据报表
      return getReportKey(key);
  }
}
// 胸痛时间管理
function getPectoralgiaKey (key) {
  switch (key) {
    case '用户新增':
      return '201001';
    case '用户回收箱':
      return '201002';
    case '用户回收箱查看':
      return '20100201';
    case '用户回收箱恢复':
      return '20100202';
    case '用户编辑':
      return '201003';
    case '用户删除':
      return '201004';
    case '用户重置密码':
      return '201005';
    case '用户权限分配':
      return '201006';
    case '角色新增':
      return '200901';
    case '角色编辑':
      return '200902';
    case '角色删除':
      return '200903';
  }
}
// 安全求助
function getHelpKey (key) {
  switch (key) {
    case '用户新增':
      return '190901';
    case '用户回收箱':
      return '190902';
    case '用户回收箱查看':
      return '19090201';
    case '用户回收箱恢复':
      return '19090202';
    case '用户编辑':
      return '190903';
    case '用户删除':
      return '190904';
    case '用户重置密码':
      return '190905';
    case '用户权限分配':
      return '190906';
    case '角色新增':
      return '190801';
    case '角色编辑':
      return '190802';
    case '角色删除':
      return '190803';
    case '个人中心':
      return '1999';
  }
}
// 温湿度监测
function getHumitureKey (key) {
  switch (key) {
    case '用户新增':
      return '180601';
    case '用户回收箱':
      return '180602';
    case '用户回收箱查看':
      return '18060201';
    case '用户回收箱恢复':
      return '18060202';
    case '用户编辑':
      return '180603';
    case '用户删除':
      return '180604';
    case '用户重置密码':
      return '180605';
    case '用户权限分配':
      return '180606';
    case '角色新增':
      return '180501';
    case '角色编辑':
      return '180502';
    case '角色删除':
      return '180503';
  }
}
// 护理白板
function getNurseKey (key) {
  switch (key) {
    case '用户新增':
      return '170501';
    case '用户回收箱':
      return '170502';
    case '用户回收箱查看':
      return '17050201';
    case '用户回收箱恢复':
      return '17050202';
    case '用户编辑':
      return '170503';
    case '用户删除':
      return '170504';
    case '用户重置密码':
      return '170505';
    case '用户权限分配':
      return '170506';
    case '角色新增':
      return '170401';
    case '角色编辑':
      return '170402';
    case '角色删除':
      return '170403';
  }
}
// 智能输液
function getTransfusionKey (key) {
  switch (key) {
    case '用户新增':
      return '161101';
    case '用户回收箱':
      return '161102';
    case '用户回收箱查看':
      return '16110201';
    case '用户回收箱恢复':
      return '16110202';
    case '用户编辑':
      return '161103';
    case '用户删除':
      return '161104';
    case '用户重置密码':
      return '161105';
    case '用户权限分配':
      return '161106';
    case '角色新增':
      return '161001';
    case '角色编辑':
      return '161002';
    case '角色删除':
      return '161003';
  }
}
// 设备管理
function getEquipKey (key) {
  switch (key) {
    case '用户新增':
      return '151001';
    case '用户回收箱':
      return '151002';
    case '用户回收箱查看':
      return '15100201';
    case '用户回收箱恢复':
      return '15100202';
    case '用户编辑':
      return '151003';
    case '用户删除':
      return '151004';
    case '用户重置密码':
      return '151005';
    case '用户权限分配':
      return '151006';
    case '角色新增':
      return '150901';
    case '角色编辑':
      return '150901';
    case '角色删除':
      return '150903';
  }
}
// 婴儿安全
function getBabyKey (key) {
  switch (key) {
    case '用户新增':
      return '140801';
    case '用户回收箱':
      return '140802';
    case '用户回收箱查看':
      return '14080201';
    case '用户回收箱恢复':
      return '14080202';
    case '用户编辑':
      return '140803';
    case '用户删除':
      return '140804';
    case '用户重置密码':
      return '140805';
    case '用户权限分配':
      return '140806';
    case '角色新增':
      return '140701';
    case '角色编辑':
      return '140702';
    case '角色删除':
      return '140703';
  }
}
// 智慧看护
function getCareKey (key) {
  switch (key) {
    case '用户新增':
      return '131301';
    case '用户回收箱':
      return '131302';
    case '用户回收箱查看':
      return '13130201';
    case '用户回收箱恢复':
      return '13130202';
    case '用户编辑':
      return '131303';
    case '用户删除':
      return '131304';
    case '用户重置密码':
      return '131305';
    case '用户权限分配':
      return '131306';
    case '角色新增':
      return '131201';
    case '角色编辑':
      return '131202';
    case '角色删除':
      return '131203';
    case '个人中心':
      return '1399';
  }
}
// 卒中时间管理
function getEmerKey (key) {
  switch (key) {
    case '用户新增':
      return '120901';
    case '用户回收箱':
      return '120902';
    case '用户回收箱查看':
      return '12090201';
    case '用户回收箱恢复':
      return '12090202';
    case '用户编辑':
      return '120903';
    case '用户删除':
      return '120904';
    case '用户重置密码':
      return '120905';
    case '用户权限分配':
      return '120906';
    case '角色新增':
      return '120801';
    case '角色编辑':
      return '120802';
    case '角色删除':
      return '120803';
  }
}
// 资产管理
function getAssetsKey (key) {
  switch (key) {
    case '用户新增':
      return '111401';
    case '用户回收箱':
      return '111402';
    case '用户回收箱查看':
      return '11140201';
    case '用户回收箱恢复':
      return '11140202';
    case '用户编辑':
      return '111403';
    case '用户删除':
      return '111404';
    case '用户重置密码':
      return '111405';
    case '用户权限分配':
      return '111406';
    case '角色新增':
      return '111301';
    case '角色编辑':
      return '111302';
    case '角色删除':
      return '111303';
  }
}
// 系统管理
function getSysKey (key) {
  switch (key) {
    case '用户新增':
      return '100301';
    case '用户回收箱':
      return '100302';
    case '用户回收箱查看':
      return '10030201';
    case '用户回收箱恢复':
      return '10030202';
    case '用户编辑':
      return '100303';
    case '用户删除':
      return '100304';
    case '用户重置密码':
      return '100305';
    case '用户权限分配':
      return '100306';
    case '角色新增':
      return '100201';
    case '角色编辑':
      return '100202';
    case '角色删除':
      return '100203';
  }
}
// 数据大屏
function getScreenKey (key) {
  switch (key) {
    case '用户新增':
      return '210601';
    case '用户回收箱':
      return '210602';
    case '用户回收箱查看':
      return '21060201';
    case '用户回收箱恢复':
      return '21060202';
    case '用户编辑':
      return '210603';
    case '用户删除':
      return '210604';
    case '用户重置密码':
      return '210605';
    case '用户权限分配':
      return '210606';
    case '角色新增':
      return '210501';
    case '角色编辑':
      return '210502';
    case '角色删除':
      return '210503';
  }
}
// 数据报表
function getReportKey (key) {
  switch (key) {
    case '用户新增':
      return '220701';
    case '用户回收箱':
      return '220702';
    case '用户回收箱查看':
      return '22070201';
    case '用户回收箱恢复':
      return '22070202';
    case '用户编辑':
      return '220703';
    case '用户删除':
      return '220704';
    case '用户重置密码':
      return '220705';
    case '用户权限分配':
      return '220706';
    case '角色新增':
      return '220601';
    case '角色编辑':
      return '220602';
    case '角色删除':
      return '220603';
  }
}

