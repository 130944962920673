import { getFloorList } from '@/api/modules/sysAPI.js';
const state = {
  // 地图配置参数
  options: {
    appName: '', // appName 必填
    key: '', // key 必填
    mapID: '', // mapID 必填
    themeID: '', // 地图默认主题ID
    buildId: '', // 地图所在建筑ID
    center: {}, // 视图中心的地图坐标
    mapZoom: 20, // 缩放级别,默认值:20
    visibleLevels: [1], // 显示楼层,默认值:[1]
    level: 1, // 聚焦楼层, 默认值: 1
    rotation: 0, // 旋转角度，默认值: 0
    tiltAngle: 30, // 倾斜角度 默认值: 30
    minTiltAngle: 0, // 最小倾斜角度
    viewMode: 2, // 视图模式 默认值: 4=3D, 2=2D
    preLoad: false, // 是否预加载 - 分层加载
    highlightPicker: [] // 触发高亮事件，目前支持 ’click‘ 和 ’hover‘，默认 []
  },
  // 楼层管理 - 楼层映射
  floorList: []
};

const mutations = {
  // 设置配置参数
  SET_OPTIONS (state, params) {
    state.options = Object.assign(state.options, params);
  },
  // 设置楼层管理 - 楼层映射
  SET_FLOOR_LIST (state, params) {
    state.floorList = params;
  }
};

const actions = {
  // 获取楼层列表
  async GET_FLOOR_LIST ({ commit }) {
    if (!state.floorList.length) {
      const { data } = await getFloorList();
      commit('SET_FLOOR_LIST', data);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
