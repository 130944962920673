// import Cookies from 'js-cookie';

// const TokenKey = 'token';

// export function getToken () {
//   return Cookies.get(TokenKey);
// }

// export function setToken (token) {
//   return Cookies.set(TokenKey, token);
// }

// export function removeToken () {
//   return Cookies.remove(TokenKey);
// }

// export function setUserName (userName) {
//   return Cookies.set('userName', userName);
// }

// export function getUserName () {
//   return Cookies.get('userName');
// }

// export function removeUserName () {
//   return Cookies.remove('userName');
// }

// token放localStorage
const TokenKey = 'token';

export function getToken () {
  return localStorage.getItem(TokenKey);
}

export function setToken (token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken () {
  return localStorage.removeItem(TokenKey);
}

export function setUserName (userName) {
  return localStorage.setItem('userName', userName);
}

export function getUserName () {
  return localStorage.getItem('userName');
}

export function removeUserName () {
  return localStorage.removeItem('userName' );
}