/*
 * @Author: zxx
 * @Date: 2020-11-17 15:51:02
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-17 15:54:11
 * @FilePath: \xinsec-iot-zhaoqing\src\utils\get-page-title.js
 */
// import defaultSettings from '@/settings';

// const title = defaultSettings.title;
export default function getPageTitle (pageTitle) {
  let info = JSON.parse(sessionStorage.getItem('CUSTOMER_INFO')) || {};
  console.log(info, '标题头--院方信息');
  let name = (info.name) || '';
  if (pageTitle) {
    return `${pageTitle}${name ? ('-' + name) : ''}`;
  }
  return `${name}`;
}
