import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/secu';
  return basic_url + url;
}

const secuAPI = {
  // 获取报警点
  getPointList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/queryList'), method: 'post', data }, option));
  },
  // 查看摄像头
  getCameraPointBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/cameraPointBind/detailCamera'), method: 'post', data }, option));
  },
  // 获取楼层是否有报警
  getAlarmByFloor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/alarmPointByFloor'), method: 'post', data }, option));
  },
  // 安全求助2.2.4
  // 报警管理-摄像头模式的位置
  queryCameraModeV2 (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/queryCameraModeSite'), method: 'post', data }, option));
  },
  // 查询医护人员
  getUserLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/userList'), method: 'post', data }, option));
  },
  // 新增医护人员
  saveBatch (data = [], option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/saveBatch'), method: 'post', data }, option));
  },
  // 获取安保人员
  getSecurityList (data = [], option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/person/list'), method: 'post', data }, option));
  },
  getNearList (data = [], option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/person/nearList'), method: 'post', data }, option));
  },
  getBatchNearList (data = [], option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/person/batchNearList'), method: 'post', data }, option));
  }
}

export default secuAPI;