export default {
  namespaced: true,
  state: {
    refreshRealtimePage: false, // 立即刷新实时病患页面
    msgBody: {}, // 消息内容
    level: [{
      value: '1',
      label: 'Ⅰ级'
    }, {
      value: '2',
      label: 'Ⅱ级'
    }, {
      value: '3',
      label: 'Ⅲ级'
    }, {
      value: '4',
      label: 'Ⅳ级'
    }],
    // 静脉溶栓，原因列表（否）
    fdjmrsyyList: [{
      value: 1,
      label: '超时间窗'
    }, {
      value: 2,
      label: '存在绝对禁忌'
    }, {
      value: 3,
      label: '患方拒绝'
    }, {
      value: 4,
      label: '症状轻微或快速好转'
    }, {
      value: 5,
      label: '起病时间不确切/醒后卒中'
    }, {
      value: 6,
      label: '严重卒中NIHSS＞25'
    }, {
      value: 7,
      label: '其他原因'
    }],
    cureTypeList: [{
      value: '1',
      label: '单纯静脉溶栓'
    }, {
      value: '2',
      label: '单纯急诊取栓'
    }, {
      value: '3',
      label: '桥接(溶栓+取栓)'
    }, {
      value: '4',
      label: 'CEA颈动脉内膜剥脱术'
    }, {
      value: '5',
      label: 'CAS颈动脉支架成形术'
    }, {
      value: '6',
      label: '去骨瓣开颅血肿清除术'
    }, {
      value: '7',
      label: '立体定向锥颅血肿抽吸术'
    }],
    cureNodeList: [{
      value: 1,
      label: '入门-首次接触',
      en: 'one',
      field: ['jzDddm', 'jzScyljc']
    }, {
      value: 2,
      label: '首次接触-开通绿通',
      en: 'two',
      field: ['jzScyljc', 'jzSjklt']
    }, {
      value: 3,
      label: '首次接触-急诊医生接诊',
      en: 'three',
      field: ['jzScyljc', 'jzJzysjz']
    }, {
      value: 4,
      label: '首次接触-通知卒中医生',
      en: 'four',
      field: ['jzScyljc', 'jzTzczhzys']
    }, {
      value: 5,
      label: '急诊医生接诊-通知卒中医生',
      en: 'five',
      field: ['jzTzczhzys', 'jzJzysjz']
    }, {
      value: 6,
      label: '通知卒中医生-会诊医生到达',
      en: 'six',
      field: ['jzTzczhzys', 'jzDd']
    }, {
      value: 7,
      label: '抽血-血糖报告',
      en: 'seven',
      field: ['jzCxwc', 'jzKsxt']
    }, {
      value: 8,
      label: '抽血-血常规报告',
      en: 'eight',
      field: ['jzCxwc', 'jzXcg']
    }, {
      value: 9,
      label: '首次接触-通知运送',
      en: 'nine',
      field: ['jzScyljc', 'jzTzys']
    }, {
      value: 10,
      label: '开通绿通-通知运送',
      en: 'ten',
      field: ['jzSjklt', 'jzTzys']
    }, {
      value: 11,
      label: '急诊医生接诊-通知运送',
      en: 'eleven',
      field: ['jzJzysjz', 'jzTzys']
    }, {
      value: 12,
      label: '会诊医生到达-通知运送',
      en: 'twelve',
      field: ['jzDd', 'jzTzys']
    }, {
      value: 13,
      label: '通知运送-运送到达',
      en: 'thirteen',
      field: ['jzTzys', 'jzYsdd']
    }, {
      value: 14,
      label: '运送到达-送CT',
      en: 'fourteen',
      field: ['jzYsdd', 'jzSct']
    }, {
      value: 15,
      label: '送CT-到达CT室',
      en: 'fifteen',
      field: ['jzDct', 'jzDdcts']
    }, {
      value: 16,
      label: '到达CT-开始CT',
      en: 'sixteen',
      field: ['jzDdcts', 'ctctaSftlctks']
    }, {
      value: 17,
      label: '开始CT-完成CT',
      en: 'seventeen',
      field: ['ctctaSftlctks', 'ctctaCtwc']
    }, {
      value: 18,
      label: '完成CT-CT报告',
      en: 'eighteen',
      field: ['ctctaWccta', 'ctctaBg']
    }],
    countReport: [{
      value: 0,
      en: 'nodeCompare',
      label: '环节对比'
    }, {
      value: 1,
      en: 'statDts',
      label: 'DTS统计（质控）',
      standard: 15, // 标准，单位分钟
      interval: 5
    }, {
      value: 2,
      en: 'statDtc',
      label: 'DTC统计（质控）',
      standard: 25,
      interval: 15
    }, {
      value: 3,
      en: 'statDnt',
      label: 'DNT统计（质控）',
      standard: 60,
      interval: 30
    }, {
      value: 4,
      en: 'statDnt',
      label: 'DNT统计（上报）',
      standard: 60,
      interval: 30
    }, {
      value: 5,
      en: 'statOtt',
      label: 'OTT统计（上报）',
      standard: 120,
      interval: 60
    }, {
      value: 6,
      en: 'statDpt',
      label: 'DPT统计（上报）',
      standard: 150,
      interval: 50
    }, {
      value: 7,
      en: 'statDrt',
      label: '再通后DRT（上报）',
      standard: 180,
      interval: 50
    }],
    lastTreatInTime: null, // 结束环节操作需要使用，选择的结束时间不能早于改时间
    lowPowerNoticeList: [], // 低电量报警列表
    // =====================
    BasicMessage: true,
    disease: true,
    comingToHospital: true,
    preHos: true,
    clinicalReception: true,
    examine: true,
    // ==============
    TD_Form: true,
    CT_Form: true,
    PastHistory_Form: true,
    NIHSS: true,
    mRS: true,
    GCS: true,
    pghzsffhjmrsrx: true,
    ConsultationResults: true,
    IntravenousThrombolysisRecord: true,
    endovascularTreatmentRecord: true,
    patientTrace: true,
    outcomePatients: true,
    SwallowingFunctionGrade: true,
    jysj: true,
    power: false,
    allFill: 203,
    basicFill: '',
    basicFilled: '',
    hzxxFill: '',
    hzxxFilled: '',
    rsjrFill: '',
    rsjrFilled: '',
    hzzgFill: '',
    hzzgFilled: '',
    FilledInFour: '',
    nation: [{
        nation: '汉族',
        value: 1
      },
      {
        nation: '蒙古族',
        value: 2
      },
      {
        nation: '回族',
        value: 3
      },
      {
        nation: '藏族',
        value: 4
      },
      {
        nation: '维吾尔族',
        value: 5
      },
      {
        nation: '苗族',
        value: 6
      },
      {
        nation: '彝族',
        value: 7
      },
      {
        nation: '壮族',
        value: 8
      },
      {
        nation: '布依族',
        value: 9
      },
      {
        nation: '朝鲜族',
        value: 10
      },
      {
        nation: '满族',
        value: 11
      },
      {
        nation: '侗族',
        value: 12
      },
      {
        nation: '瑶族',
        value: 13
      },
      {
        nation: '白族',
        value: 14
      },
      {
        nation: '土家族',
        value: 15
      },
      {
        nation: '哈尼族',
        value: 16
      },
      {
        nation: '哈萨克族',
        value: 17
      },
      {
        nation: '傣族',
        value: 18
      },
      {
        nation: '黎族',
        value: 19
      },
      {
        nation: '僳僳族',
        value: 20
      },
      {
        nation: '佤族',
        value: 21
      },
      {
        nation: '畲族',
        value: 22
      },
      {
        nation: '高山族',
        value: 23
      },
      {
        nation: '拉祜族',
        value: 24
      },
      {
        nation: '水族',
        value: 25
      },
      {
        nation: '东乡族',
        value: 26
      },
      {
        nation: '纳西族',
        value: 27
      },
      {
        nation: '景颇族',
        value: 28
      },
      {
        nation: '柯尔克孜族',
        value: 29
      },
      {
        nation: '土族',
        value: 30
      },
      {
        nation: '达斡尔族',
        value: 31
      },
      {
        nation: '仫佬族',
        value: 32
      },
      {
        nation: '羌族',
        value: 33
      },
      {
        nation: '布朗族',
        value: 34
      },
      {
        nation: '撤拉族',
        value: 35
      },
      {
        nation: '毛难族',
        value: 36
      },
      {
        nation: '仡佬族',
        value: 37
      },
      {
        nation: '锡伯族',
        value: 38
      },
      {
        nation: '阿昌族',
        value: 39
      },
      {
        nation: '普米族',
        value: 40
      },
      {
        nation: '塔吉克族',
        value: 41
      },
      {
        nation: '怒族',
        value: 42
      },
      {
        nation: '乌孜别克族',
        value: 43
      },
      {
        nation: '俄罗斯族',
        value: 44
      },
      {
        nation: '鄂温克族',
        value: 45
      },
      {
        nation: '崩龙族',
        value: 46
      },
      {
        nation: '保安族',
        value: 47
      },
      {
        nation: '裕固族',
        value: 48
      },
      {
        nation: '京族',
        value: 49
      },
      {
        nation: '塔塔尔族',
        value: 50
      },
      {
        nation: '独龙族',
        value: 51
      },
      {
        nation: '鄂伦春族',
        value: 52
      },
      {
        nation: '赫哲族',
        value: 53
      },
      {
        nation: '门巴族',
        value: 54
      },
      {
        nation: '珞巴族',
        value: 55
      },
      {
        nation: '基诺族',
        value: 56
      }
    ]
  },
  mutations: {
    setMsgBody(state, msgBody) {
      state.msgBody = msgBody;
    },
    setRefreshRealtimePage(state, flag) {
      state.refreshRealtimePage = flag;
    },
    // 设置最后一个环节进入时间
    setLastTreatInTime(state, time) {
      state.lastTreatInTime = time;
    },
    setLowPowerNoticeList(state, noticeList) {
      state.lowPowerNoticeList = noticeList;
    },
    pushLowPowerNoticeList(state, notice) {
      state.lowPowerNoticeList.unshift(notice);
    },
    spliceLowPowerNoticeList(state, option) {
      state.lowPowerNoticeList.splice(option.index, option.num);
    },
    getPower(state, power) {
      state.power = power;
    },
    allfill(state) {
      state.FilledInFour = state.basicFilled + state.hzxxFilled + state.rsjrFilled + state.hzzgFilled
    }
    // changeAll(state, number) {
    //   state.allFill = state.allFill + number
    // }
  }
};
