var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-table" },
    [
      _c(
        "el-table",
        {
          attrs: {
            lazy: "",
            "row-key": _vm.rowKey,
            load: _vm.load,
            data: _vm.data,
            stripe: _vm.stripe,
            border: _vm.border,
            height: _vm.tableHeight,
            "tree-props": _vm.treeProps,
            "span-method": _vm.spanMethod,
            "default-expand-all": _vm.defaultExpandAll,
            "header-cell-style": Object.assign(
              {
                height: "56px",
                color: "#333",
                "background-color": "#F7F8FA"
              },
              _vm.headerStyle
            ),
            "cell-style": Object.assign(
              {
                height: "56px"
              },
              _vm.cellStyle
            )
          },
          on: {
            "sort-change": _vm.sortChange,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }