import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/nurs';
  return basic_url + url;
}

const nurseAPI = {
  // ----------1.4.9 新增患者一览备注字段------------------------
  // 转床
  nursItemManuallnput (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/manualInput'), method: 'post', data }, option));
  },

  // ----------1.4.8 新增患者一览备注字段------------------------
  // 新增备注
  nurseNursPatientRemarkAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/NursPatientRemark/add'), method: 'post', data }, option));
  },
  // 更新备注
  nurseNursPatientRemarkUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/NursPatientRemark/update'), method: 'post', data }, option));
  },


  // ----------字典管理------------------------
  // 获取数据字典列表：实际地址就是：  /api/nurse/dict/queryList  请求基地址拼接上接口地址标识，那么需要在菜单管理定义好接口地址
  nurseDictList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/list'), method: 'post', data }, option));
  },
  // 根据字典id获取到字典详情
  nurseDictDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/detail'), method: 'post', data }, option));
  },
  // 获取上级字典树数据（树形）（新增字典或者编辑字典需要用到这个接口）来回显数据
  getqueryTree (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/queryTree'), method: 'post', data }, option));
  },
  // 新增字典
  nurseDictSave (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/save'), method: 'post', data }, option));
  },
  // 修改字典
  nurseDictUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/update'), method: 'post', data }, option));
  },
  // 删除字典
  nurseDictDel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/delete'), method: 'post', data }, option));
  },

  // ----------备注列表------------------------

  // 获取备注列表数据
  nurseRemarks (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/remarks/list'), method: 'post', data }, option));
  },
  // 根据备注id获取备注详情
  nurseRemarksDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/remarks/detail'), method: 'post', data }, option));
  },
  // 修改或者新增备注
  nurseRemarksSave (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/remarks/add'), method: 'post', data }, option));
  },
  // 删除备注列表
  nurseRemarksDel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/remarks/delete'), method: 'post', data }, option));
  },

  // ----------白板管理------------------------

  // 获取白板列表
  nurseBoardList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/list'), method: 'post', data }, option));
  },
  // 检查白板是否正在使用中：点击新增白板或者是修改白板需要先检查
  nurseIsUse (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/checkUse'), method: 'post', data }, option));
  },
  // 保存白板,修改白板
  nurseBoardSave (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/save'), method: 'post', data }, option));
  },
  // 保存白板,修改白板
  nurseBoardUpdateManualArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/updateManualArea'), method: 'post', data }, option));
  },
  // 根据白板id获取白板详情  id,白板名字，病区，白板创建时间等
  nurseBoardDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/detail'), method: 'post', data }, option));
  },
  // 根据白板id获取白板详情  id,白板名字，病区，白板创建时间等
  nurseItemList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/list'), method: 'post', data }, option));
  },
  // 获取白板详情数据--护理事项
  getNurseInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/getNurseInfo'), method: 'post', data }, option));
  },
  // 获取白板详情数据---医护一览
  getCareInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/getCareInfo'), method: 'post', data }, option));
  },
  // 获取患者大屏一览数据
  getNursePatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/getNursePatient'), method: 'post', data }, option));
  },
  // 获取白板的预览模块
  nurseBoardPreview (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/preview'), method: 'post', data }, option));
  },
  // 获取通知消息列表
  getNurseNoticeList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/notice/list'), method: 'post', data }, option));
  },
  // 修改白板的更新时间
  updateNurseTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/modifyUpdateTime'), method: 'post', data }, option));
  },
  // 修改白板的状态：草稿箱和回收箱可以对白板状态进行更改与白板列表的删除
  nurseBoardStatusChange (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/updateBoardStatus'), method: 'post', data }, option));
  },
  // 手工录入获取护理床位列表
  getQueryBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/queryBed'), method: 'post', data }, option));
  },
  // 手工录入添加数据
  setManualInput (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/manualInput'), method: 'post', data }, option));
  },
  // 手工录入获取检查分类床位列表
  getqueryExamBed (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/queryExamBed'), method: 'post', data }, option));
  },
  //白板启动大屏相关接口-----------------
  getItemID_List (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/list'), method: 'post', data }, option));
  },
  // 检查分类手工录入项批量修改
  updateEntryCheckCategory (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/updateBatch'), method: 'post', data }, option));
  },
  // 项目维护批量修改白板详情名字
  updateprojectmaintain (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/boardDetail/batchUpdate'), method: 'post', data }, option));
  },
  // 白板管理导出项目
  exportPoject (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dictParam/export'), method: 'post', data }, option));
  },

  // 医护一览手工录入
  updatecaremanualInput (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/care/manualInput'), method: 'post', data }, option));
  },
  // -----------------患者一览->>输液监测
  // 获取输液中的医嘱
  getPatientInfuMedicine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/queryByPatientInfuMedicine'), method: 'post', data }, option));
  },
  // 获取输液详情
  getMedicineDetails (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/queryByInfuMedicineDetails'), method: 'post', data }, option));
  },

  // 获取白板数据（患者一览护理分类项目列表）
  getPatientItemList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/getNursePatient/itemList'), method: 'post', data }, option));
  },

  //------------------自定义管理------------------
  //查询自定义列表
  getcustomize (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/customize/listPage'), method: 'post', data }, option));
  },
  //新增自定义内容
  addcustomize (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/customize/add'), method: 'post', data }, option));
  },
  //编辑自定义内容
  editcustomize (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/customize/update'), method: 'post', data }, option));
  },
  //------------------自定义管理------------------

  //------------------应急电话管理----------------
  //查询应急电话列表
  getemergencyCall (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/emergencyCall/listPage'), method: 'post', data }, option));
  },
  //编辑应急电话
  editemergencyCall (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/emergencyCall/update'), method: 'post', data }, option));
  },
  //新增应急电话
  addemergencyCall (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/emergencyCall/add'), method: 'post', data }, option));
  },
  //------------------应急电话管理----------------


  //启动大屏定时任务弹窗接口相关----------------


  //处理每一条报警记录
  handlealarmRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/process'), method: 'post', data }, option));
  },
  //批量处理报警记录
  batchhandlealarmRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/batchProcess'), method: 'post', data }, option));
  },
  //查询未处理的报警记录列表
  getalarmRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/alarmRecord/list'), method: 'post', data }, option));
  },

  //-----------启动大屏新增定时任务，新增备注相关

  //保存定时任务
  updateJobOrContent (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/updateJobOrContent'), method: 'post', data }, option));
  },
  //清空定时任务
  updatestopJob (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/stopJob'), method: 'post', data }, option));
  },
  //清空备注
  updateremark (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/item/clearRemark'), method: 'post', data }, option));
  },
  //----------------------获取医护排班相关信息


  //获取护士管床
  getnurseManagementBedData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/queryBedRangeShitftByArea'), method: 'post', data }, option));
  },
  //获取医生管床
  getdoctorManagementBedData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/queryDoctorBedRangeByArea'), method: 'post', data }, option));
  },
  //大屏获取医护排班数据
  getScreenNurseData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/queryNursDoctorRosterByArea'), method: 'post', data }, option));
  },
  //修改护士排班
  updatenurseManagementBed (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/updateBedRangeShitftByArea'), method: 'post', data }, option));
  },
  //修改医生排班
  updateDoctorBedRangeByArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/updateDoctorBedRangeByArea'), method: 'post', data }, option));
  },
  // 床位字典 获取位置信息/bed/listBuildAndFloor
  getListBuildAndFloor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/board/bed/listBuildAndFloor'), method: 'post', data }, option));
  },

};

export default nurseAPI;
