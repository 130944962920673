import axios from 'axios';
// import { MessageBox } from 'element-ui';
// import store from '@/store';
import router from '@/router';
import qs from 'qs';
import merge from 'lodash/merge';
import { getToken, removeToken } from '@/utils/auth';
import {
  setLoading
} from '@/utils';
import {
  message
} from '@/utils/resetMessage';

// create an axios instance
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000, // request timeout
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
});

// request interceptor
request.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['token'] = getToken();
    config.headers['t'] = new Date().getTime() + '' + parseInt(Math.random() * 100000);
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// code值白名单
// const whiteCode = [0, 10000, 301013, 309017];
const whiteCode = [0, 10000, 301013, 309017, 306022, 303075, 303076, 303077, 99999];
// response interceptor
request.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */

  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if ((res instanceof Blob) || whiteCode.includes(res.code)) {
      // console.log(res.code, 'res.coderes.coderes.code');
      return res;
    } else {
      // message({
      //   message: res.msg || 'error',
      //   type: 'error',
      //   duration: 3 * 1000
      // });
      if (res.code === 200000) { // 如果token过期 清除token
        removeToken();
        // location.reload();
        message({
          message: '登录过期，请重新登录',
          type: 'error',
          duration: 2000
        });
        router.push({
          name: 'login'
        });
      } else if ([100009, 301018, 100010].includes(res.code)) {
        message({
          message: res.msg || 'error',
          type: 'error',
          duration: 3 * 1000
        });
      }
      setLoading(false);// 防止接口报错持续出现加载页
      return Promise.reject(res || 'error');
    }
  },
  error => {
    console.log('err' + error); // for debug
    // message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // });
    setLoading(false);// 防止接口报错持续出现加载页
    return Promise.reject(error);
  }
);

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
request.adornParams = (params = {}, openDefultParams = true) => {
  let defaults = {
    't': new Date().getTime()
  };
  return openDefultParams ? merge(defaults, params) : params;
};
/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
request.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  let defaults = {
    't': new Date().getTime()
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};

export default request;
