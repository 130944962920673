/*
 * @Author: zxx
 * @Date: 2020-10-27 15:55:17
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-20 18:14:11
 * @FilePath: \xinsec-iot-zhaoqing\src\utils\eventEnum.js
 */
// 发布订阅事件枚举
export default {
  WS_Message_pati: 'WS_Message_pati', // 病患模块ws消息
  WS_Message_devi: 'WS_Message_devi', // 设备模块ws消息
  WS_Message_infu: 'WS_Message_infu', // 输液模块ws消息
  WS_Message_universal: 'WS_Message_universal', // 通用ws消息
  WS_Message_help: 'WS_Message_help', // 安全求助ws消息
  ALL_HANDLE_ALARM: 'ALL_HANDLE_ALARM', // 各个模块处理报警触发事件(用某模块报警处理报警,触发layout页面事件)
  CLICK_ALL_PAGE: 'CLICK_ALL_PAGE', // 点击了全局点击
  CLICK_ALL_PAGE1: 'CLICK_ALL_PAGE1', // 模拟ws推送数据
  NurseList: 'NurseList', // 白板管理页面是否需要清除缓存
  /* 安全求助2.0.0 */
  SECU_ALARM: 'SECU_ALARM', // 安全求助报警
  SECU_ALARM_PROCESSED: 'SECU_ALARM_PROCESSED', // 安全求助已处理报警
  SECU_ALARM_ORDER: 'SECU_ALARM_ORDER' // 安全求助工单
};
