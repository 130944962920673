import enLocale from 'element-ui/lib/locale/lang/en';
const en = {
  // 导航栏
  navBar: {
    logout: 'logout',
    languageSet: 'language settings',
    ChangePassword: 'change password'
  },
  // 环境
  config: {
    devTitle: 'Medical Internet of things application system of the second affiliated hospital of guangzhou medical university',
    // devTitle: 'Yangjiang peoples hospital medical iot application system',
    devLoginTitle: 'Medical iot application platform'
  },
  // 登录
  login: {
    logIn: 'Login In',
    username: 'username',
    enterUsername: 'Please input user name',
    password: 'Please enter your password',
    usernameEmpty: 'The account cannot be empty',
    passwordEmpty: 'The password cannot be empty',
    versionNumber: 'version number',
    logging: 'be logging in',
    copyright: 'Copyright XINSEC technology co. , LTD. All rights reserved'
  },
  // 常见使用
  common: {
    disable: 'disable',
    normal: 'normal',
    sysSet: 'system settings',
    homePage: 'Home Page',
    second: 'second',
    min: 'minute',
    bed: 'bed',
    BedDictionary: 'Bed dictionary',
    edit: 'edit',
    other: 'other',
    hint: 'hint',
    left: 'left',
    right: 'right',
    man: 'man',
    woman: 'woman',
    unknown: 'unknown',
    none: 'none',
    have: 'have',
    inHos: 'In the courtyard',
    outHos: 'Departure from the hospital',
    cancel: 'cancel',
    confirm: 'confirm',
    all: 'all',
    name: 'name',
    sex: 'gender',
    age: 'age',
    phone: 'telephone',
    nowStatus: 'current state',
    hosNum: 'Hospital number',
    bedNum: 'Bed Number',
    listMode: 'List Mode',
    bedMode: 'Bed model',
    inpatientWard: 'inpatient ward',
    selectKeyword: 'Please enter keyword search',
    status: 'Status',
    selectStatus: 'Please select state',
    isbinding: 'Is binding',
    notBound: 'not bound',
    isbind: 'Is binding',
    change: 'change',
    operation: 'operation',
    device: 'device',
    deviceRecord: 'device Record',
    detail: 'detail',
    changeDevice: 'replace equipment',
    bindingDevice: 'Binding equipment',
    distributionEquipment: 'distribution Equipment',
    bindingSuccess: 'Binding success',
    changebindSuccess: 'Replace the success',
    unbindSuccess: 'Unbundling success',
    fiveMin: 'After five minutes',
    tenMin: 'After ten minutes',
    thirtyMin: 'Thirty minutes later',
    minLater: 'minutes later',
    pleaseInput: 'please enter',
    pleaseSelect: 'please choose',
    newSuccess: 'New success',
    remain: 'remain',
    residue: 'residue',
    speed: 'speed',
    total: 'gross',
    type: 'type',
    selectType: 'Please select a type',
    unconfirmed: 'unconfirmed',
    confirmed: 'confirmed',
    alarmType: 'Alarm Type',
    alarmInfo: 'alarm information',
    alarmTime: 'alarm time',
    confirmTime: 'acknowledging time',
    check: 'examine',
    new: 'new',
    leastValue: 'least value',
    maxValue: 'maximum value',
    createTime: 'creation time',
    updateTime: 'Update Time',
    remark: 'remark',
    del: 'delete',
    close: 'close',
    open: 'open',
    minute: 'minute',
    save: 'save',
    custom: 'custom',
    default: 'default',
    next: 'next',
    formatNum: 'Format error, numeric only',
    tenChar: 'Only ten characters can be entered',
    delSuccess: 'successfully delete',
    saveSuccess: 'save successfully',
    editSuccess: 'modify successfully',
    cancelledDelete: 'Cancelled delete',
    deviceType: 'device type',
    bindingState: 'Binding State',
    divideState: 'Divide State',
    useState: 'Use State',
    equipment: 'equipment',
    selectDeviceType: 'Please select device type',
    enterSearchKeyword: 'Please enter your search keywords',
    pptDowload: 'download the template',
    bulkImport: 'bulk import',
    binding: 'binding',
    online: 'on-line',
    outline: 'off-line',
    deviceAbnormal: 'unit exception',
    template: 'template',
    errorDataExport: 'Error in export data',
    sureDelDevice: 'Make sure to delete this device?',
    enterDeviceID: 'Please enter the device ID',
    clickUpload: 'Click on the upload',
    selectFile: 'Please select file',
    importSuccess: 'Batch import successful',
    uploadMost: 'Upload at most',
    afile: 'A file',
    selectBindState: 'Select the bind state',
    departments: 'Departments',
    selectSection: 'Please select section',
    area: 'area',
    WardName: 'Ward Name',
    WardCode: 'Ward  Code',
    areaDictName: 'area dictname',
    selectArea: 'Please select ward (search supported)',
    selectAreaTips: 'Please select ward',
    adf: 'Their departments',
    roomNumber: 'Room number',
    bedNo: 'bed No',
    bedNumber: 'bed number',
    changeBind: 'change Bind',
    unbind: 'unbind',
    relieve: 'relieve',
    replace: 'replace',
    deleteCurrentBed: 'Make sure to delete the current bed?',
    unbindCurrentDevice: 'Make sure to unbind this device?',
    changeBinding: 'Change the binding',
    changeDistribution: 'Change assignment',
    distribution: 'distribution',
    AllocationStatus: 'Allocation Status',
    selectBindType: 'Select the binding type',
    changeBindSuccess: 'change to success',
    selsectRoomNum: 'Please choose your room number',
    enterRoomNum: 'Please enter your room number',
    selsectBedNum: 'Please choose your bed number',
    enterBedNum: 'Please enter the bed number',
    messageRemind: 'message reminding',
    affirm: 'affirm',
    affirmSuccess: 'confirmation successful',
    remindTimeOver: 'Time to remind',
    processed: 'processed',
    unprocessed: 'unprocessed',
    viewed: 'viewed',
    transboundaryAlarm: 'Transboundary alarm',
    oneButtonAlarm: 'one button Alarm',
    dismantlTheAlarm: 'dismantl The Alarm',
    cancelHelp: 'cancel help',
    level: 'level',
    class: 'level',
    getInto: 'get into',
    leave: 'leave',
    processingTime: 'processing time',
    handle: 'handle',
    year: 'year',
    month: 'month',
    reqERR: 'Request error',
    for: 'for',
    deleting: 'deleting',
    deleting1: 'Delete completely',
    modify: 'modify',
    operationS: 'Successful operation',
    gotIt: 'got it',
    optionDate: 'option date',
    startTime: 'Start Time',
    endTime: 'End Time',
    selectTimeRange: 'Select time range',
    floor: 'floor',
    tower: 'floor',
    stop: 'stop',
    ward: 'ward',
    hosBed: 'hospital bed',
    success: 'success',
    admissionTime: 'admission Time',
    outHosTime: 'Time of departure from the hospital',
    deviceName: 'device name',
    coord: 'coord',
    address: 'address',
    importFromTemplate: 'Import from the template',
    No: 'No.',
    with: 'with',
    shouldBluetoothBracelet: 'Should Bluetooth Bracelet',
    viewLocation: 'View location',
    OneKeyProcessing: 'One button processing',
    closedAndProcessed: 'Closed',
    AlarmLevel: 'Alarm Level',
    handleSuccess: 'Handle a successful',
    alarmTips: 'Alarm tone has been turned off. Do you need to turn it on?',
    yes: 'yes',
    no: 'no',
    search: 'search',
    selectDateTips: 'Please select date',
    selectEndTimeTips: 'Please select the end time',
    processInfo: 'process information',
    cantSeeMapTips: 'Unable to view map location',
    screen: 'screen',
    years: 'years of age',
    editProfile: 'Edit Profile',
    forDetails: 'For details',
    hardLoading: 'Hard loading',
    noData: 'no data',
    add: 'add',
    inputName: 'Please input your name',
    selectDateTime: 'Select date time',
    endTimeCannotEmpty: 'The end time cannot be empty',
    timeFormatNotCorrect: 'The time format is not correct',
    chooseEarlierThan: 'You cant choose earlier than that',
    patientIDcannotEmpty: 'Patient ID cannot be empty',
    category: 'category',
    noDataFound: 'No data found',
    nothing: 'nothing',
    grade: 'grade',
    updateFailedTips: 'Update failed, please try again',
    activated: 'Activated',
    AlarmFromHospital: 'Alarm from hospital',

    StartDate: 'Start date',
    AllOneClickProcessing: 'All one click processing',
    historicalInformation: 'Historical information',
    syncSuccess: 'Sync success',
    strip: '',
    Binding: 'Binding',
    bedID: 'Bed ID',
    enterSearchBedNum: 'Please enter your search bed number',
    Bed: 'Bed',
    NewBed: 'New bed',
    Assigned: 'Assigned',
    Unassigned: 'Unassigned',
    ItisBottom: 'It is the bottom',
    ReleasedSuccessfully: 'Released Successfully',
    AssignmentSuccessful: 'Assignment Successful',
    TipsInfo: 'Tips info',
    DirectProcessing: 'direct Processing',
    ProcessAndComment: 'process and comment',
    alarmLocation: 'alarm Location',
    NoNews: 'No news',
    NoprocessNews: 'Historical unprocessed messages',
    NowUnProcessNews: 'Currently unprocessed messages',
    NowUnReadNews: 'Un read message',
    DealAll: 'Deal with it all',
    choice: 'choice',
    addName: 'Add name',
    Scheduling: 'Scheduling',
    Reporting: 'Reporting',
    amount: 'amount',
    retry: 'retry',
    detailed: 'detailed',
    determine: 'determine',
    RealTimeLocation: 'Real time location',
    date: 'Date',
    TipsTime: 'Tips time ',
    function: 'function',
    selectRegion: 'Please select area'

  },

  // 语言
  language: {
    tips: 'Switching language successful'
  },
  // home页
  home: {
    title: 'Welcome to XINSEC Internet of things system',
    // title: 'Welcome to the intelligent infusion system',
    loadingText: 'Desperate Loading',
    clickLeft: 'Please click the left menu bar for relevant operations'

  },
  // 智能输液
  transfusion: {
    inTheInfusion: 'In the infusion',
    stopInfu: 'Suspension of infusion',
    infusionInsuf: 'The absence',
    infusionOut: 'Will lose out',
    weightAbnormal: 'gravimetric anomaly',
    deviceOffline: 'device offline',
    infusionOver: 'Liquid be totally lost',
    aneroidAlarm: 'Aneroid alarm',
    speedAnomalies: 'Dripping speed anomalies',
    infusionJam: 'Infusion jam',
    speedToFast: 'Dripping speed too fast',
    speedToSlow: 'Dripping speed too slow',
    batteryLow: 'battery low',
    batteryAmple: 'sufficient charge',
    lowBattery: 'low battery',
    callHelp: 'Call for help',
    sort: 'sort',
    bedSort: 'Bed number sort',
    marginSort: 'margin sort',
    endSort: 'Expected time sort',
    expectedEnd: 'Is expected to end',
    liquidMargin: 'Liquid allowance',
    electricQuantity: 'electric quantity',
    timeRemind: 'Time to remind',
    marginRemind: 'Allowance to remind',
    memo: 'memo',
    memorandum: 'memorandum',
    margin: 'margin',
    infusionTerminal: 'Infusion terminal',
    selsetTerminal: 'Please select the ID of infusion terminal',
    wirelessPager: 'Wireless pager',
    selectWireless: 'Please select the wireless pager ID',
    unbindTerminal: 'Untie the infusion terminal',
    unbindWireless: 'Untether the wireless pager',
    unbindTerminalText: 'If the binding is removed, the infusion progress cannot be monitored in real time. Please be careful!',
    isCheckUnbindTerminal: 'Do you want to unbind the terminal?',
    unbindWirelessText: 'Unbind and the patient cannot seek help. Please be careful!',
    isCheckUnbindWireless: 'Do you want to unbind the wireless pager?',
    remindWay: 'Remind the way',
    customContent: 'Custom Content',
    residualFluidVolume: 'Residual fluid volume',
    editContent: 'prompt content',
    tipsTime: 'reminderTime',
    enterTipsTime: 'Please enter reminder time',
    remindConditions: 'Remind conditions',
    closeRemind: 'Close to remind',
    selectRemindTime: 'Please select the reminder time',
    enterMargin: 'Please enter margin',
    closeRemindSuccess: 'Turn off reminder successful',
    closeRemindText: 'Do you want to turn off the current reminder?',
    medicalInfo: 'Medical information',
    criticalCare: 'critical care',
    docAdvice: 'The doctors advice',
    drugNames: 'drug names',
    usage: 'usage',
    HZ: 'frequency',
    ST: 'skin test',
    openToldTime: 'Open told time',
    executionTime: 'execution time',
    collator: 'collator',
    executor: 'executor',
    infuDtails: 'Infusion details',
    infuDetector: 'Infusion terminal',
    deviceNum: 'device ID',
    BindBedNo: 'Binding bed no.',
    tareWeightSet: 'Tare weight set',
    spec: 'specification',
    selectSpec: 'Please select specification',
    nominalCap: 'standard capacity',
    selectNominalCap: 'Please select capacity',
    tare: 'tare',
    thresholdManage: 'threshold management',
    dripNum: 'Infusion drip coefficient',
    inputDripNum: 'Please enter drip coefficient',
    selectDripNum: 'Please select the drop coefficient',
    inputDripText: 'The number of drops per ml of solution is the drop coefficient',
    dripSpeed: 'Infusion dripping speed',
    enterMinDrip: 'Please enter the minimum drip rate',
    until: 'until',
    enterMaxDrip: 'Please enter the maximum drop rate',
    dropAndmin: 'Drops / min',
    otherSet: 'other settings',
    timeRemain: 'time remaining',
    inputTimeRemain: 'Please enter the remaining time',
    liquidRatio: 'Residual liquid ratio',
    inputLiquidRatio: 'Please input the proportion of remaining liquid',
    selectUnit: 'Please select unit',
    ReportInter: 'Reporting interval',
    TerminalInter: 'Terminal data escalation interval',
    enterMinNum: 'Please enter the minimum number',
    selectFrequency: 'Please select frequency',
    fiveSecondsFitSug: 'Suggestion',
    fiveSecondsFitApr: 'is appropriate',
    record: 'Note: the default time is recommended, if you need to customize the advice to consult the administrator',
    typeSet: 'Typemtic Rate Setting',
    oneself: 'oneself',
    thirdParty: 'third party',
    selectReminder: 'Please select reminder mode',
    inputMinRate: 'Please enter the minimum droplet rate',
    inputMaxRate: 'Please enter the maximum drop rate',
    inputUnit: 'Please enter unit',
    removeSet: 'Are you sure to remove this setting?',
    dropText: 'The maximum drop rate cannot be less than or equal to the minimum drop rates',
    pleaseSupplement: 'Please supplement the starting range of infusion rate',
    inputNominalCap: 'Please enter standard capacity',
    inputTareWeight: 'Please input tare weight',
    enterTareMax: 'Please enter maximum tare weight',
    enterTareMin: 'Please enter minimum tare weight',
    enterRemarks: 'Please enter remarks within 50 words',
    content50Char: 'The content is over 50 characters long',
    content16Char: 'The content is over 16 characters long',
    enterCapacity: 'Please enter capacity',
    enterTareWeight: 'Please enter tare weight',
    enterMinValue: 'Please enter the minimum value',
    enterMaxValue: 'Please enter maximum value',
    terminalExport: 'Infusion management - terminal equipment export',
    terminalImport: 'Batch import of infusion terminal',
    wirelessImport: 'Batch import of wireless pager',
    bedBatchImport: 'Bed batch introduction',
    terminalBindingStatus: 'Infusion terminal binding status',
    wirelessBindingStatus: 'Wireless pager binding state',
    delFailedText: 'Sorry, deletion failed',
    bindDeviceText: 'There is a binding device in the current bed, please unbind it and operate it again',
    selectInfoTerminal: 'Please choose infusion terminal',
    selectInfoWireless: 'Select a wireless pager',
    addinfuChannel: 'Add infusion channel',
    deviceBallast: 'Device in ballast',
    pleaseBindTerminal: 'Please bind the infusion terminal',
    lightOff: 'Light off',
    ReportInterTipM: 'Failed to submit, input content should be no less than 2 minutes',
    ReportInterTipS: 'Failed to submit, input content should be no less than 120 seconds',
    setAddFailTip: 'Failed to submit. The minimum value cannot be greater than the maximum value. Please modify and try again',
    setAddFailTipT: 'Failed to submit. The maximum value cannot be less than the tare weight. Please modify it and try again',
    positive: 'positive',
    feminine: 'feminine',
    dropMinute: 'Drop/Minute',
    reminderMethod: 'Reminder method',
    countDrops: 'Calculating droplet velocity',
    enterDrops: 'Please input the calculated dropping speed',
    enterRange: 'Range of values that can be entered',
    PreVerification: 'Pre allocation verification',
    VerificationFailure: 'Verification failure can be temporarily used',
    VerificationFailure1: 'Verification failure cannot be used temporarily',
    ThirdPartyCallers: 'Third party callers',
    DropRateDisplay: 'Drop rate display',
    OnlyEntered: 'Only positive integers between 30-60 can be entered',
    OnlyEntered1: 'Only positive integers between 1-25 can be entered',
    ToBeInfused: 'To be infused',
    treatmentCount: 'Statistics of treatment items in ward',
    individualCount: 'Statistics of individual processing items',
    selectPerson: 'Please select specific personnel',
    CurrentInfusion: 'Current infusion number',
    todayInfusion: 'Number of infusion today',
    todayAlarm: 'Number of alarms today',
    todayCall: 'Number of calls today',
    currentTerminalPow: 'Current low power quantity of infusion terminal',
    currentWirelessPow: 'Current low battery number of wireless callers',
    AlarmTypeCount: 'Alarm type proportion statistics',
    infusionEndTypeCount: 'Statistics of the proportion of infusion end types',
    AlarmProcessingTimes: 'Alarm processing times',
    callHelpHandlingTime: 'Call help handling times',
    infusionOperaHandlingTime: 'Handling times of infusion operation',
    frequency: 'frequency',
    CallForHelp: 'Call for help',
    speedAlarm: 'Abnormal dropping speed alarm',
    MemoAlarm: 'Memo Alarm',
    infuBlockageAlarm: 'Infusion blockage',
    fastSpeedAlarm: 'Alarm for too fast dropping speed',
    slowSpeedAlarm: 'Alarm for slow dripping speed',
    PDAEnd: 'PDA end infusion shutdown',
    btnShowdown: 'Physical button shutdown',
    AutoShutdown: 'Automatic shutdown',
    BuiltInLogo: 'Built In Logo',
    CustomIdentity: 'Custom Identity',
    enterRFID: 'Please enter the built-in passive RFID tag ID',
    enterNum: 'Please enter the number',
    sixLength: '1 to 6 characters in length',
    fiftyLength: '1 to 50 characters in length',
    CustomNumber: 'Custom Number',
    DropRate: 'Drop Rate',
    bedPosition: 'bed position',
    closeAdvance: 'Collapse advanced search',
    Advance: 'Advanced search',
    ManualConfirmation: 'Manual confirmation',
    SystemValidation: 'System Validation',
    patientsNameShow: 'Patient name display',
    desensitization: 'desensitization',
    Nodesensitization: 'No desensitization is required',
    drugSearch: 'Drug search'
  },
  // 病患管理
  care: {
    sendHelp: 'Send a button for help',
    wristbandRemoved: 'wristband was removed',
    CancelHelp: 'Cancel one button for help',
    Explain: 'Processing instructions',
    statisticalMonth: 'Statistical Month',
    chooseYM: 'Choose year and month',
    patientPopulation: 'patient population',
    people: 'peopel',
    Nostatistical: 'No statistical data available',
    SummaryFigures: 'Summary of figures',
    searchName: 'Please enter name search',
    checkOut: 'Temporary check-out',
    signOut: 'check out',
    signIn: 'check in',
    checkIn: 'Check-in',
    outTime: 'Check-out time',
    inTime: 'Check-in time',
    outOperator: 'Check out operator',
    inOperator: 'Check in operator',
    locateFailureTips: 'Positioning failed!please try again or check the bracelet later. If there is an emergency, please contact the administrator',
    locateFailureTips2: 'Positioning failed!Please check whether the device is abnormal or the network is disconnected!',
    patientsName: 'The patients name',
    cancelTrack: 'Cancel the track',
    realtimeTrack: 'real-time tracing',
    historicalTrack: 'historical track',
    queryTrajectory: 'Query the trajectory',
    TrajectoryQuery: 'Track the query',
    trackList: 'Track list',
    showTrajectories: 'Show Trajectories',
    enterPatientName: 'Please enter patient name',
    checkTrajectoryTips: 'The trajectory of the query may contain other trajectories of the patient whose historical trajectory should be',
    timeRange: 'Time range',
    bracelet: 'bracelet',
    location: 'location',
    braceletSet: 'Bracelet set',
    record: 'record',
    heartRate: 'Heart Rate',
    willPatient: 'Will the patient',
    inHosRecords: 'The hospital records',
    hosSerialNum: 'Hospital serial number',
    elapsedTime: 'elapsed Time',
    operationalContext: 'Operation procedure',
    fornowTips: 'There is no operation record',
    admittedHos: 'Admission to Hospotial',
    leaveHos: 'Discharged',
    changeMajor: 'The transfer factor',
    penName: 'Pen name',
    intoAlarm: 'into Alarm',
    departure: 'departure',
    checkFencePosition: 'Check fence position',
    enterBraceletLabel: 'Please enter the bracelet label',
    selectBraceletType: 'Please select the bracelet type',
    alarmFence: 'Alarm fence',
    unbindBracelet: 'Unbundling bracelet',
    selectBraceletLabel: 'Please select the bracelet label',
    fenceAlarm: 'A fence alarm',
    oneKeyHelp: 'A key for help',
    braceletWasRemoved: 'The bracelet was removed',
    callForHelp: 'Issue a button for help',
    cancelForHelp: 'Cancel one click for help',
    crossBorder: 'cross the border',
    retention: 'bedetained',
    levelFence: 'Level of a fence',
    levelSet: 'Level set',
    fenceLevelSet: 'Fence grade setting',
    ecgPasteID: 'Ecg Paste ID',
    bloodOxygenClampID: 'Blood Oxygen Clamp ID',
    temperaturePasteID: 'Temperature Paste ID',
    temperatureBoxID: 'Temperature Box ID',
    divideBed: 'Divide Bed',
    useBed: 'Use Bed'
  },

  assets: {
    enterChannels: 'Please enter channels',
    houseEntities: 'house of entities',
    fillMeasurement: 'Please fill in the unit of measurement',
    enterSNnum: 'Please enter the SN number of the asset',
    back: 'back',
    PreviousStep: 'Previous step',
    NextStep: 'Next step',
    AssetCode: 'AssetCode',
    WardManage: 'WardManage',
    deptManage: 'administrative department',
    print: 'print',
    StorageTime: 'StorageTime',
    ResidualRate: 'ResidualRate',
    model: 'model',
    BasicMessage: 'BasicMessage',
    SecurityTime: 'SecurityTime',
    PleaseSelectManagementWard: 'PleaseSelectManagementWard',
    AssetSNCode: 'AssetSNCode',
    AssetName: 'AssetName',
    user: 'user',
    PleaseInputUser: 'PleaseInputUser',
    StorLocation: 'StorLocation',
    DistrictCourt: 'DistrictCourt',
    Loudong: 'Please select the building',
    LoudongIn: 'Please input building',
    storey: 'storey',
    userTime: 'userTime',
    Month: 'Month',
    UnitMeasure: 'UnitMeasure',
    source: 'source',
    SelectSource: 'SelectSource',
    Classification: 'Classification',
    PurchaseTime: 'PurchaseTime',
    money: 'money',
    brand: 'brand',
    PleaseSelectBrand: 'PleaseSelectBrand',
    WarrantyStart: 'WarrantyStart',
    channel: 'channel',
    ExtendedInformation: 'ExtendedInformation',
    configuration: 'configuration',
    Additional: 'Additional',
    save: 'save',
    yuan: 'yuan',
    Code: 'Code',
    Name: 'Name',
    classification: 'classification',
    SelectCategory: 'Select Category',
    MapMode: 'MapMode',
    PrintLabel: 'PrintLabel',
    ImportAssets: 'Import Assets',
    AssetsInstock: 'Assets In stock',
    Label: 'Label',
    BoundLabel: 'BoundLabel',
    more: 'more',
    borrow: 'borrow',
    return: 'return',
    maintain: 'maintain',
    MaintenanceCompleted: 'Maintenance Completed',
    scrap: 'scrap',
    lose: 'lose',
    AssetInformation: 'AssetInformation',
    OperationRecords: 'OperationRecords',
    LocationRecords: 'LocationRecords',
    ElectronicSignature: 'ElectronicSignature',
    LoanOfAssets: 'Loan Of Assets',
    BorrowWard: 'Borrow Ward',
    ChooseAreaToUse: 'Choose Area To Use',
    BorrowTime: 'Borrow Time',
    BorrowUser: 'Borrow User',
    EnterTheBorrower: 'Enter The Borrower',
    LabelBinding: 'LabelBinding',
    Binded: 'Binded',
    LabelType: 'LabelType',
    BluetoothTab: 'BluetoothTab',
    SelectTab: 'SelectTab',
    NormalLabel: 'NormalLabel',
    SelectTabType: 'SelectTabType',
    AlarmFence: 'AlarmFence',
    CategoryOfAssets: 'CategoryOfAssets',
    UseOfTheWard: 'UseOfTheWard',
    IntoTheLibrary: 'IntoTheLibrary',
    Modifier: 'Modifier',
    ModifyTime: 'ModifyTime',
    Months: 'Months',
    SNNumber: 'SNNumber',
    AssetsLocation: 'AssetsLocation',
    ViewMapLocation: 'ViewMapLocation',
    time: 'time',
    location: 'location',
    NoLocationInformationYet: 'NoLocationInformationYet',
    ImportFromTemplate: 'Import From Template',
    SequenceNumber: 'SequenceNumber',
    NameOfFence: 'NameOfFence',
    GetIntoTheAlarm: 'Get Into The Alarm',
    LeaveThePolice: 'Leave The Police',
    CheckTheFence: 'Check The Fence',
    Operator: 'Operator',
    ApplyArea: 'ApplyArea',
    Claimant: 'Claimant',
    OperationContent: 'Operation Content',
    NoOperationalRecord: 'No Operational Record',
    ImportInTemplate: 'Import In Template',
    TemplateName: 'Template Name',
    leadingIn: 'leading In',
    property: 'property',
    ViewDetails: 'View Details',
    SelectBorrowingTime: 'Please select borrowing time',
    EnterAssetCode: 'Please enter the asset code',
    EnterAssetName: 'Please enter an asset name',
    CodeTips: 'Asset code can not exceed 20 characters',
    NameTips: 'Asset name can not exceed 20 characters',
    AssetClassification: 'Select Asset Classification',
    SelectManagement: 'Please select Management Section',
    SelectStoragTime: 'Please select storage time',
    SelectPurchaseTime: 'Please select purchase time',
    ModelTips: 'Model size must not exceed 20 characters',
    UnitTips: 'Unit of measure should not exceed 10 characters',
    ChannelTips: 'Channel can not exceed 50 characters',
    AssetSNNTips: 'Asset SNN must not exceed 50 characters',
    ConfigureTips: 'Configure not more than 200 characters',
    AdditionalTips: 'Additional configuration should not exceed 200 characters',
    ApplyTime: 'ApplyTime',
    Download: 'Download',
    ChangeApplicant: 'ChangeApplicant',
    OriginalC: 'Original claimant',
    NewApplicant: 'New Applicant',
    ConfirmDownload: 'Confirm Download',
    EditSuccess: 'Editing successfully',
    DownloadPre: 'Download Preview',
    FormatTips: 'FormatTips',
    EnterAmount: 'Please enter the amount',
    FillInteger: 'Please fill in the integer',
    LessThanZ: 'Can not be less than 0',
    FillUseLife: 'Please fill in the useful life',
    AssetManage: 'Asset management',
    EditAssets: 'Edit Assets',
    ErrorTips: 'Error fetching data',
    UploadFormat: 'Upload avatar images can only be JPG, JPEG, PNG, GIF format',
    UploadExceed: 'Upload picture size can not exceed 3 MB!',
    AddOne: 'Add up to one picture',
    MissingID: 'Missing Asset ID',
    ErrorRequest: 'Error requesting',
    selectTab: 'Select tab to print',
    limitTips: 'The number of passive RFID tag bindings has reached the limit',
    ReturnSucc: 'Return successful',
    number: 'number',
    ImportSucc: 'Import successful',
    floor: 'floor',
    FenceIDEmpty: 'Fence ID is empty',
    EnterAssets: 'Please enter assets',
    search: 'search',
    EnteBrand: 'Please enter brand name',
    alarmProcessing: 'alarm processing',
    enterAssets: 'Please enter assets',
    enterAssetsTips: 'Please enter the asset code or name',
    assetsModel: 'Asset types',
    totalAssets: 'Total assets',
    idleAssets: 'Idle assets',
    borrowAssets: 'Borrow assets',
    normalAssets: 'Normal assets',
    useAssets: 'Use assets',
    maintenanceAssets: 'Maintenance assets',
    scrapAssets: 'Abandoned assets',
    loseAssets: 'Loss assets',
    statusPercentage: 'status Percentage',
    endemicArea: 'Endemic Area Distribution',
    timeDistribution: 'Time distribution statistical year',
    selectYear: 'Please select year',
    classifyStatistics: 'No classified statistics are available',
    statusStatistics: 'No state statistics',
    areaStatistics: 'No regional statistics were available',
    timeStatistics: 'No time distribution statistics are available',
    leisure: 'leisure',
    claimed: 'claimed',
    apply: 'apply',
    enterClassify: 'Please enter category name search',
    importClassify: 'The import classification',
    addClassify: 'The new classification',
    classifyName: 'classify name',
    categoryParent: 'category parent',
    LengthTips: 'Length 1 to 50 characters',
    ChooseFill: 'Please choose to fill in the remarks',
    LengthTips2: 'Length 0 to 50 characters',
    AddSuccess: 'Add Success',
    BrandName: 'BrandName',
    CategoryName: 'Category',
    UpperClassify: 'UpperClassify',
    SelectSCategory: 'Please select superior category',
    exampleT: 'For example: Tai',
    EnteCategory: 'Please enter a category name',
    FillmeasureInfo: 'Please fill in the unit of measurement information',
    LengthTips3: 'Length 0 to 10 characters',
    LengthTips4: 'Length 0 to 200 characters',
    EnterToSearch: 'Please enter a category name search',
    ImportClassify: 'Import Classification',
    NewClassify: 'New Classification',
    del: 'delete',
    EnterBrand: 'Enter brand name search',
    NewBrand: 'New brand',
    OnlyPrint: 'Only print at most',
    tabs: 'tabs',
    EndOfLife: 'Conduct end-of-life operations',
    LossOperate: 'Conduct loss operations',
    Return: 'Return',
    IdleAssets: 'Idle',
    BorrowAssets: 'Borrowed',
    NormalAssets: 'Normal',
    MaintenAssets: 'Maintenance',
    EndOfLifeAssets: 'End-of-life',
    LostAssets: 'Lost',
    screen: 'screen',
    NoDisaggregated: 'No disaggregated statistics available',
    StatusRatio: 'Status ratio',
    TemporaryStatus: 'Temporary status statistics',
    Distribution: 'Disease area distribution',
    NoWardArea: 'Statistics on no ward area',
    TimeDistribution: 'Time distribution statistical year',
    SelectYear: 'Select Year',
    NoTimeDistribution: 'No time distribution statistics available',
    TaskName: 'Task name',
    SelectAssets: 'Select assets',
    AssetsSelected: 'Assets selected on a conditional basis',
    Numberselected: 'Number of selected assets',
    SelectExecutor: 'Select Executor',
    TaskDescrip: 'Task Description',
    Tip: 'Tip: General inventory only for assets with RFID tags.',
    chooseAssets: 'Please choose assets according to assets or according to conditions',
    EnterTask: 'Please enter a task name',
    selectAssets: 'Select Assets',
    LengthTips5: 'Length within 200 characters',
    InventAssets: 'Inventory of assets',
    EditTasks: 'Edit Asset Inventory Tasks',
    AdditionalTask: 'Additional asset count task',
    Tip2: 'Tip: Automatic inventory is only available for all Bluetooth tags.',
    ImplementInvent: 'Implementation Inventory',
    LengthTips6: 'Length within 50 characters',
    InventSucc: 'Inventory success',
    result: 'result',
    SelectResults: 'Please select the results of the inventory',
    Deficiencies: 'Deficiencies',
    NotYetInvent: 'Not yet inventoried',
    EnterAsset: 'Please enter an asset name search',
    InventResults: 'Inventory results',
    NoCount: 'No count',
    TotalTime: 'Total Time',
    TaskType: 'Task type',
    AutoInvent: 'Automatic Inventory',
    ManualInvent: 'Manual Inventory',
    TotalAssets: 'Total assets of tasks',
    InventResult: 'Asset inventory results',
    InventInfo: 'Inventory information',
    InventDetails: 'Inventory Details',
    SelectByAssets: 'Selected by assets',
    allAssets: 'Total assets',
    NumberSelected: 'Number of assets selected',
    ChoosebyC: 'Choose by Condition',
    AllCategory: 'All Categories',
    selectCategory: 'Selected Categories',
    NoDataSelect: 'No data selected',
    SelectTabTab: 'Select tab tab',
    NotStart: 'Not started',
    InProgress: 'In progress',
    Finished: 'Finished',
    NewInventTask: 'New Inventory Task',
    InventoryNum: 'Inventory Number',
    WaitStart: 'Waiting for automatic count to start',
    WaitCountResults: 'Waiting for automatic count results',
    WaitingResults: 'Waiting for results',
    ExportD: 'ExportDetail',
    ViewResults: 'ViewResult',
    ResultOfInvent: 'Asset inventory-results of inventory-',
    BorrowSucc: 'Borrowing success',
    SavedAssets: 'Saved assets',
    SelectClassify: '-- Please select superior classification --',
    enterAssetName: 'Please enter the asset name',
    fillNumber: 'Please fill in the model number',
    deliveryTime: 'delivery time'
  },
  emer: {
    EmerFoundationSetup: 'Basic Settings',
    CategoryNameTips1: 'The category name can not be empty',
    CategoryNameTips2: 'The category name can not exceed 20 characters',
    RemarksTips: 'Remarks can not exceed 200 characters',
    LCstatisticalReports: 'LINK COMPARISON-STATISTICAL REPORTS -',
    Emer: 'Emergency',
    GodIn: 'In the mind',
    GodOut: 'God is not in the picture',
    Intervene: 'Intervene',
    LinkCompare: 'Link comparison',
    NoTreatOption: 'There is no treatment option',
    NoStatisticsYet: 'No statistics yet',
    MeanTimeOfAve: 'Mean Time of arrival',
    WhereTimeOut: 'Where the time-out',
    Examples: 'Examples',
    Percentage: 'Percentage',
    Standards: 'Standards',
    DTStoStroke: 'DTS Primer to stroke group consultant',
    TimeDTCAndCt: 'Time between beginning of DTC and completion of Ct.',
    TimeDNTAndAdmin: 'Time between introduction of DNT and administration of thrombolytic therapy',
    TimeOttAndTreat: 'Time between Ott onset and treatment',
    OPTtoFemoral: 'OPT induction to femoral artery puncture time',
    DRTtoRepass: 'RE-PASS DRT induction to re-pass time',
    TimeRange: 'Time Range',
    SelectTimeRange: 'Please select a time range',
    StartDate: 'Start date',
    EndDate: 'End Date',
    Hours: 'Hours',
    bindChestCard: 'binding chest card',
    pleaseInputMac: 'Please enter the MAC address',
    macAddress: 'MAC address',
    macTips: 'MAC address cannot be empty',
    macTips2: 'MAC addresses can only contain English letters and Numbers',
    realTimePatient: 'Real-time patient',
    minuteAutomaticRefresh: 'Minute automatic refresh',
    autoCreatePatients: 'Automatically create patients',
    addPatient: 'Add the patient',
    patientsInfo: 'information for patients',
    clearLink: 'Empty the link',
    endLink: 'The end of the link',
    completed: 'Completed',
    stayTime: 'The stay time',
    entryTime: 'entry time',
    leaveTime: 'departure time',
    delLinkRecords: 'Delete the link record?',
    clearLinkRecords: 'Clean up all of the patients records?',
    enterSNnumber: 'Please enter SN number',
    medicalRecordNo: 'Medical record No',
    emerClassify: 'Emergency classification',
    enterNameTips: 'Please enter a name of less than 10 characters',
    snNoEmpty: 'SN cannot be empty',
    snNoTips: 'SN number can only contain English characters and Numbers',
    snNoTips2: 'The SN number cannot exceed 12 characters',
    pleaseEnterNumber: 'Please enter number',
    ageCannotZero: 'Age cannot be less than 0',
    ageCannotMax: 'You cant be older than 999',
    medicalNoTips: 'Medical record number cannot exceed 50 characters',
    medicalNoTips2: 'Remarks cannot exceed 200 characters',
    chooseToLeave: 'Choose to leave the time and end the link',
    waitingStar: 'Waiting to start',
    chestCard: 'Chest card',
    beginLink: 'Began to link',
    nameOfLink: 'Fill in the name of link',
    chooseFence: 'Choose a fence',
    midstLink: 'midst link',
    linkTips: 'At least one link needs to be preserved',
    fenceNotEmpty: 'The fence must not be empty',
    linkTips2: 'The link name cannot be empty',
    linkTips3: 'Link name cannot exceed 20 characters',
    fencesTips: 'Fences cannot be selected repeatedly',
    exportTimeTable: 'Export the time management table',
    recordTime: 'record Time',
    timeSheet: 'time sheet',
    hisPatient: 'History of the patient',
    hospitalID: 'Outpatient/hospital ID',
    emerDoctor: 'Emergency doctor',
    nerveDoctor: 'Nerve physician',
    neurDoctor: 'neurosurgeon',
    InterDoctor: 'Intervention group doctor',
    disease: 'disease',
    diseaseTime: 'disease Time',
    comingToHospital: 'Way of coming to hospital',
    ourHos: 'Our hospital',
    outerHos: 'Outer court',
    selfHos: 'Come to the hospital voluntarily',
    inTheHos: 'Nosocomial disease',
    toCourtDate: 'To the hospital date',
    preHos: 'Pre hospital',
    timeForHelp: 'Time for help',
    ambulanceTime: 'Ambulance departure time',
    timeOfArrival: 'Time of arrival/first medical contact',
    timeOfTransit: 'Departure/transit time',
    clinicalReception: 'clinical reception',
    timeOfArrivalAtGate: 'Time of arrival at gate',
    timeOfMedical: 'Time of first medical contact',
    actualPassTime: 'Actual green pass time',
    emerDoctorTime: 'Emergency doctor attendance time',
    apoplexyTime: 'Inform the stroke consultant of the time',
    arrivalTime: 'arrival time',
    bloodTime: 'Blood drawing completion time',
    bloodDeliveryTime: 'Notify the blood delivery time',
    bloodDrawingReport: 'Report time of blood drawing (rapid blood glucose)',
    bloodDrawingReport2: 'Blood test report time (routine blood test) ',
    adviseDeliveryTime: 'Advise delivery time',
    deliveryAdviseTime: 'Delivery arrival time',
    sendingCTtime: 'Sending CT time',
    timeOfCTroom: 'Time of arrival in CT room',
    timeOfHead: 'Time of initial head CT',
    CTcompletionTime: 'CT completion time',
    CTAcompletionTime: 'CTA completion time (if necessary)',
    reportTime: 'report time',
    consciousness: 'consciousness',
    questions: 'Questions',
    instruction: 'Instruction',
    stare: 'stare',
    view: 'view',
    facioplegia: 'facioplegia',
    upLeft: 'upper left',
    upRight: 'upper right',
    lowerLeft: 'left lower',
    lowerRight: 'lower right',
    freemasonry: 'Freemasonry',
    feel: 'feeling',
    aphasia: 'aphasia',
    articulation: 'articulation',
    neglect: 'neglect',
    totalPoints: 'total points',
    treatment: 'treatment method',
    thrombolysis: 'Simple intravenous thrombolysis',
    thrombolyticDrug: 'thrombolytic drug',
    dateTreatment: 'Date of treatment',
    thrombectomy: 'Simple arterial thrombectomy',
    bridging: 'Bridging (thrombolysis + thrombolysis)',
    chooseReason: 'the reason to choose',
    intravenous: 'intravenous thrombolysis',
    otherReason: 'Other reasons',
    thrombolytic: 'Thrombolytic administration time',
    punctureTime: 'Femoral artery puncture time/angiography start time',
    therapyTime: 'Time of initiation of intravascular therapy',
    recanalization: 'recanalization',
    recanalizationTime: 'recanalization time',
    patientsTo: 'Patients to',
    centre: 'centre',
    neurologyDepart: 'neurology department',
    neurosurgeryDepart: 'neurosurgery department',
    viewHos: 'The view from the hospital',
    aroundEmer: 'Around the emergency',
    emerDeath: 'Emergency death',
    hospitalization: 'hospitalization',
    carotidEndarterectomy: 'carotid endarterectomy',
    carotidArteryStenting: 'carotid artery stenting',
    hematoma: 'Removal of craniotomy hematoma',
    aspiration: 'Stereotactic hematoma aspiration',
    outcomePatients: 'Outcome in patients with',
    significantComplication: 'significant complication',
    symptomaticHemorrhage: 'Symptomatic hemorrhage',
    die: 'die',
    selectTreatmentTime: 'Please select treatment time',
    SequenceNumberTip: 'The sequence number must be a number',
    SequenceNumberExceed: 'The sequence number cannot exceed 10 digits',
    othersTips: 'Others cannot exceed 100 characters',
    scoreNumberTips: 'The score can only be 0 to 10 integers',
    ToHospital: 'To the hospital',
    ChooseTreatment: 'Please choose your treatment',
    AllOfThem: 'All of them',
    EmptyIt: 'Empty it',
    ReverseElection: 'Reverse election',
    TherapeuticSession: 'Therapeutic session',
    ChooseTreat: 'Please choose your treatment',
    ListNames: 'List of names',
    BatchExport: 'Batch export',
    ContactNum: 'Contact number',
    doc: 'doctor',
    IntroductFirstContact: 'Introduction - first contact',
    FirstContactgoGreen: 'First Contact - go Green',
    FirstContactTEMT: 'First CONTACT - EMT',
    FirstContactCallDoc: 'First contact - call the stroke doctor',
    EMTComInCallstrokeDoc: 'EMT coming in - call the stroke doctor',
    PageDocConsultantIsHere: 'Page the stroke doctor - the consultant is here',
    BloodDrawSugarReport: 'Blood draw - blood sugar report',
    BloodDRAWCBCReport: 'Blood DRAW - CBC report',
    FirstContactNotify: 'First contact - notify transport',
    OpenGreenNotify: 'Open a green line - notify transport',
    EmtsNotify: 'Emts - notify transport',
    ConsultantNotify: 'Consultant is here - notify transport',
    NotifyArriving: 'Notify transport - transport arriving',
    TransportHereCT: 'Transport is here - CT',
    CTCTroom: 'CT - CT room',
    ComeUpStartCT: 'Coming up on c.t.-starting C.T',
    StartCTClearCT: 'Start the CT - clear the CT',
    CTReportComplete: 'CT-CT report complete',
    TotalNumPatient: 'Total number of patients',
    NumOfMale: 'Number of male patients',
    ProportionOfMale: 'Proportion of male patients',
    NumOfFemale: 'Number of female patients',
    ProportionOfFemale: 'Proportion of female patients',
    UnknownSex: 'Unknown sex',
    UnknownSexRatio: 'Unknown sex ratio',
    CurrentCountTime: 'Current count time',
    Year: 'Year',
    Month: 'Month',
    CustomTimeFilter: 'Custom time filtering',
    AgeShare: 'Age share',
    AveLinkLength: 'Average link length',
    Under30: 'Under 30',
    to50: '31 to 50',
    to70: '51 to 70',
    Over71: 'Over 71',
    UnknownAgeRange: 'Unknown Age Range',
    UnknownEmerCategory: 'Unknown emergency category',
    DateChoseIncorrect: 'The date chosen is incorrect',
    Birthday: 'Birthday',
    HospitalNum: 'In hospital number',
    OutpatientNumber: 'Outpatient number',
    IDNumber: 'ID number',
    Nation: 'Nation',
    HospitalWay: 'Hospital way',
    ChestCardID: 'ChestCard ID',
    Saved: 'Saved',
    Submitted: 'Submitted',
    HasBeenReported: 'Has been reported',
    Diagnose: 'Diagnose',
    DataExport: 'Data export',
    PatientQualityControlForm: 'Patient quality control form',
    DoctorQualityControlForm: 'Doctor quality control form',
    NIHSSGrade: 'NIHSS grade',
    TreatmentOfType: 'Treatment of type',
    ONT: 'ONT',
    DNT: 'DNT',
    OPT: 'OPT',
    DPT: 'DPT',
    DRT: 'DRT',
    ThePercentageFilledIn: 'The percentage filled in',
    LastUpdateTime: 'Last update time',
    TimerShaft: 'Timer shaft',
    TimeTable: 'Time table',
    FirstAidMessage: 'First aid message',
    ConsultationMessage: 'Consultation message',
    Thrombolysis: 'Thrombolysis',
    ApoplexyDoctor: 'Apoplexy doctor',
    IntravenousDoctor: 'Intravenous doctor',
    InterveneDoctor: 'Intervene doctor',
    TimeOfFindingSymptoms: 'Time of finding symptoms',
    LastNormalTime: 'Last normal time',
    WhetherToHaveAStrokeAfterWakingUp: 'Whether to have a stroke after waking up',
    TimeOfCerebralInfarction: 'Time of cerebral infarction',
    Known: 'Known',
    WakeUpStroke: 'Wake up stroke',
    AmbulanceL: 'Ambulance',
    GoToTheHospitalByYourself: 'Go to the hospital by yourself',
    GetSickInTheHospital: 'Get sick in the hospital',
    FromOtherHospitals: 'From other hospitals',
    FromWhichHospital: 'From which hospital',
    BeforeArrivingAtTheHospital: 'Before arriving at the hospital',
    CallTime: 'Call time',
    DepartureTime: 'Departure time',
    FirstTreatmentTime: 'First treatment time',
    ArriveAtTheScene: 'Arrive at the scene',
    SelfDescriptionAndSymptom: 'Self description and symptom',
    ComprehensiveAssessment: 'Comprehensive assessment',
    Sober: 'Sober',
    Dim: 'Dim',
    Delirium: 'Delirium',
    Drowsiness: 'Drowsiness',
    Lethargy: 'Lethargy',
    LightComa: 'LightComa',
    ModerateComa: 'ModerateComa',
    DeepComa: 'DeepComa',
    AddPatient: 'Add patient',
    ConsultationScheduling: 'Consultation scheduling',
    TrainScheduling: 'Train scheduling',
    ReportDataExport: 'Report data export',
    TimeSet: 'Time set',
    date: 'Date'
  },
  equip: {
    newBracelet: 'New Bracelet',
    newLabel: 'new label',
    tagID: 'Tag ID',
    informationView: 'Information view',
    assetID: 'Please enter the asset tag ID',
    selectDevice: 'Please select device type',
    insufficient: 'Insufficient electricity',
    sufficient: 'Sufficient electricity',
    enterID: 'Please enter label ID',
    limitLabel: 'Label ID cannot exceed 50 characters',
    SelectBind: 'Please select bind',
    DelLabel: 'Delete this label',
    FenceAlarmType: 'Type of fence alarm',
    AssociatFenceArea: 'Associated Fence area',
    StartDraw: 'Start drawing',
    EndDraw: 'End drawing',
    EnterFenceName: 'Please enter the name of the fence',
    to16: '3 to 16 characters in length',
    SelectAlarmType: 'Please select the type of alarm',
    to120: '2 to 120 characters in length',
    StartPaintFence: 'Start painting the fence, please',
    DotsTips: 'And draw at least three dots',
    formWrongTips: 'There is something wrong with the form, Please check before submitting',
    NewRW: 'New Reader and writer',
    EquipmentNum: 'Equipment number',
    IpAddress: 'Ip Address',
    OnlineState: 'Online state',
    TriggerSortEvent: 'The sort event is triggered',
    SelectCoordinates: 'Select coordinates',
    XAxisCoordinates: 'X Axis coordinates',
    YAxisCoordinates: 'Y Axis Coordinates',
    IpFormatErr: 'Ip format error',
    IPnotEmpty: 'The IP can not be empty',
    EnterDeviceName: 'Please enter the device name',
    EnterDeviceNum: 'Please enter the device number',
    LackOf: 'Lack of',
    DeviceAddr: 'Device address',
    SignalRepeater: 'Signal repeater',
    SignalReceiver: 'Signal Receiver',
    ExitCamera: 'Exit Camera',
    ToWard: 'To the same ward',
    EquipmentStatus: 'Equipment status',
    Abnormity: 'Abnormity',
    EquipmentType: 'Type of equipment',
    FirmwareVersion: 'Firmware version',
    DeviceIpAddr: 'Device Ip address',
    MacAddress: 'Mac Address',
    OperationMode: 'Mode of operation',
    WorkPort: 'Working port',
    TargetIP1: 'Target IP1',
    PortIP1: 'Port IP1',
    TargetIP2: 'Target IP2',
    PortIP2: 'Port IP2',
    TargetIP3: 'Target IP3',
    PortIP3: 'Port IP3',
    TargetIP4: 'Target IP4',
    PortIP4: 'Port IP4',
    SubnetMask: 'Subnet mask',
    GatewayIp: 'Gateway Ip',
    HowGetIP: 'How to get IP',
    StaticAcquisition: 'Static acquisition',
    DynamicAcquisition: 'Dynamic acquisition',
    WebPort: 'Web Port',
    CommandPort: 'Command port',
    EnterDeviceAddr: 'Please enter the device address',
    ModifyButton: 'Modify Button',
    NewEquipment: 'New Equipment',
    AddTime: 'Add time',
    LastActiveTime: 'Last active time',
    port: 'port',
    ParameterSet: 'Parameter setting',
    delDeviceOrNot: 'Whether to delete this device?',
    EnterNameSearch: 'Please enter a name search',
    AddFence: 'Add a fence',
    HoldOverAlarm: 'Hold-over alarm',
    EnterTplNameSearch: 'Please enter the template name search',
    NewTemplate: 'New Template',
    TplName: 'Template name',
    EnterAlarmFenceTplname: 'Please enter the alarm fence template name',
    to20: '3 to 20 characters in length',
    To120: '0 To 120 characters in length',
    NewType: 'New Type',
    DevTypeName: 'Device type name',
    Business: 'Business',
    TechnologyType: 'Type of technology',
    SelectTecType: 'Please select the type of technology',
    SelectBusiness: 'Please Select Your Business',
    EnterDevTypeName: 'Please enter a device type name',
    DevTypeNameTips: 'The device type name can not exceed 20 characters',
    Obstetrics: 'Obstetrics'
  },
  // 系统设置
  sys: {
    NumberOfAssociatedDevices: 'Number of associated devices',
    AffiliatedHospital: 'Affiliated Hospital',
    enterEmployeSearch: 'Please enter employee number to search',
    enterRoleSearch: 'Please enter a role name search',
    batchRemove: 'delete in batches',
    roleName: 'role name',
    proceed: 'proceed',
    confirmTo: 'To determine the',
    phoneNum: 'Mobile',
    employeeID: 'Employee ID',
    password: 'password',
    confirmPassword: 'ConfirmPassword',
    customAccount: 'Custom account',
    email: 'mailbox',
    depart: 'department',
    clickSelectDepartment: 'Select department',
    respectiveWard: 'Respective ward',
    clickSelectWard: 'Click to select ward',
    role: 'role',
    passTips: 'The password cannot be empty',
    confirmPasswordTips: 'Confirm that password cannot be empty',
    pwdTips: 'Verify that the password is inconsistent with the password entered',
    emailTips: 'Email format error',
    phoneNumTips: 'Wrong phone number format',
    wardTips: 'The ward cannot be empty',
    nameTips: 'The name cannot be empty',
    pwdText: 'The length is 8 to 16 characters',
    pwdTipsText: 'Length of 8 to 16 characters, there is at least one letter, the rest can be any character',
    employeeTips: 'Employee number cannot be empty',
    authorization: 'authorization',
    roleNameTips: 'The role name cannot be empty',
    departTips: 'The department cannot be empty',
    Enquiry: 'Enquiry',
    ParameterName: 'Parameter name',
    ParameterVal: 'Parameter value',
    parameterNameTips: 'The parameter name can not be empty',
    parameterValTips: 'The parameter name can not be empty',
    IntoDepartment: 'Into the department',
    DepartID: 'Department ID',
    NameDepartment: 'Name of Department',
    CodingNameDepartment: 'Coding Name Department',
    SynchronizeData: 'Synchronize data',
    SuperiorDepart: 'Superior Department',
    SORTnum: 'SORT number',
    ClickToSelectSupDepa: 'Click to select a superior department',
    depaNameTips: 'The name of the department can not be empty',
    SupDepaTips: 'The Superior Department can not be empty',
    FirstlevelDepaTips: 'First-level departments can not choose higher-level departments',
    DictionaryName: 'Dictionary name',
    DictionaryType: 'Dictionary type',
    DictionaryCode: 'Dictionary Code',
    DictionaryVal: 'Dictionary value',
    SORT: 'SORT',
    DicNameTips: 'The dictionary name can not be empty',
    DicTypeTips: 'The dictionary type can not be empty',
    DicCodeTips: 'The dictionary Code can not be empty',
    DicValTips: 'The dictionary value can not be empty',
    DefaultField: 'Default field',
    enterContents: 'Please enter the contents',
    NewLocation: 'New Location',
    HptDistrict: 'Hospital district',
    Buildings: 'Buildings',
    selectDistrict: 'Please select your district',
    NewHplArea: 'New Hospital area',
    EditorialOffice: 'Editorial office',
    SuperiorUnit: 'Superior unit:',
    FillBuildingName: 'Please fill in the name of the building',
    buildingNameTips: 'The building name is 1 to 50 characters long',
    institutionName: 'Name of institution',
    enterHplName: 'Please enter the name of the hospital',
    HplNameTips: 'You can not have spaces in the name of a hospital district',
    HplNameTips2: 'The name of the campus is 1-50 characters in length',
    UserNameAndAction: 'User name / User Action',
    UserName: 'User name',
    UserOperation: 'User operation',
    RequestMethod: 'Request method',
    ReqParameter: 'Request parameter',
    ExecutionTime: 'Execution Time in milliseconds',
    SuperMenu: 'Superior Menu',
    ContentsTable: 'Table of contents',
    Menu: 'Menu',
    btn: 'button',
    MenuUrl: 'Menu URL',
    AuthorizationSigns: 'Authorization identification',
    clickSelectMenu: 'Click select supervisor menu',
    MenuRoute: 'Menu routing',
    MultipleCommas: 'Multiple Commas',
    SuchAs: 'Such as',
    menuURLtips: 'The MENU URL can not be empty',
    menuNameTips: 'The menu name can not be empty',
    parentMenuTips: 'The parent menu can not be empty',
    FenceCacheInfoSync: 'Fence cache information synchronization',
    TagCacheInfoSync: 'Tag Cache information synchronization',
    SYNCfailure: 'SYNC failure',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    Common: 'Common',
    resetPassword: 'Reset password',
    oldPassword: 'Old password',
    alarmSoundSetting: 'alarm sound setting',
    siteInfo: 'position information'

  },
  job: {
    BatchSuspension: 'Batch suspension',
    BatchRecovery: 'Batch recovery',
    BatchExecution: 'Batch execution',
    LogList: 'Log List',
    MethodName: 'Method name',
    Parameters: 'Parameters',
    Expression: 'Expression',
    TimeOut: 'Time-out',
    Recover: 'Recover',
    Immediately: 'Immediately',
    userNameTips: 'The user name can not be empty',
    methodNameTips: 'The method name can not be empty',
    cronExpressTips: 'A cron expression can not be null',
    TaskId: 'Task Id',
    LogID: 'Log ID',
    Failure: 'Failure',
    UnitTime: 'Unit of time: milliseconds'
  },
  oss: {
    cloudConfig: 'Cloud storage configuration',
    uploadFiles: 'Upload files',
    operateNextTips: 'Operation successful, do you want to continue?',
    imgTips: 'Only JPG, PNG, GIF images are supported!',
    dragTips: 'Drag the file here, or',
    storageType: 'storage type',
    qiniu: 'QINIU',
    Aliyun: 'Aliyun',
    qcloud: 'Qcloud',
    freeTips: 'Free application (QINIU) for 10GB storage space',
    domainName: 'domain name',
    boundDomainName: 'Bound domain name',
    pathPrefix: 'Path prefix',
    notSetTips: 'The default is not set to null',
    spaceName: 'Space name',
    storageSpaceName: 'QINIU storage space name',
    regionOrigin: 'Region of origin',
    originTips: '(optional value, south China: gz north China: tj east China: sh)'
  },
  // 护理白板
  nurse: {
    remarkContent: 'Remarks contents',
    createUserName: 'Founder',
    addRemark: 'New memo',
    lengthTip100: '1 to 100 characters in length',
    dictName: 'Dictionary name',
    addDict: 'New data dictionary',
    dictNew: 'newly build',
    dictCode: 'Dictionary encoding',
    selectPrevDict: 'Click to select the parent dictionary',
    prevDict: 'Superior dictionary',
    SuperiorParameter: 'Superior parameter',
    lengthTip20: '1 to 20 characters in length',
    boardName: 'Whiteboard name',
    scope: 'Application scope',
    source: 'source',
    boardId: 'Plate ID',
    Module: 'Module',
    selectBoardId: 'Please select plate ID',
    dictNamePlaceholder: 'Please enter a dictionary name',
    StartNursingScreen: 'Start nursing screen',
    NewNursingWhiteboard: 'New nursing whiteboard',
    PleaseEnterWhiteboardName: 'Please enter a whiteboard name',
    preview: 'preview',
    ApplicationWard: 'Application ward',
    HaveChosen: 'Have chosen',
    SickWards: 'Sick wards',
    LayoutStyle: 'Layout style',
    ViewExamples: ' View examples',
    SetAsDefault: 'Set as default',
    CancelDefault: 'Cancel default',
    delThis: 'Delete this',
    startUp: 'start-up',
    RecycleBin: 'Recycle bin',
    drafts: 'drafts',
    Release: 'Release',
    delete: 'delete',
    edit: 'edit',
    DeleteTime: 'Delete time',
    UndercutDeletion: 'Undercut deletion',
    recovery: 'recovery',
    ManualEntry: 'Manual Entry',
    notice: 'Notification details',
    setting: 'Setting',
    projectUpdateTime: 'projectUpdateTime',
    systemdefault: 'system Default',
    secondDay: 'secondDay',
    update: 'update',
    dataManagement: 'data management',
    projectName: 'project Name',
    exportProject: 'export Project',
    projectMaintain: 'project Maintain',
    CareMatters: 'Care Matters',
    medicglance: 'medic Glance',
    patientView: 'patient View'

  },
  baby: {
    unbundleTip: 'The baby will be unbound from the bed only after the "ok" operation is performed.',
    outHosNotUnbound: 'Discharge to be unbound',
    BabyLabel: 'Baby Label',
    MotherTag: 'Mother Tag',
    Unused: 'Unused',
    Inuse: 'In use',
    LabelCutAlarm: 'Label cut alarm',
    ExitCameraAlert: 'Exit camera alert',
    EquipAlarm: 'Equipment alarm',
    PairAlarm: 'Pair alarm',
    CheckoutTips: 'Check-out, no return, call 911',
    LowBatAlarm: 'Low battery alarm',
    MonBabyAlarm: 'Mother infant pairing alarm',
    offlineAlarm: 'offline alarm',
    activateAlarm: 'activate alarm',
    bedOccupancyAlarm: 'bed occupancy alarm',
    Handler: 'Handler',
    ChooseComPhrase: 'Choose common phrases',
    Falsealarm: 'False alarm, check no abnormality!',
    equipFailure: 'EQUIPMENT FAILURE, check!',
    SetInspectItem: 'Setting of inspection items',
    babyName: 'INFANT NAME',
    babySex: 'sex of baby',
    MotherName: 'Mother Name',
    MotherHplNum: 'hospital number of Mother',
    SearchResults: 'Search results',
    patrolTime: 'patrolTime',
    InspectOne: 'Inspect one',
    AddInspectItems: 'Add inspection items',
    enterKeyword: 'Please enter a keyword',
    signIO: 'signIn or signOut',
    MotherBabyPairs: 'Mother-infant pairing',
    PatrolRecords: 'Patrol records',
    calRecords: 'off line record',
    OperationRecord: 'Operation Record',
    AlarmRecord: 'Alarm Record',
    OffWarning: 'Off warning tone',
    TemporarilyOut: 'Temporarily out of',
    Returnrest: 'Return to the rest of the',
    babyLabelTips: 'he baby label is too low',
    mom: 'mother',
    baby: 'baby',
    BoundMomTag: 'Bound mother tag',
    WaitBabyInfo: 'Waiting for baby information',
    inactive: 'inactive',
    BoundBabyLabel: 'Bound baby label',
    notBoundBabyLabel: 'Not bound baby label',
    BabyTagActivated: 'Baby tag activated',
    BabyInfoAssigned: 'Baby information has been assigned',
    PreAssignedLabel: 'Pre-assigned baby label',
    labelSet: 'Label Defaults',
    history: 'history',
    PreBoundLabel: 'Pre-bound baby label',
    BabyLocation: 'Baby location',
    handleOperation: 'Please handle the operation after alarm',
    checkBabyIn: 'Please check the baby in and operate',
    babyTagOffline: 'The baby tag is offline',
    will: 'will',
    ToCheckIn: 'To check-in',
    CheckinS: 'Checkin success',
    ClearAlarmSucc: 'Clear sound and light alarm successfully',
    HavetoLabel: 'Have to label',
    UnbundLabel: 'Unbundling label',
    LabelBaby: 'Label the baby',
    ForSolutionTo: 'For solution to',
    PairingSUCC: 'Pairing successful',
    MatchFailure: 'Matching failure',
    MatchTime: 'Matching time',
    operatingInfo: 'operating information',
    CompletePreBind: 'Pre-bind baby label binding',
    CompleteLabelBind: 'Complete the baby label binding',
    OperationChange: 'Operation change label',
    OperationUnbind: 'Operation unbind label',
    CheckOutOper: 'Check out the operation',
    OperatingCheckin: 'Operating checkin',
    ReceivePushMom: 'Receive push mother information',
    ReceivePushBaby: 'Receive push baby messages',
    ReceiveBedChange: 'Receive push bed change information',
    ReceiveDischarge: 'Receiving discharge information',
    OutHospitalUnbundleLabel: 'Operation discharge unbind label',
    operationTime: 'operation time',
    BabyLabelBind: 'Baby label binding',
    ReasonCheckOut: 'Sign an accident by',
    PlanRegressTime: 'Planned regression time',
    ToCheckOut: 'To check out',
    After5min: 'After 5 minutes',
    After10min: 'After ten minutes',
    afterHalfhour: 'half an hour later',
    afterAHour: 'After 1 hour',
    afterTwoHour: 'After 2 hours',
    afterThreeHour: '3 hours later',
    seeADoc: 'In Treatment',
    nurse: 'nurse',
    takeABath: 'bath',
    FamiliesFrom: 'Families from',
    CheckOutSucc: 'Check out the success',
    babyNormal: 'The baby is normal',
    babyAbnormal: 'The baby is abnormal',
    locatorData: ' locator data',
    numberBabiesHpl: 'The number of babies in the hospital',
    Newbabiestoday: 'New babies today',
    NumDischargedT: 'Number of discharged today',
    CheckOutT: 'Check out today',
    CheckInT: 'Check in today',
    NumPatrolsT: 'Number of patrols today',
    TagAlarmNumT: 'Tag alarm number today',
    NumbMatAndInfaT: 'Number of maternal and infant pairs today',
    numboys: 'The number of boys',
    numgirl: 'The number of the girl',
    AnUnknownNum: 'An unknown number of',
    SexRatioOfInfants: 'Sex ratio of infants in hospital',
    LabUseStatisticalP: 'Labels use statistical proportions',
    haveBeenUsed: 'have been used',
    Taguse: 'Tag use',
    TnfantManageAlarmRatioT: 'Todays infant management alarm ratio',
    UnusedPairFailalarm: 'Unused pairing failure alarm',
    ExportAlarm: 'Export alarm',
    TBbabylabAlarmRatio: 'Todays baby label alarm ratio',
    labelcut: 'The label cut',
    EquipHisAlarmRatio: 'Equipment history alarm ratio',
    signalMonitor: 'signal monitor',
    TWorkContentRatio: 'Todays work content ratio',
    Babypatrol: 'Baby patrol',
    MoveOutof: 'Check in check out',
    distributionTime: 'distribution of time',
    pm: 'pm',
    No: 'No',
    workloadStatistics: 'workload statistics',
    chartInfo: 'chart-information',
    BindBabyInfo: 'Binding baby information',
    ActivatedTips: 'Activated, please confirm the completion of the baby binding in time!',
    selectBedNum: 'Please select the bed number to bind to',
    selectBaby: 'Please select the baby to bind to',
    LOOKTG: 'LOOKTG',
    enterRemarks: 'Please enter remarks',
    BabyLabelsAssigned: 'Baby labels have been assigned',
    noBabyLocation: 'No location information for the baby',
    bindTagReminder: 'Bind tag reminder',
    LabelUntyingReminder: 'Label untying reminder',
    bindBabyInTime: 'Please bind the baby in time',
    CheckOutNoReturn: 'Check out not returned',
    NoBabyListNow: 'No baby information available',
    NoBedListNow: 'There is no bed number information',
    SyncBabyInformation: 'Sync baby information'
  },
  humiture: {
    customNum: 'Custom number',
    MonitoringPoint: 'Monitoring point',
    addMonitoringPoint: 'add Monitoring Point',
    PleaseBindHumiture: 'Please bind the humiture monitoring terminal',
    delMonitoringPoint: 'Delete monitoring point',
    deviceManagement: 'device management',
    MonitoringPointDeatil: 'Monitoring details'
  },
  ...enLocale
};

export default en;
