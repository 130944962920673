/*
 * @Author: zxx
 * @Date: 2020-10-10 10:55:21
 * @LastEditors: zxx
 * @LastEditTime: 2020-11-18 16:15:24
 * @FilePath: \xinsec-iot-zhaoqing\src\config\env\staging.js
 */
module.exports = {
  ws: {
    // 通用WS地址
    universal: 'ws://' + document.domain + '/ws/base/socket/',
    // 第三方WS地址--大耳马床垫
    // darma: 'ws://77.169.220.90/ws/pati/socket',
    darma: 'ws://10.80.210.93:9991/ws',
    // 输液看板
    screen: 'ws://' + document.domain + '/ws/base/screen/socket'
  }
};
