<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
};
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  color: #333;
  background: #F0F2F5;
}
.ellipsis{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.el-form-item__label {
  color: #333;
}
</style>
