<template>
  <div class="footer-fixed">
    <div class="footer-fixed-content" :style="sidebar.opened ? 'margin-left: 208px;' : 'margin-left: 63px;'">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters([
      'sidebar'
    ])
  }
};
</script>

<style lang="scss" scoped>
  .footer-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px -6px 16px 0px rgba(0, 0, 0, 0.08);
    background: #fff;
    height: 64px;
    z-index: 666;
    &-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
    }
  }
</style>
